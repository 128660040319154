import _defineProperty from "C:\\Projects\\InkedLandManagement\\src\\client\\inked-react\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

var _Yup$object$shape;

import * as Yup from 'yup';
import LookupType from './LookupType';
import ContactHistory from './ContactHistory';
import RecordingInfo from './RecordingInfo';
export var PermitSchema = Yup.object().shape((_Yup$object$shape = {
  client: LookupType.label("Client").default(null).nullable(),
  project: LookupType.label("Project").default(null).nullable(),
  tract_no: Yup.string().strict(true).label('Tract #').min(1).required("Tract # is required"),
  permit_type: LookupType.label("Permit Type").default(null).nullable().required("Permit type is required"),
  landman: LookupType.label("Landman").default(null).nullable(),
  inked_id: Yup.mixed().default(null).nullable(),
  grantor: LookupType.label("Grantor").default(null).nullable(),
  permit_number: Yup.string().label("Permit Number").default(null).nullable(),
  plss_qq: Yup.string().label("QQ").default(null).nullable(),
  plss_section: LookupType.label("Section").default(null).nullable(),
  plss_township: LookupType.label("Township").default(null).nullable(),
  plss_range: LookupType.label("Range").default(null).nullable(),
  state: LookupType.label("State").default(null).nullable(),
  county: LookupType.label("County").default(null).nullable(),
  acquisition_status: LookupType.label("Acquisition Status").default(null).nullable(),
  survey_complete_date: Yup.string().label("Survey Complete").default(null).nullable(),
  plan_profile_received_date: Yup.string().label("Plan and Profile Received").default(null).nullable(),
  submitted_date: Yup.string().label("Permit Submitted").default(null).nullable(),
  executed_date: Yup.string().label("Permit Executed").default(null).nullable(),
  agreement_type: LookupType.label("Agreement Type").default(null).nullable(),
  recording_info: RecordingInfo.label("Recording Info"),
  footage: Yup.number().label("Footage").default(null).nullable(),
  depth: Yup.number().label("Depth").default(null).nullable(),
  application_fee: Yup.number().label("Application Fee").default(null).nullable(),
  application_check_number: Yup.string().label("Application Check #").default(null).nullable(),
  crossing_fee: Yup.number().label("Crossing Fee").default(null).nullable(),
  crossing_fee_check_number: Yup.string().label("Crossing Fee Check #").default(null).nullable(),
  bond_requirement: Yup.number().label("Bond Requirement").default(null).nullable(),
  bond_check_number: Yup.string().label("Bond Check #").default(null).nullable(),
  total_payment: Yup.number().label("Total Payment").default(null).nullable(),
  term_assignable: LookupType.label("Assignable").default(null).nullable()
}, _defineProperty(_Yup$object$shape, "term_assignable", LookupType.label('Assignable').default(null).nullable()), _defineProperty(_Yup$object$shape, "term_length", LookupType.label('Term Length').default(null).nullable()), _defineProperty(_Yup$object$shape, "term_permanent_width", LookupType.label('Perm. Width (feet)').default(null).nullable()), _defineProperty(_Yup$object$shape, "term_expiration_date", Yup.string().label('Expiration Date').default(null).nullable()), _defineProperty(_Yup$object$shape, "term_abandonment", LookupType.label('Abandonment due to Non-Use').default(null).nullable()), _defineProperty(_Yup$object$shape, "term_number_of_lines", LookupType.label('Number of Lines').default(null).nullable()), _defineProperty(_Yup$object$shape, "term_change_size", LookupType.label('Change Size').default(null).nullable()), _defineProperty(_Yup$object$shape, "term_above_ground_appurtenance", LookupType.label('Above-Ground Appurtenance').default(null).nullable()), _defineProperty(_Yup$object$shape, "term_product_restriction", LookupType.label('Product Restriction').default(null).nullable()), _defineProperty(_Yup$object$shape, "term_tws_length", LookupType.label('TWS Length (ft)').default(null).nullable()), _defineProperty(_Yup$object$shape, "term_tws_expiration", LookupType.label('TWS Expiration').default(null).nullable()), _defineProperty(_Yup$object$shape, "term_contract_notes", Yup.string().label('Contract Notes').default(null).nullable()), _defineProperty(_Yup$object$shape, "station_entry", Yup.string().label('Station Entry').default(null).nullable()), _defineProperty(_Yup$object$shape, "station_exit", Yup.string().label('Station Exit').default(null).nullable()), _defineProperty(_Yup$object$shape, "construction_stips", Yup.array().of(Yup.object().shape({
  id: Yup.mixed(),
  entry: Yup.string().label("Construction Stip"),
  property_id: Yup.mixed(),
  permit_id: Yup.mixed()
}).label("Construction Stips"))), _defineProperty(_Yup$object$shape, "survey_stips", Yup.array().of(Yup.object().shape({
  id: Yup.mixed(),
  entry: Yup.string().label("Survey Stip"),
  property_id: Yup.mixed(),
  permit_id: Yup.mixed()
}).label("Survey Stips"))), _defineProperty(_Yup$object$shape, "existing_encumberances", Yup.array().of(Yup.object().shape({
  id: Yup.mixed(),
  reception_number: Yup.string().nullable(),
  encumberance_type: LookupType.nullable()
}).label('Existing Encumberances'))), _defineProperty(_Yup$object$shape, "contact_history", ContactHistory.label('History')), _defineProperty(_Yup$object$shape, "user", Yup.string().nullable()), _Yup$object$shape));