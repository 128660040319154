import React from 'react'

import { usePreventWindowUnload } from '../../hooks/preventWindowUnload'
import { Icon } from '@blueprintjs/core'
import { FieldArray } from 'formik'


import { 
  ButtonLink,
  Card, 
  CardWithBreadcrumb, 
  Form,
  FormikInput, 
  CancelButton,
  SaveButton,
  FormikSuggest,
  FormikTextArea,
  FormikPhoneMaskedInput,
  Table,
  Button
} from '../../elements'

const containerStyle = {
  flex: 1,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'stretch'
}

const cardStyle = {
  width: '23vw',
  minWidth: 300
}

const extensionStyle = {
  width: 100,
  marginLeft: 10
}

const urlCardStyle = {
  width: 600
}

const phoneContainerStyle = {
  display: 'flex'
}

const PersonContactCard = ({ index }) => (
  <Card style={cardStyle}>
    <FormikInput
      name={`contacts[${index}].name`}
      label={`Contact ${Number(index) + 1} Name`}
      placeholder={`Contact ${Number(index) + 1} Name`}
      leftIcon="person"
    />

    <FormikInput
      name={`contacts[${index}].title`}
      label="Title"
      placeholder="Title"
      leftIcon="id-number"
    />

  <div style={phoneContainerStyle}>
    <FormikPhoneMaskedInput
      name={`contacts[${index}].phone_1`}
      label="Phone (work)"
      placeholder="Phone (work)"
      leftIcon="phone"
      type="tel"
    />
  <div style={extensionStyle}>
    <FormikInput
      name={`contacts[${index}].phone_1_ext`}
      label="Ext."
      placeholder="Ext."
    />
  </div>
    </div>
  <div style={phoneContainerStyle}>
    <FormikPhoneMaskedInput
      name={`contacts[${index}].phone_2`}
      label="Phone (cell)"
      placeholder="Phone (cell)"
      leftIcon="phone"
      type="tel"
    />
      <div style={extensionStyle}>
      <FormikInput
        name={`contacts[${index}].phone_2_ext`}
        label="Ext."
        placeholder="Ext."
      />
    </div>
  </div>

    <FormikInput
      name={`contacts[${index}].email`}
      label="Email"
      placeholder="Email"
      leftIcon="envelope"
      type="email"
    />

  </Card>
)

export default ({ isNew, values={}, states, dirty }) => {
  usePreventWindowUnload(dirty)

  return (
    <Form>
      <CardWithBreadcrumb items={[{ text: 'Clients', to: '/client' }, { text: values.name }]}>
        <ButtonLink
          disabled={isNew}
          icon="label"
          to={`/property?client=${values.id}`}
        >
          Properties
        </ButtonLink>
        <CancelButton />
        <SaveButton />
      </CardWithBreadcrumb>

      <div style={containerStyle}>
        <Card style={cardStyle}>
          <FormikInput
            name="name"
            label="Client Name"
            placeholder="Client Name"
            leftIcon="projects"
          />
        </Card>

        <Card style={cardStyle}>
          <FormikInput
            name="details.address_1"
            label="Address"
            placeholder="Address"
            leftIcon="map-marker"
          />

          <FormikInput
            name="details.city"
            label="City"
            placeholder="City"
            leftIcon="map-marker"
          />

          <FormikInput
            name="details.zip_code"
            label="Zip Code"
            placeholder="Zip Code"
            leftIcon="map-marker"
          />

          <FormikSuggest
            name="details.state"
            label="State"
            placeholder="State"
            leftIcon="map-marker"
            items={states}
          />
        </Card>

        <Card style={cardStyle}>
          <div style={phoneContainerStyle}>
            <FormikPhoneMaskedInput
              name="details.phone_1"
              label="Phone (main)"
              placeholder="Phone (main)"
              leftIcon="phone"
              type="tel"
            />
            <div style={extensionStyle}>
              <FormikInput
                name="details.phone_1_ext"
                label="Ext."
                placeholder="Ext."
              />
            </div>
          </div>

        <div style={phoneContainerStyle}>
            <FormikPhoneMaskedInput
              name="details.phone_2"
              label="Phone (2)"
              placeholder="Phone (2)"
              leftIcon="phone"
              type="tel"
            />
          <div style={extensionStyle}>
              <FormikInput
                name="details.phone_2_ext"
                label="Ext."
                placeholder="Ext."
              />
          </div>
        </div>

          <FormikInput
            name="details.email_1"
            label="Email (main)"
            placeholder="Email (main)"
            leftIcon="envelope"
            type="email"
          />

          <FormikInput
            name="details.email_2"
            label="Email (2)"
            placeholder="Email (2)"
            leftIcon="envelope"
            type="email"
          />
        </Card>

        <Card style={cardStyle}>
          <FormikInput
            name="details.website"
            label="Web / Social Media"
            placeholder="Web / Social Media"
            leftIcon="link"
          />

          <FormikTextArea
            name="details.notes"
            label="Notes"
            placeholder="Notes"
          />
        </Card>

        <PersonContactCard index="0" />

        <PersonContactCard index="1" />

        <PersonContactCard index="2" />

        <PersonContactCard index="3" />
        <Card style={urlCardStyle}>
          <FieldArray
            name="property_urls"
            label="GIS Property URL"
            render={( {push, remove, onChange }) => (
              <div style={{marginTop: 10}}>
                <Table>
                  <thead>
                    GIS Property URL
                  </thead>
                 <tbody>
                  {values.property_urls && values.property_urls.map((c, index) => {
                    return (
                      <tr>
                        <td>
                          <FormikInput 
                            key={`property_url-${index}-name`}
                            name={`property_urls[${index}].name`}
                            placeholder="Name"
                          />
                        </td>
                        <td>
                          <FormikInput
                            key={`property_url-${index}-url`}
                            name={`property_urls[${index}].url`}
                            placeholder="Property URL"
                            style={{ width: 300 }}
                        />
                        </td>
                        <td>
                            <Icon icon='delete' onClick={() => remove(index)} />
                          </td>
                      </tr>
                    )
                  })}
                 </tbody>
                </Table>
                { (!values.property_urls || (values.property_urls && values.property_urls.length === 0)) &&
                  <Button
                  style={{marginLeft: '10px', marginTop: '10px' }}
                  icon='add'
                  text='Add New'
                  type='button'
                  onClick={() => push({ url: '', name: '' })}
                />
              }
              </div>
            )}
          />
          <FieldArray
            name="permit_urls"
            label="GIS Permit URL"
            render={( {push, remove, onChange }) => (
              <div style={{marginTop: 10}}>
                <Table>
                  <thead>
                    GIS Permit URL
                  </thead>
                 <tbody>
                  {values.permit_urls && values.permit_urls.map((c, index) => {
                    return (
                      <tr>
                        <td>
                          <FormikInput 
                            key={`permit_url-${index}-name`}
                            name={`permit_urls[${index}].name`}
                            placeholder="Name"
                          />
                        </td>
                        <td>
                          <FormikInput
                            key={`permit_url-${index}-url`}
                            name={`permit_urls[${index}].url`}
                            placeholder="Permit URL"
                            style={{ width: 300 }}
                        />
                        </td>
                        <td>
                            <Icon icon='delete' onClick={() => remove(index)} />
                          </td>
                      </tr>
                    )
                  })}
                 </tbody>
                </Table>
                { 
                (!values.permit_urls || values.permit_urls && values.permit_urls.length === 0) &&
                  <Button
                  style={{marginLeft: '10px', marginTop: '10px' }}
                  icon='add'
                  text='Add New'
                  type='button'
                  onClick={() => push({ url: '', name: '' })}
                />
              }
              </div>
            )}
          />
        </Card>
      </div>
    </Form>
  )
}