import React, { useEffect } from 'react'

import { useAuth0 } from '../../hooks/auth0'

export default ({ location }) => {
  const { handleAuthentication } = useAuth0()

  useEffect(() => {
    if (/access_token|id_token|error/.test(location.hash)) {
      handleAuthentication()
    }
  }, [handleAuthentication, location])

  return (
    <div>hello</div>
  )
}