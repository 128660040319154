import React from 'react'
import { get } from 'lodash/fp'
import { Formik } from 'formik'
import { navigate } from '@reach/router'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { GrantorSchema } from '@inked/validation'

import GrantorForm from '../GrantorForm'
import { Spinner } from '../../elements'

const QUERY = gql`
  query {
    states {
      id
      name
    }
  }
`

const MUTATION = gql`
  mutation createGrantor($grantor: GrantorInput!) {
    createGrantor(grantor: $grantor) {
      id
    }
  }
`

export default () => {
  const [createGrantor] = useMutation(MUTATION)
  const { loading, error, data } = useQuery(QUERY)


  if (loading) {
    return <Spinner />
  }

  if (error) {
    console.error(error)

    return <span>error</span>
  }

  const onSubmit = async (props) => {
    const grantor = GrantorSchema.cast(props, { stripUnknown: true })

    const res = await createGrantor({ variables: { grantor }})

    const id = get('data.createGrantor.id')(res)

    navigate(`/grantor/${id}/edit`, { replace: true })
  }

  const onReset = () => navigate('/grantor')

  return (
    <Formik
      initialValues={{}}
      onSubmit={onSubmit}
      onReset={onReset}
      render={props => <GrantorForm {...props} isNew states={data.states} />}
      validationSchema={GrantorSchema}
    />
  )
}