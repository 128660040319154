import React, { useEffect } from 'react'
import { useField, useFormikContext, Field } from 'formik'

import {
  FormInput,
  FormSimpleSelect,
  FormSuggest,
  FormTextArea,
  MaskedInput,
  PhoneMaskedInput,
  FormMultiSuggest,
  FormEditableText,
  NumberMaskedInput
} from '../Input'

export const useFormik = (name) => {
  const { isSubmitting, setFieldValue, submitCount } = useFormikContext()
  const [field, meta] = useField(name)

  const { error, touched } = meta

  return {
    field,
    meta,
    isSubmitting,
    setFieldValue,
    error: (touched || submitCount > 0) && error
  }
}

export const FormikInput = ({ name, isAuto, autoValue, ...props }) => {
  const { field, isSubmitting, error } = useFormik(name)

  return (
    <FormInput
      {...props}
      {...field}
      disabled={isSubmitting || props.disabled}
      errorText={error}
      isAuto={isAuto}
      autoValue={autoValue}
    />
  )
}

export const FormikEditableText = ({ name, ...props }) => {
  const { field, isSubmitting, setFieldValue, error } = useFormik(name)
  return (
    <FormEditableText
      {...props}
      {...field}
      disabled={isSubmitting || props.disabled}
      errorText={error}
      onChange={(item) => { return setFieldValue(name, item) }}
    />
  )
}

export const FormikMaskedInput = ({ name, ...props }) => {
  const { field, isSubmitting, error } = useFormik(name)

  return (
    <MaskedInput
      {...props}
      {...field}
      disabled={isSubmitting || props.disabled}
      errorText={error}
    />
  )
}

export const FormikPhoneMaskedInput = ({ name, ...props }) => {
  const { field, isSubmitting, error } = useFormik(name)

  return (
    <PhoneMaskedInput
      {...props}
      {...field}
      disabled={isSubmitting || props.disabled}
      errorText={error}
      autocomplete='off'
    />
  )
}

export const FormikNumberMaskedInput = ({ name, type, ... props }) => {
  let { field, isSubmitting, setFieldValue, error } = useFormik(name)
  
  return (
    <NumberMaskedInput
      {...props}
      {...field}
      disabled={isSubmitting || props.disabled}
      errorText={error}
      onChange={async (e) =>  { 
        const val = e.currentTarget.value

        if (type === 'number') {
          setFieldValue(name, parseInt(val.replace(/\D+/g, '')) || null)
        } else if (type === 'decimal') {
          setFieldValue(name, isNaN(val) ? val.replace(/\D+\./g, '') : val)
        } else {
          setFieldValue(name, val)
        }
      }
    }
    />
  )
}

export const FormikTextArea = ({ name, ...props }) => {
  const { field, isSubmitting, error } = useFormik(name)

  return (
    <FormTextArea
      {...props}
      {...field}
      disabled={isSubmitting || props.disabled}
      errorText={error}
    />
  )
}


export const FormikSuggest = ({ name, value, dependent, updateDependent, ...props }) => {
  const { error, field, isSubmitting, setFieldValue } = useFormik(name)
  const dForm = useFormik(dependent)

  return (
    <FormSuggest
      {...props}
      value={value ? value : field.value}
      onBlur={field.onBlur}
      onChange={(item) => {
        if(dependent) {
          updateDependent(item, dependent, (val) => {
            setFieldValue(dForm.field.name, val)
          })
        }    
        return setFieldValue(field.name, item)  
      }}
      name={field.name}
      disabled={isSubmitting || props.disabled}
      errorText={error}
    />
  )
}

export const FormikMultiSuggest = ({ name, ...props }) => {
  const { error, field, isSubmitting, setFieldValue } = useFormik(name)

  return (
    <FormMultiSuggest
      {...props}
      value={field.value}
      onBlur={field.onBlur}
      onChange={(item) => setFieldValue(field.name, item)}
      name={field.name}
      disabled={isSubmitting || props.disabled}
      errorText={error}
    />
  )
}


export const FormikSimpleSelect = ({ name, ...props }) => {
  const { field, isSubmitting, error, setFieldValue } = useFormik(name)

  return (
    <FormSimpleSelect
      {...props}
      {...field}
      onChange={(item) => setFieldValue(name, item)}
      disabled={isSubmitting || props.disabled}
      errorText={error}
    />
  )
}

export const FormikCheckbox = ({ name, value, label, onChange }) => {
  const style = {
    display: 'flex',
    flexDirection: 'row'
  }
  return(
      <div style={style}>
        <Field
          style={{ marginTop: 3, marginRight: 10}}
          name={name}
          checked={value}
          type='checkbox'
          onChange={(e) => {
            onChange(e)
          }}
      />
      <p style={{ marginTop: 0, fontWeight: 400}}>{label}</p>
    </div>
  )}