import React from 'react'
import { isLocal } from '../../utils/helpers'

import { useAuth0 } from '../../hooks/auth0'
import { 
    CAN_EDIT_CLIENT,
    CAN_EDIT_LANDMAN,
    CAN_EDIT_GRANTOR,
    CAN_EDIT_PROPERTY,
    CAN_EDIT_PERMIT,
    CAN_EDIT_PROJECT,
    CAN_VIEW_AND_EDIT_ALL
} from '../../utils/enums'

export const withAuth = (action, isRoute,) => ({ ...props }) => {
    const { profile } = useAuth0()
    const isProd = window.location.origin === 'https://data.inkedlandco.com'
    const context = isProd ? 'https://row.inkedlandco.com/actions' : 'https://row.dev.inkedlandco.com/actions' // Use the old URL because this is set by Auth0 via a custom rule.  
    
    const userActions = profile ? profile[context] : []
  
    const hasRight = userActions ? userActions.find(a => a === action) : null
    return isRoute ? hasRight : hasRight ? props.children : null
}

export const CanEdit = (arg, roleid) => {
    const { profile } = useAuth0()
    
    const isProd = window.location.origin === 'https://data.inkedlandco.com'
    const context = isProd ? 'https://row.inkedlandco.com/actions' : 'https://row.dev.inkedlandco.com/actions' // Use the old URL because this is set by Auth0 via a custom rule.
    const userActions = profile[context]
    const editActions = [CAN_EDIT_PERMIT, CAN_EDIT_CLIENT, CAN_EDIT_LANDMAN, CAN_EDIT_GRANTOR, CAN_VIEW_AND_EDIT_ALL, CAN_EDIT_PROJECT, CAN_EDIT_PROPERTY]

    let hasRight = false

    if(userActions) {
        userActions.forEach(a => {
            if(editActions.indexOf(a) !== -1) {
                hasRight = true
            }
        })
    }

    if(arg === 'Projects' && roleid === 7) {
        hasRight = false
    }

    return hasRight
}

