import React, { useEffect } from 'react'

export const usePreventWindowUnload = (preventDefault) => {

    useEffect(() => {
        if (!preventDefault) return;
        const handleBeforeUnload = event => {
            event.preventDefault()
            event.returnValue = ""
        }
        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => window.removeEventListener('beforeunload', handleBeforeUnload)


    }, [preventDefault])
}