import React, { 
  createContext, 
  useCallback, 
  useContext, 
  useMemo, 
  useState 
} from 'react'
import { navigate } from '@reach/router'
import { WebAuth } from 'auth0-js'
import jwtDecode from 'jwt-decode'

const DEFAULT_STATE = {
  accessToken: null,
  idToken: null,
  expiresAt: 0
}

const KEY_LOGGED_IN = 'AUTH0_IsLoggedIn'
const KEY_AUTH0 = 'AUTH0'
const KEY_REDIRECT = 'AUTH0_Redirect'

const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN

const Auth0Context = createContext()

const useAuth0Context = () => useContext(Auth0Context)



const createAuth0 = () => new WebAuth({
  domain: AUTH0_DOMAIN,
  clientID: AUTH0_CLIENT_ID,
  redirectUri: `${window.location.origin}/auth`,
  responseType: 'token id_token',
  scope: 'openid profile email'
});

export const getAuthState = () => {
  return JSON.parse(localStorage.getItem('AUTH0'))
}

const useAuthState = () => {
  const state = getAuthState() || DEFAULT_STATE
  
  return useState(state)
}

const useContextValue = () => {
  const [authState, updateAuthState] = useAuthState()
  
  return {
    auth0: createAuth0(),
    authState,
    updateAuthState
  }
}

export const Auth0Provider = ({ children }) => {
  const ctx = useContextValue()

  return (
    <Auth0Context.Provider value={ctx}>
      {children}
    </Auth0Context.Provider>
  )
}

export const useAuth0 = () => {
  const { auth0, authState, updateAuthState } = useAuth0Context()

  const login = useCallback(() => {
    localStorage.setItem(KEY_REDIRECT, window.location.pathname)

    auth0.authorize()
  }, [auth0])

  const logout = useCallback(() => {
    localStorage.clear()
    localStorage.setItem(KEY_LOGGED_IN, false)

    updateAuthState(DEFAULT_STATE)
    auth0.logout({returnTo: `${window.location.origin}`})

    navigate('/logout')
  }, [auth0, updateAuthState])

  const setSession = useCallback(
    authResult => {
      let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()

      const state = {
        accessToken: authResult.accessToken,
        idToken: authResult.idToken,
        expiresAt: expiresAt
      }

      localStorage.setItem(KEY_LOGGED_IN, true)
      localStorage.setItem(KEY_AUTH0, JSON.stringify(state))

      updateAuthState(state)
    },
    [updateAuthState]
  )

  const isLoggedIn = useCallback(
    () => localStorage.getItem(KEY_LOGGED_IN), 
    []
  )

  const isAuthenticated = useMemo(
    () => new Date().getTime() < authState.expiresAt, 
    [authState]
  )

  const profile = useMemo(
    () => authState && authState.idToken && jwtDecode(authState.idToken),
    [authState]
  )

  const jwt = useMemo(
    () => authState && authState.idToken,
    [authState]
  )

  const handleAuthentication = useCallback(() => {
    auth0.parseHash((err, res) => {
      if (res && res.accessToken && res.idToken) {
        setSession(res)

        const path = localStorage.getItem(KEY_REDIRECT)

        if (path) {
          navigate(path)
        } else {
          navigate('/')
        }
      } else {
        console.error(err)
      }
    })
  }, [])

  return {
    login,
    logout,
    isLoggedIn,
    isAuthenticated,
    handleAuthentication,
    profile,
    jwt
  }
}