import React, { useCallback } from 'react'
import { gql } from 'apollo-boost'
import { Form } from 'formik'
import { Card, FormikSuggest, Button, ButtonLink, FormSuggest } from '../../elements'
import { H5 } from '@blueprintjs/core'
import { orderByName } from '../../utils/helpers'

const reportFiltersStyle = {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 30
}


const clearLinkStyle = {
    marginLeft: 20
}

const filterStyle = {
    marginRight: 20
}


export default ({ clients, landmen, grantors, projects, lookups, handleReset, filterOnChange, values, reset }) => {

    return (
        <Form>
            <Card items={[]}>
            <H5>Reports</H5>
            <h4>Please set your search criteria below:
                <Button onClick={reset} intent="danger" type="reset" style={clearLinkStyle}>Clear search</Button>
            </h4>
            <div style={reportFiltersStyle} id="report-filters">
                <FormSuggest
                    style={filterStyle}
                    label="Client"
                    placeholder=""
                    items={orderByName(clients)}
                    onChange={(e) => filterOnChange({
                        name: 'client_id',
                        val: e
                    })}
                    value={values.client_id}
                />
                <FormSuggest
                    style={filterStyle}
                    label="Project"
                    placeholder=""
                    items={orderByName(projects)}
                    onChange={(e) => filterOnChange({
                        name: 'project_id',
                        val: e
                    })}
                    value={values.project_id}
                />
                <FormSuggest
                    style={filterStyle}
                    label="Grantor"
                    placeholder=""
                    items={orderByName(grantors)}
                    onChange={(e) => filterOnChange({
                        name: 'grantor_id',
                        val: e
                    })}
                    value={values.grantor_id}
                />
                <FormSuggest
                    style={filterStyle}
                    label="Landman"
                    placeholder=""
                    items={orderByName(landmen)}
                    onChange={(e) => filterOnChange({
                        name: 'landman_id',
                        val: e
                    })}
                    value={values.landman_id}
                />
                <FormSuggest
                    style={filterStyle}
                    label="Property Type"
                    placeholder=""
                    items={orderByName(lookups.property_types)}
                    onChange={(e) => filterOnChange({
                        name: 'property_type_id',
                        val: e
                    })}
                    value={values.property_type_id}
                />
                <FormSuggest
                    style={filterStyle}
                    label="Project Status"
                    placeholder=""
                    items={orderByName(lookups.acquisition_statuses)}
                    onChange={(e) => filterOnChange({
                        name: 'acquisition_status_id',
                        val: e
                    })}
                    value={values.acquisition_status_id}
                />
                <FormSuggest
                    style={filterStyle}
                    label="Section"
                    placeholder=""
                    items={orderByName(lookups.plss_section)}
                    onChange={(e) => filterOnChange({
                        name: 'plss_section_id',
                        val: e
                    })}
                    value={values.plss_section_id}
                />
                <FormSuggest
                    style={filterStyle}
                    label="Township"
                    placeholder=""
                    items={orderByName(lookups.plss_township)}
                    onChange={(e) => filterOnChange({
                        name: 'plss_township_id',
                        val: e
                    })}
                    value={values.plss_township_id}
                />
                <FormSuggest
                    style={filterStyle}
                    label="Range"
                    placeholder=""
                    items={orderByName(lookups.plss_range)}
                    onChange={(e) => filterOnChange({
                        name: 'plss_range_id',
                        val: e
                    })}
                    value={values.plss_range_id}
                />
            </div>
            </Card>
        </Form>
    )
}