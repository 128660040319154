export const CAN_VIEW_AND_EDIT_ALL = "CAN_VIEW_AND_EDIT_ALL"

export const CAN_VIEW_CLIENT = "CAN_VIEW_CLIENT"

export const CAN_EDIT_CLIENT = "CAN_EDIT_CLIENT"

export const CAN_VIEW_LANDMAN = "CAN_VIEW_LANDMAN"

export const CAN_EDIT_LANDMAN = "CAN_EDIT_LANDMAN"

export const CAN_VIEW_GRANTOR = "CAN_VIEW_GRANTOR"

export const CAN_EDIT_GRANTOR = "CAN_EDIT_GRANTOR"

export const CAN_CREATE_USER = "CAN_CREATE_USER"

export const CAN_VIEW_PROJECT = "CAN_VIEW_PROJECT"

export const CAN_EDIT_PROJECT = "CAN_EDIT_PROJECT"

export const CAN_VIEW_PROPERTY = "CAN_VIEW_PROPERTY"

export const CAN_EDIT_PROPERTY = "CAN_EDIT_PROPERTY"

export const CAN_VIEW_PERMIT = "CAN_VIEW_PERMIT"

export const CAN_EDIT_PERMIT = "CAN_EDIT_PERMIT"

export const CAN_VIEW_REPORT = "CAN_VIEW_REPORT"