import React, { useState, useCallback, useRef } from 'react'
import { gql } from 'apollo-boost'
import * as lo from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import download from 'downloadjs'
import { useAuth0 } from '../../hooks/auth0'
import ReportFilters from '../ReportFilters'
import { Spinner, Card, Table, Button } from '../../elements'
import { getUrlPath, formatStrToThousands as fm } from '../../utils/helpers'


import './index.css'


const QUERY = gql`
  query($client_id: ID, $project_id: ID, $grantor_id: ID, $landman_id: ID, $property_type_id: Int,
  $acquisition_status_id: Int , $plss_section_id: Int, $plss_township_id: Int, $plss_range_id: Int) {
    reports(client_id: $client_id, project_id: $project_id, grantor_id: $grantor_id, landman_id: $landman_id, property_type_id: $property_type_id,
    acquisition_status_id: $acquisition_status_id, plss_section_id: $plss_section_id, plss_township_id: $plss_township_id, plss_range_id: $plss_range_id)
    {
      inked_id
      name
      client {
        id
        name
      }
      project {
        id
        name
      }
      landman {
        id
        name
      }
      grantor {
        id
        name
      }
      grantor_status {
        id
        name
      }
      property_type {
        id
        name
      }
      parcel_number
      plss_qq
      plss_section {
        id
        name
      }
      plss_township {
        id
        name
      }
      plss_range {
        id
        name
      }
      recording_info
      state {
        id
        name
      }
      county {
        id
        name
      }

    survey_permission_date
    plat_received_date
    title_received_date
    agreement_executed_date

    acquisition_status {
        id
        name
      }
    agreement_type {
        id
        name
      }

    preliminary_footage
    price_per_foot
    final_plat_footage
    footage_difference

    initial_payment_percent
    initial_payment_made
    initial_payment_check_number

    payment_due_to_exercise_option_percent
    payment_due_to_exercise_option_dollar
    exercise_option_due_date
    final_payment
    exercise_option_check_number

    misc_payment
    misc_payment_check_number

    term_assignable {
        id
        name
      }
    term_length {
        id
        name
      }
    term_expiration_date
    term_abandonment {
        id
        name
      }
    term_number_of_lines {
        id
        name
      }
    term_change_size {
        id
        name
      }
    term_above_ground_appurtenance {
        id
        name
      }
    term_product_restriction {
        id
        name
      }
    term_permanent_width {
        id
        name
      }
    term_tws_length {
        id
        name
      }
    term_tws_expiration {
        id
        name
      }
    term_contract_notes
    station_entry
    station_exit
    existing_encumberances
    survey_stips
    construction_stips
    permit_number
    total_payment
    }
    clients {
      id
      name
    }
    projects {
      id
      name
    }
    landmen {
      id
      name
    }
    grantors {
      id
      name
    }
    property_types {
      id
      name
    }
    acquisition_statuses {
      id
      name
    }
    plss_section {
      id
      name
    }
    plss_township {
      id
      name
    }
    plss_range { 
      id
      name
    }
  }
`

const reportsTableStyle = {
  overflowX: 'scroll',
  overflowY: 'scroll',
  paddingBottom: 50,
  height: 800,
  marginTop: 0,
  paddingTop: 0
}

const headerStyle = { 
  position: 'sticky', 
  top: 0, 
  zIndex: 10, 
  backgroundColor: 'white',
  borderBottom: '1px gray solid',
  paddingTop: 0
}

const reportFooterStyle = {
  marginTop: 0,
  marginLeft: 20,
  display: 'flex',
  justifyContent: 'space-around'
}

const containerStyleRight = {
  width: 200,
  maxWidth: '24vw',
  display: 'inline',
  marginLeft: 20
}

const containerStyle = {
  width: 200,
  maxWidth: '24vw',
  display: 'inline',
  marginLeft: 200
}

const sortByAttribute = (items, attr, order) => {
  return lo.orderBy(items, [(o) => o[attr]], [order])
}

export default (props) => {

  const [filterVals, updateFilterVals] = useState({})
  const [selection, updateSelection] = useState({})
  const [sortVal, updateSortVal] = useState('inked_id')
  const [order, updateOrder] = useState('asc')

  const { loading, error, data } = useQuery(QUERY, {
    fetchPolicy: 'network-only',
    variables : filterVals
  })

  const filterOnChange = async (kv) => {
    let newVals = {}
    let options = {}

    if(kv.val) {
      newVals = { ...filterVals, [`${kv.name}`]: kv.val.id }
      options = { ...selection, [`${kv.name}`] : { name: kv.val.name, id: kv.val.id } }

      const newState = { ...newVals }
      const newOptions = { ...options }

      await updateFilterVals(prv => newState)
      await updateSelection(prv => newOptions)
      
    } else {
      const { ...state } = filterVals
      const { ...ops } = selection

      delete state[kv.name]
      delete ops[kv.name]

      const newState = { ...state }
      const newOptions = { ...ops }

      await updateFilterVals(newState)
      await updateSelection(newOptions)
    }
    
  }

  const clear = () => {
    updateFilterVals({})
    updateSelection({})
  }

  const { jwt } = useAuth0()

  const exportSpecReport = useCallback((reportType) => {
    fetch(getUrlPath(`report/${reportType}/xlsx`), {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify({
          filters: filterVals
      })
    })
    .then(res => res.json())
    .then(d => download(d.url))
  })

  const createAndPrintReport = (headers, data) => {
    
    const l = headers.length
    const table1Max = l % 20 < l ? 20 : l
    const table2Max = l % 40 < l ? 40 : 20 + l;
    const table3Max = l % 60 < l ? 60 : l;

    const table1Headers = headers.slice(0, table1Max).map(h => `<th style="text-align:left; width:50px">${h}</th>`).join("")

    const table2Headers = headers.slice(table1Max, table2Max).map((h) => `<th style="text-align:left; width:50px">${h}</th>`).join("")

    const table3Headers = headers.slice(table2Max, table3Max).map((h, i) => `<th style="text-align:left; width:50px">${h}</th>`).join("")

    const table1Rows = data.map((r, i) => {
      const attr = Object.keys(r)
      const rowData = attr.slice(0, table1Max).map((a, k) => `<td style=width:50px">${r[attr[k]] ? r[attr[k]] : 'N/A'}</td>`).join("")
      return `<tr>${rowData}</tr>`
    }).join("")

    const table2Rows = data.map((r, i) => {
      const attr = Object.keys(r)
      const rowData = attr.slice(table1Max, table2Max).map((a, k) => `<td style=width:50px">${r[attr[k + table1Max]] ? r[attr[k + table1Max]] : 'N/A'}</td>`).join("")
      return `<tr>${rowData}</tr>`
    }).join("")

    const table3Rows = data.map((r, i) => {
      const attr = Object.keys(r)
      const rowData = attr.slice(table2Max, table3Max).map((a, k) => `<td style=width:50px">${r[attr[k + table2Max]] ? r[attr[k + table2Max]] : 'N/A' }</td>`).join("")
      return `<tr>${rowData}</tr>`
    }).join("")


    const table1 = `<table><thead>${table1Headers}</thead><tbody>${table1Rows}</tbody></table>`
    const table2 = `<table><thead>${table2Headers}</thead><tbody>${table2Rows}</tbody></table>`
    const table3 = `<table><thead>${table3Headers}</thead><tbody>${table3Rows}</tbody></table>`

    var tableToPrint = window.open('', '', 'height=800, width=1000')
    tableToPrint.document.write('<html>')
    tableToPrint.document.write(`<body><div>${table1}</br>${table2}</br>${table3}</div></body>`)
    tableToPrint.document.write('</html')
    tableToPrint.print()
    tableToPrint.document.close()
  }


  if(loading) {
    return <Spinner />
  }

  if(error) {
    return <span>error</span>
  }

const { clients, landmen, grantors, projects, reports, ...lookups } = data

const headers = ['Inked Record #', 'Tract #', 'Client', 'Project', 'Landman', 'Permit #', 'Grantor', 'Grantor Status', 'Property Type', 'Parcel #', 'QQ', 'Section', 
'Township', 'Range', 'State', 'County', 'Survey Permission Date (Survey Complete Date)', 'Plat Received Date (Plan and Profile Received Date)', 'Title Received Date (Permit Submitted)', 'Agreement Executed Date (Permit Executed Date)', 'Acquisition Status', 'Agreement Type', 'Recording Info',
,'Preliminary Footage', 'Price Per Foot ($)', 'Final Plat Footage (Permit Footage)', 'Footage Difference (Depth)', 'Initial Payment %', 'Initial Payment (Application Fee) ($)', 'Initial Payment Check # (Application Check #)', 'Payment Due To Exercise Option (%)', 'Payment Due To Exercise Option ($)',
'Final Payment Due Date', 'Crossing Fee ($)', 'Final Payment Check # (Crossing Fee Check #)', 'Miscellaneous Payment (Bond Requirement) ($)', 'Misc. Payment Check # (Bond Check #)', 'Total Payment ($)', 'Term Assignable', 'Term Length', 'Term Expiration Date', 'Term Abandonment', 'Term Number Of Lines', 'Term Change Size', 'Term Above Ground Appurtenance', 'Term Product Restriction',
'Term Permanent Width', 'Term TWS Length', 'Term TWS Expiration', 'Term Contract Notes', 'Station Entry', 'Station Exit', 'Existing Encumberances', 'Survey Stips', 'Construction Stips']

const updateSortValWithOrder = (s) => {
  const defaultSortOrder = 'asc'
  const newSortOrder = 'desc'

  if((s === sortVal)) {
    updateOrder(order === defaultSortOrder ? newSortOrder : defaultSortOrder)
  } else {
    updateOrder(defaultSortOrder)
  }

  updateSortVal(s)
}

const sortByAttribute = (items, attr, order) => {
  if (attr === 'name') {
    var intItems = items.filter(function(o) {
      return !isNaN(o.name);
    });

    var sortedIntItems =  lo.orderBy(intItems, function (obj) {
      return parseInt(obj.name, 10);
    }, [order]);

    var stringItems = items.filter(function(o) {
      return isNaN(o.name);
    });
 
    var sortedStringItems = lo.orderBy(stringItems, [(o) => o[attr]], [order]);

    var sortedItems = [];

    if (order === 'asc') {
      sortedItems = sortedIntItems.concat(sortedStringItems);
    } else {
      sortedItems = sortedStringItems.concat(sortedIntItems);
    }

    return sortedItems;
  }

  return lo.orderBy(items, [(o) => o[attr]], [order])
}

const calculateTotalPayment = (item) => {
  let totalPayment = null
  const shouldCalculateProperty = item.initial_payment_made || item.payment_due_to_exercise_option_dollar || item.misc_payment
  const shouldCalculatePermit = item.application_fee || item.crossing_fee || item.bond_requirement

  if(shouldCalculateProperty) {
    totalPayment = item.payment_made_to_exercise_option ? (item.initial_payment_made || 0) + (item.payment_due_to_exercise_option_dollar || 0) + (item.misc_payment || 0) : (item.initial_payment_made || 0) + (item.misc_payment || 0)
  }

  if(shouldCalculatePermit) {
    totalPayment = (item.application_fee || 0) + (item.crossing_fee || 0) + (item.bond_requirement || 0)
  }


  return totalPayment
}

const calculateFootageDifference = (item) => {
  let footageDifference = item.footage_difference

  if(item.preliminary_footage || item.final_plat_footage && !item.footage_difference) {
    footageDifference = item.final_plat_footage - item.preliminary_footage
  }

  return footageDifference
}

const removeObjects = (arr) => {
  const newReports = arr.map(r => {
    let newObj = {}
    const slcObj = lo.omit(r, ['__typename'])
    
    const keys = Object.keys(slcObj)   
    keys.forEach(k => {
      if(slcObj[k] && typeof slcObj[k] === 'object' && !(slcObj[k] instanceof Array)) {
        newObj[k] = slcObj[k].name
        
      } else {
        newObj[k] = slcObj[k]
      }
    })
    return newObj
  })
  return newReports
}

  return (
        <div>
           <ReportFilters
              clients={clients}
              landmen={landmen}
              grantors={grantors}
              projects={projects}
              lookups={lookups}
              filterOnChange={filterOnChange}
              values={selection}
              update={updateFilterVals}
              reset={clear}
           />
          <Card style={reportsTableStyle}>
            <Table id="report-table">
              <thead>
                <tr style={{ position: 'relative'}}>
                  {
                    headers.map((item, i) => <th style={headerStyle} onClick={() => 
                      { 
                        if (reports.length > 0) {
                          const currentKey = Object.keys(reports[0])[i]                         
                          return updateSortValWithOrder(currentKey)
                        } else {
                          return null
                        }
                      }} 
                      key={`report-table-header-${i}`}>{item}</th>)
                  }
                </tr>
              </thead>
              <tbody>
                {
                  sortByAttribute(removeObjects(reports), sortVal, order).map((item, i) =>
                    <tr key={`report-property-${i}`}>
                      <td>{item.inked_id}</td>
                      <td>{item.name}</td>
                      <td>{item.client}</td>
                      <td>{item.project}</td>
                      <td>{item.landman}</td>
                      <td>{item.permit_number}</td>
                      <td>{item.grantor}</td>
                      <td>{item.grantor_status}</td>
                      <td>{item.property_type}</td>
                      <td>{item.parcel_number}</td>
                      <td>{item.plss_qq}</td>
                      <td>{item.plss_section}</td>
                      <td>{item.plss_township}</td>
                      <td>{item.plss_range}</td>
                      <td>{item.state}</td>
                      <td>{item.county}</td>
                      <td>{item.survey_permission_date}</td>
                      <td>{item.plat_received_date}</td>
                      <td>{item.title_received_date}</td>
                      <td>{item.agreement_executed_date}</td>
                      <td>{item.acquisition_status}</td>
                      <td>{item.agreement_type}</td>
                      <td>{item.recording_info && item.recording_info.map(r => <p>{r}</p>)}</td>
                      <td>{fm(item.preliminary_footage)}</td>
                      <td>{fm(item.price_per_foot)}</td>
                      <td>{fm(item.final_plat_footage)}</td>
                      <td>{fm(calculateFootageDifference(item))}</td>
                      <td>{fm(item.initial_payment_percent)}</td>
                      <td>{fm(item.initial_payment_made)}</td>
                      <td>{item.initial_payment_check_number}</td>
                      <td>{fm(item.payment_due_to_exercise_option_percent)}</td>
                      <td>{fm(item.payment_due_to_exercise_option_dollar)}</td>
                      <td>{item.exercise_option_due_date}</td>
                      <td>{item.final_payment}</td>
                      <td>{item.exercise_option_check_number}</td>
                      <td>{fm(item.misc_payment)}</td>
                      <td>{item.misc_payment_check_number}</td>
                      <td>{fm(calculateTotalPayment(item))}</td>
                      <td>{item.term_assignable}</td>
                      <td>{item.term_length}</td>
                      <td>{item.term_expiration_date}</td>
                      <td>{item.term_abandonment}</td>
                      <td>{item.term_number_of_lines}</td>
                      <td>{item.term_change_size}</td>
                      <td>{item.term_above_ground_appurtenance}</td>
                      <td>{item.term_product_restriction}</td>
                      <td>{item.term_permanent_width}</td>
                      <td>{item.term_tws_length}</td>
                      <td>{item.term_tws_expiration}</td>
                      <td>{item.term_contract_notes}</td>
                      <td>{item.station_entry}</td>
                      <td>{item.station_exit}</td>
                      <td>{item.existing_encumberances && item.existing_encumberances.map(e => <p>{e}</p>)}</td>
                      <td>{item.survey_stips && item.survey_stips.map(s => <p>{s}</p>)}</td>
                      <td>{item.construction_stips && item.construction_stips.map(c => <p>{c}</p>)}</td>
                    </tr>
                    )
                }
              </tbody>
            </Table>
          </Card>
          <div style={reportFooterStyle}>
              <div>
                <Button onClick={() => createAndPrintReport(headers, sortByAttribute(removeObjects(reports), sortVal, order))} className="report-link">Print Above List</Button>
              </div>
              <div style={containerStyle}>
                <Button onClick={() => exportSpecReport('acquisition')} className="report-link">Acquisition Report</Button>
                <Button onClick={() => exportSpecReport('recording')} className="report-link">Recording Report</Button>
              </div>
              <div style={containerStyleRight}>
                <Button onClick={() => exportSpecReport('payment')} className="report-link">Payment Report</Button>
                <Button onClick={() => exportSpecReport('contract-terms')} className="report-link">Contract Terms Report</Button>
              </div>
              <div style={containerStyleRight}>
                <Button onClick={() => exportSpecReport('survey-line')} className="report-link">Survey Line List</Button>
                <Button onClick={() => exportSpecReport('construction-release')} className="report-link">Construction Release</Button>
              </div>
              <div style={containerStyleRight}>
                <Button onClick={() => exportSpecReport('due-diligence')} className="report-link">Full Due Diligence Report</Button>
              </div>
            </div>
    </div>

  )
}