import React, { useCallback, useState } from 'react'
import { Table, Spinner, InputGroup, CardEntitySearch, Card, Button } from '../../elements'
import * as lo from 'lodash'
import { navigate, Redirect } from '@reach/router';
import { useAuth0 } from '../../hooks/auth0'
import download from 'downloadjs'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { useDebounce } from '../../hooks/debounce'
import { getUrlPath } from '../../utils/helpers'
import { CanEdit } from '../WithAuth'


const QUERY = gql`
query($keyword: String, $sub: String) {
  projects(keyword: $keyword) {
    id
    name
    manager
    client_name
    state
    county
    project_manager
    products
    afe_po_number
    number_of_lines
    target_release_to_build
    }

   userAuth(sub: $sub) {
     role_id
   }
  }
`

const filterButtonStyle = {
  marginTop: '10px'
}

const headerStyle = { 
  position: 'sticky', 
  top: 0, 
  zIndex: 10, 
  backgroundColor: 'white',
  borderBottom: '1px gray solid',
  paddingTop: 40
}

const sortByAttribute = (items, attr, order) => {
  return lo.orderBy(items, [(o) => o[attr]], [order])
}

const ProjectSearchResults = ({ items = [], ...props }) => {
  const onClick = (id) => () => navigate(`/project/${id}/edit`)
  const updateSortVal = props.updateSortVal

  const canEdit = CanEdit('Projects', props.roleid)

  return (
    <Table>
      <thead>
        <tr style={{ position: 'relative'}}>
          <th style={headerStyle} onClick={() => updateSortVal('client_name')}>Client</th>
          <th style={headerStyle} onClick={() => updateSortVal('state')}>State</th>
          <th style={headerStyle} onClick={() => updateSortVal('county')}>County</th>
          <th style={headerStyle} onClick={() => updateSortVal('name')}>Project</th>
          <th style={headerStyle} onClick={() => updateSortVal('manager')}>Project Manager</th>
          <th style={headerStyle} onClick={() => updateSortVal('afe_po_number')}>AFE</th>
          <th style={headerStyle} onClick={() => updateSortVal('number_of_lines')}>No. of Lines</th>
          <th style={headerStyle} onClick={() => updateSortVal('products')}>Products</th>
          <th style={headerStyle} onClick={() => updateSortVal('target_release_to_build')}>Target RTB</th>
        </tr>
      </thead>
      <tbody>
        {items.map(( { id, ...item }) => (
          <tr key={id} onDoubleClick={canEdit ? onClick(id) : null}>
            <td>{item.client_name}</td>
            <td>{item.state}</td>
            <td>{item.county}</td>
            <td>{item.name}</td>
            <td>{item.manager}</td>
            <td>{item.afe_po_number}</td>
            <td>{item.number_of_lines}</td>
            <td>{item.products}</td>
            <td>{item.target_release_to_build}</td>
            
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default () => {

  const [keyword, updateKeyword] = useState('')
  const debouncedSearch = useDebounce(keyword, 500)

  const { jwt, profile } = useAuth0()

  const { loading, error, data } = useQuery(QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      keyword: debouncedSearch,
      sub: profile.sub
    }
  })

  const [sortVal, updateSortVal] = useState('client_name')
  const [order, updateOrder] = useState('asc')

  const exportProjects = useCallback(() => {
    fetch(getUrlPath('project/xlsx'), {
      headers: {
        'Authorization' : `Bearer ${jwt}`
      }
    }).then(res => res.json()).then(d => download(d.url))
  })

  const updateSortValWithOrder = (s) => {
    const defaultSortOrder = 'asc'
    const newSortOrder = 'desc'

    if(s === sortVal) {
      updateOrder(order === defaultSortOrder ? newSortOrder : defaultSortOrder)
    }
    
    updateSortVal(s)
  }

  if(loading) {
    return <Spinner />
  }

  return (
    <>
      <CardEntitySearch 
        title="Projects" 
        buttonText="New Project" 
        buttonTo="/project/new"
        exportClick={exportProjects}
        roleid={data && data.user ? data.user.role_id : null}
      >
        <InputGroup 
            placeholder='Keyword...'
            style={{ marginTop: 10 }}
            id={'project-search-keyword'}
            value={keyword}
            onChange={(evt) => updateKeyword(evt.target.value)}
            rightElement = {
              <div style={filterButtonStyle}>
                <Button
                minimal
                icon='cross'
                onClick={() => updateKeyword('')} 
                />
              </div>
            }
        />
      </CardEntitySearch>

      <Card style={{ maxHeight: 800, marginTop: 0, paddingTop: 0, overflowY: 'scroll'}}>
      {(loading || !data) && <Spinner />}
      {!(loading || !data) && <ProjectSearchResults roleid={data && data.user && data.user.role_id}  updateSortVal={updateSortValWithOrder} items={sortByAttribute(data.projects, sortVal, order)} />}
      </Card>
    </>
  )
}
