import React, { useState } from 'react'
import { Formik } from 'formik'
import UserForm from '../UserForm'
import { gql } from 'apollo-boost'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { UserSchema } from '@inked/validation'
import { navigate } from '@reach/router'
import { Spinner } from '../../elements'

const QUERY = gql`
    query($id: String) {
        clients {
            id
            name
        }
        roles {
            id
            name
        }
        projects {
            id
            name
        }
        user(id: $id) {
            id
            sub
            first_name
            last_name
            role {
                id
                name
            }
            email
            clients {
                id
                name
            }
            projects {
                id
                name
            }
        }
    }
`

const MUTATION = gql`
    mutation UpdateUser($user: UserInput!, $id: String!, $sub: String!) {
        updateUser(user: $user, id: $id, sub: $sub) {
            id
            sub
        }
    }
`

export default ({ userID }) => {
    const { loading, error, data } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            id: userID
        }
    })

    const [updateUser] = useMutation(MUTATION)
    const [createError, updateCreateError] = useState(false)
    const [createSuccess, updateCreateSuccess] = useState(false)

    if(loading) {
        return <Spinner />
    }

    if (error) {
        console.error(error)
    
        return <span>error</span>
      }

    const { clients, roles, projects, user } = data

    const onSubmit = async (props) => {
        await updateCreateError(false)
        await updateCreateSuccess(false)

        const context = {
            isEdit: true
        }

        const model = UserSchema.cast(props, { stripUnknown: true, context })

        const res = await updateUser({ variables: { user: model, id: userID, sub: user.sub }}).catch(e => updateCreateError(true))

        if(res) {
            navigate(`/user/${userID}/edit`)
            updateCreateSuccess(true)
        }
    }

    const onReset = () =>  { 
        updateCreateError(false)
        updateCreateSuccess(false)
        navigate(`/user/${userID}/edit`) 
    }

    return(
        <Formik 
            
            enableReinitialize
            initialValues={user}
            onSubmit={onSubmit}
            onReset={onReset}
            render={({ errors, ...props }) => { 
                return(
                    <UserForm
                    isEdit
                    error={createError}
                    success={createSuccess}
                    clients={clients}
                    roles={roles}
                    projects={projects}
                    {...props}
                    />
            )}}
            validationSchema={UserSchema}
        />
    )
}