import React, { useState } from 'react'
import { Formik } from 'formik'
import { gql } from 'apollo-boost'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Spinner } from '../../elements'
import PermitForm from '../PermitForm'
import { useAuth0 } from '../../hooks/auth0'
import { PermitSchema } from '@inked/validation'
import { inputToArcGIS } from '../../utils/helpers'
import { updatePermitFeature } from '../../utils/gis'
import { Alert, Icon } from '@blueprintjs/core';


const FRAGMENT = gql`
    fragment PermitFragment on Permit {
        id
        name
        inked_id
        client {
            id
            name
            permit_urls {
                url
            }
        }
        project {
            id
            name
            client {
                id
                name
            }
            permit_urls {
                url
            }
        }
        name
        tract_no
        permit_type {
            id
            name
        }
        landman {
            id
            name
        }
        inked_id
        grantor {
            id
            name
        }
        permit_number
        plss_qq
        plss_section {
            id
            name
        }
        plss_township {
            id
            name
        }
        plss_range {
            id
            name
        }
        existing_encumberances {
            id
            reception_number
            encumberance_type {
                id
                name
            }
            property_id
            permit_id
        }

        recording_info {
        id
        agreement_type {
            id
            name
        }
        recording_date
        reception_number
        property_id
        permit_id
        }

    survey_stips {
      id
      entry
    }

    construction_stips {
      id
      entry
    }

    state {
        id
        name
    }
    county {
        id
        name
    }
    acquisition_status {
        id
        name
    }
    survey_complete_date
    plan_profile_received_date
    submitted_date
    executed_date
    agreement_type {
        id
        name
    }

    footage
    depth     
    application_fee
    application_check_number
    crossing_fee
    crossing_fee_check_number
    bond_requirement
    bond_check_number
    total_payment
    term_assignable {
        id
        name
    }
    term_length {
        id
        name
    }
    term_expiration_date
    term_abandonment {
        id
        name
    }
    term_number_of_lines {
        id
        name
    }
    term_change_size {
        id
        name
    }
    term_above_ground_appurtenance {
        id
        name
    }
    term_product_restriction {
        id
        name
    }
    term_permanent_width {
        id
        name
    }
    term_tws_length {
        id
        name
    }
    term_tws_expiration {
        id
        name
    }
    term_contract_notes
    station_entry
    station_exit
    contact_history {
        id
        date
        user
        notes
    }
    }
`

const PERMITQUERY = gql`
    ${FRAGMENT}
    query($id: ID!) {
        permit(id: $id) {
            ...PermitFragment
        }
        clients {
            id
            name
        }
        projects {
            id
            name
            client_id
            client_name
            property_service_url
            permit_service_url
        }
        landmen {
            id
            name
        }
        grantors {
            id
            name
        }
        permit_type {
            id
            name
        }
        plss_section {
            id
            name
        }
        plss_township {
            id
            name
        }
        plss_range {
            id
            name
        }

        encumberance_types {
            id
            name
        }

        states {
            id
            name
        }

        property_states {
            id
            name
        }

        counties {
            id
            name
            state_id
        }
        acquisition_statuses {
            id
            name
        }
        agreement_types {
            id
            name
        }

        term_assignable_types {
            id
            name
        }

        term_lengths {
            id
            name
        }

        term_abandonment_types {
            id
            name
        }

        term_number_of_lines {
            id
            name
        }

        term_change_sizes {
            id
            name
        }

        term_above_ground_appurtenance {
            id
            name
        }

        term_product_restriction {
            id 
            name
        }

        term_permanent_width {
            id
            name
        }

        term_tws_length {
            id
            name
        }

        term_tws_expiration {
            id
            name
        }

    }
`

const MUTATION = gql`
    ${FRAGMENT}
    mutation UpdatePermit($id: ID!, $permit: PermitInput!) {
        updatePermit(id: $id, permit: $permit) {
            ...PermitFragment
        }
    }
`



export default ({ permitID }) => {
    const { profile } = useAuth0()
    const [gisSuccess, updateGisSuccess] = useState(true)

    let { loading, error, data } = useQuery(PERMITQUERY, {
        fetchPolicy: 'network-only',
        variables: {
            id: permitID
        }
    })

    const [updatePermit] = useMutation(MUTATION)

    if (loading) {
        return <Spinner />
    }

    if (error) {
        return <span>error</span>
    }

    const onSubmit = async (props) => {
        let model = PermitSchema.cast(props, { stripUnknown: true })
        const permitUrls = props.client ? props.client.permit_urls : []

        const permit = inputToArcGIS(props)
        await updatePermit({
            variables: {
                id: permitID,
                permit: model
            }
        })

        if (!permit.Inked_ID || permitUrls.length === 0) {
            updateGisSuccess(false)
        }

        if (permit.Inked_ID && permitUrls.length > 0) {
            const url = permitUrls[0].url
            const result = await updatePermitFeature(permit, url, props.client.name)
            updateGisSuccess(result)
        }
    }

    const onReset = () => window.history.go(-1)

    const { clients, landmen, permit, grantors, projects, ...lookups } = data

    let client = null
    if (permit.project) {
        const permitClient = permit.project.client
        client = {
            id: permitClient.id,
            name: permitClient.name
        }
    }

    return (
        <div>
            <Formik
                validationSchema={PermitSchema}
                enableReinitialize
                onSubmit={onSubmit}
                onReset={onReset}
                initialValues={data.permit}
                render={(props) => (
                    <PermitForm
                        clients={clients}
                        landmen={landmen}
                        projects={projects}
                        grantors={grantors}
                        lookups={lookups}
                        inkedID={data.permit.inked_id}
                        user={profile.nickname}
                        projectClient={client}
                        {...props}
                    />
                )}
            />
            <Alert
                isOpen={!gisSuccess}
                onClose={() => updateGisSuccess(null)}
                canOutsideClickCancel
            >
                {
                    !gisSuccess &&
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Icon style={{ color: 'red' }} icon="cross" />
                        <div>ArcGIS update unsuccessful</div>
                    </div>
                }
            </Alert>
        </div>
    )
}