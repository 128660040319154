import React from 'react'
import { usePreventWindowUnload } from '../../hooks/preventWindowUnload'

import { 
  ButtonLink,
  Card, 
  CardWithBreadcrumb, 
  Form,
  FormikInput, 
  CancelButton,
  SaveButton,
  FormikSuggest,
  FormikTextArea,
  FormikPhoneMaskedInput
} from '../../elements'

const containerStyle = {
  flex: 1,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'stretch'
}

const cardStyle = {
  width: '23vw',
  minWidth: 300
}

const extensionStyle = {
  width: 100,
  marginLeft: 10
}

const phoneContainerStyle = {
  display: 'flex'
}

export default ({ isNew, values, states, dirty }) => {
  usePreventWindowUnload(dirty)

  return (
    <Form>
      <CardWithBreadcrumb items={[{ text: 'Landmen', to: '/landman' }, { text: values.name }]}>
        <ButtonLink
          disabled={isNew}
          icon="label"
          to={`/property?landman=${values.id}`}
        >
          Properties
        </ButtonLink>
        <CancelButton />
        <SaveButton />
      </CardWithBreadcrumb>

      <div style={containerStyle}>
        <Card style={cardStyle}>
          <FormikInput
            name="name"
            label="Landman Name"
            placeholder="Landman Name"
            leftIcon="projects"
          />
        </Card>

        <Card style={cardStyle}>
          <FormikInput
            name="details.address_1"
            label="Address"
            placeholder="Address"
            leftIcon="map-marker"
          />

          <FormikInput
            name="details.city"
            label="City"
            placeholder="City"
            leftIcon="map-marker"
          />

          <FormikInput
            name="details.zip_code"
            label="Zip Code"
            placeholder="Zip Code"
            leftIcon="map-marker"
          />

          <FormikSuggest
            name="details.state"
            label="State"
            placeholder="State"
            leftIcon="map-marker"
            items={states}
          />
        </Card>

        <Card style={cardStyle}>
          <div style={phoneContainerStyle}>
          <FormikPhoneMaskedInput
            name="details.phone_1"
            label="Phone (main)"
            placeholder="Phone (main)"
            leftIcon="phone"
            type="tel"
          />
            <div style={extensionStyle}>
              <FormikInput
                name={`details.phone_1_ext`}
                label="Ext."
                placeholder="Ext."
              />
            </div>
          </div>

          <div style={phoneContainerStyle}>
            <FormikPhoneMaskedInput
              name="details.phone_2"
              label="Phone (2)"
              placeholder="Phone (2)"
              leftIcon="phone"
              type="tel"
            />
             <div style={extensionStyle}>
                <FormikInput
                  name={`details.phone_2_ext`}
                  label="Ext."
                  placeholder="Ext."
                />
            </div>
          </div>

          <FormikInput
            name="details.email_1"
            label="Email (main)"
            placeholder="Email (main)"
            leftIcon="envelope"
            type="email"
          />

          <FormikInput
            name="details.email_2"
            label="Email (2)"
            placeholder="Email (2)"
            leftIcon="envelope"
            type="email"
          />
        </Card>

        <Card style={cardStyle}>
          <FormikInput
            name="details.website"
            label="Web / Social Media"
            placeholder="Web / Social Media"
            leftIcon="link"
          />

          <FormikTextArea
            name="details.notes"
            label="Notes"
            placeholder="Notes"
          />
        </Card>
      </div>
    </Form>
  )
}