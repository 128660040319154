import React from 'react'
import { Formik } from 'formik'
import { navigate } from '@reach/router'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { LandmanSchema } from '@inked/validation'

import LandmanForm from '../LandmanForm'
import { Spinner } from '../../elements'

const FRAGMENT = gql`
  fragment LandmanFragment on Landman {
    id
    name

    details {
      address_1
      city
      state {
        id
        name
      }
      zip_code

      phone_1
      phone_1_ext
      phone_2
      phone_2_ext
      email_1
      email_2

      website
      notes
    }
  }
`

const QUERY = gql`
  ${FRAGMENT}
  query ($id: ID!) {
    landman(id: $id) {
      ...LandmanFragment
    }

    states {
      id
      name
    }
  }
`

const MUTATION = gql`
  mutation UpdateLandman($id: ID!, $landman: LandmanInput!) {
    updateLandman(id: $id, landman: $landman) {
      ...LandmanFragment
    }
  }
  ${FRAGMENT}
`

export default ({ landmanID }) => {
  const [updateLandman] = useMutation(MUTATION)
  const { loading, error, data } = useQuery(QUERY, { 
    fetchPolicy: 'network-only',
    variables: {
      id: landmanID
    }
  })

  if (loading) {
    return <Spinner />
  }

  if (error) {
    console.error(error)

    return <span>error</span>
  }

  const onSubmit = async (props) => {
    const model = LandmanSchema.cast(props, { stripUnknown: true })

    await updateLandman({ variables: {
      id: landmanID,
      landman: model
    }})
  }

  const onReset = () => navigate('/landman')

  return (
    <Formik
      initialValues={data.landman}
      onSubmit={onSubmit}
      onReset={onReset}
      render={props => <LandmanForm {...props} states={data.states} />}
      validationSchema={LandmanSchema}
    />
  )
}