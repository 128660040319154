import * as Yup from 'yup';
import LookupType from './LookupType';
export default Yup.object().shape({
  website: Yup.string().label('Client Name').nullable(),
  notes: Yup.string().label('Notes').nullable(),
  address_1: Yup.string().label('Address').nullable(),
  city: Yup.string().label('City').nullable(),
  zip_code: Yup.string().label('Zip Code').nullable(),
  state: LookupType.label('State').nullable(),
  phone_1: Yup.string().label('Phone (main)').nullable(),
  phone_1_ext: Yup.string().label('Ext.').nullable(),
  phone_2: Yup.string().label('Phone (2)').nullable(),
  phone_2_ext: Yup.string().label('Ext.').nullable(),
  email_1: Yup.string().label('Email (main)').nullable(),
  email_2: Yup.string().label('Email (2)').nullable()
});