import React, { useState, useCallback } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { useAuth0 } from '../../hooks/auth0'
import download from 'downloadjs'
import * as lo from 'lodash'
import { navigate } from '@reach/router'
import { useDebounce } from '../../hooks/debounce'
import { Spinner, Table,Card, CardEntitySearch, InputGroup, Button } from '../../elements'
import { getUrlPath } from '../../utils/helpers'
import { CanEdit } from '../WithAuth'

const filterButtonStyle = {
    marginTop: '10px'
  }

  const headerStyle = { 
    position: 'sticky', 
    top: 0, 
    zIndex: 10, 
    backgroundColor: 'white',
    borderBottom: '1px gray solid',
    paddingTop: 40
  }

const QUERY = gql`
    query($keyword: String) {
        permits(keyword: $keyword) {
            id
            inked_id
            name
            client
            project
            landman
        }
    }
`
const sortByAttribute = (items, attr, order) => {
  if (attr === 'name') {
    var intItems = items.filter(function(o) {
        return !isNaN(o.name);
      });
  
      var sortedIntItems =  lo.orderBy(intItems, function (obj) {
        return parseInt(obj.name, 10);
      }, [order]);
  
      var stringItems = items.filter(function(o) {
        return isNaN(o.name);
      });
   
      var sortedStringItems = lo.orderBy(stringItems, [(o) => o[attr]], [order]);
  
      var sortedItems = [];
  
      if (order === 'asc') {
        sortedItems = sortedIntItems.concat(sortedStringItems);
      } else {
        sortedItems = sortedStringItems.concat(sortedIntItems);
      }
  
      return sortedItems;
  }
  
  return lo.orderBy(items, [(o) => o[attr]], [order])
}

const PermitSearchResults = ({ items = [], ...props}) => {
    const onClick = (id) => () => navigate(`/permit/${id}/edit`)
    const updateSortVal = props.updateSortVal

    const canEdit = CanEdit()

    return(
        <Table>
            <thead>
                <tr style={{ position: 'relative '}}>
                    <th style={headerStyle} onClick={() => updateSortVal('inked_id')}>Inked Record No.</th>
                    <th style={headerStyle} onClick={() => updateSortVal('name')}>Tract No.</th>
                    <th style={headerStyle} onClick={() => updateSortVal('client')}>Client</th>
                    <th style={headerStyle} onClick={() => updateSortVal('project')}>Project</th>
                    <th style={headerStyle} onClick={() => updateSortVal('landman')}>Landman</th>
                </tr>
            </thead>
            <tbody>
                {items.map(({id, ...item}) => (
                    <tr key={id} onDoubleClick={canEdit ? onClick(id) : null}>
                        <td>{item.inked_id}</td>
                        <td>{item.name}</td>
                        <td>{item.client}</td>
                        <td>{item.project}</td>
                        <td>{item.landman}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )

}

export default () => {
    const [keyword, updateKeyword] = useState('')
    const debouncedSearch = useDebounce(keyword, 500)
    const [sortVal, updateSortVal] = useState('inked_id')
    const [order, updateOrder] = useState('asc')

    const { loading, error, data } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            keyword: debouncedSearch
        }
    })

    const { jwt } = useAuth0()

    const exportPermits = useCallback(() => {
        fetch(getUrlPath('/permit/xlsx'), {
          headers: {
            'Authorization': `Bearer ${jwt}`
          }
        }).then(res => res.json()).then(d => download(d.url))
      })

    const updateSortValWithOrder = (s) => {
        const defaultSortOrder = 'asc'
        const newSortOrder = 'desc'

        if(s === sortVal) {
            updateOrder(order === defaultSortOrder ? newSortOrder : defaultSortOrder)
        }

        updateSortVal(s)
    }

    if(loading) {
        return <Spinner />
    }

    return(
        <>
            <CardEntitySearch
                title="Permits"
                buttonText="New Permit"
                buttonTo="/permit/new"
                exportClick={exportPermits}
            >
                <InputGroup
                    placeholder="Keyword..."
                    style={{ marginTop: 10}}
                    id={'permit-search-keyword'}
                    value={keyword}
                    onChange={(evt) => updateKeyword(evt.target.value)}
                    rightElement = {
                        <div style={filterButtonStyle}>
                            <Button
                            minimal
                            icon='cross'
                            onClick={() => updateKeyword('')}
                            />
                        </div>
                    }
                />
            </CardEntitySearch>
            <Card style={{ maxHeight: 800, paddingTop: 0, marginTop: 0, overflowY: 'scroll'}}>
                {(loading || !data) && <Spinner />}
                {!(loading || !data) && <PermitSearchResults updateSortVal={updateSortValWithOrder} items={sortByAttribute(data.permits, sortVal, order)} />}
            </Card>
        </>
    )
}