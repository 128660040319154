import request from 'request'

const REACT_APP_UPDATE_PRODUCTION_ARCGIS = JSON.parse(process.env.REACT_APP_UPDATE_PRODUCTION_ARCGIS.toLowerCase());


export const getAuthToken = async (props) => {
  const appId = "zoQY5XAXRBjjWgwD"
  const appSecret = "2cbc3cfd0bca4b1fa6689aca2051fba2"

  const authUrl = `https://www.arcgis.com/sharing/rest/oauth2/token/?client_id=${appId}&client_secret=${appSecret}&grant_type=client_credentials`

  const response = await fetch(authUrl, {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    }
  }).then(res => {
    return res.json()
  }).catch(err => console.log(err))

  const access_token = response.access_token

  return access_token
}

export function queryGISService(options, handleError) {
  return new Promise(function (resolve, reject) {
    request(options, function (err, response, body) {
      if (!err && response.statusCode == 200) {
        resolve(JSON.parse(body));
      }

      if (err && handleError) {
        handleError(false)
      }

      if (err) {
        reject(console.log(err))
      }

    })
  })
}

export const updatePropertyFeature = async (property, serviceUrl, handleError, client_url, clientName) => {

  let shouldUpdate = false;

  if (REACT_APP_UPDATE_PRODUCTION_ARCGIS) {
    shouldUpdate = true;
  }
  else if (clientName.toLowerCase().indexOf("sample") != -1) {
    shouldUpdate = true;
  }


  if (shouldUpdate == false) {
    return { isSuccess: true, short: true }
  }

  //const propertyMockUrl = 'https://services9.arcgis.com/DLCWUJibUNQwkpc2/arcgis/rest/services/Properties_Mock_Layer/FeatureServer'
  const token = await getAuthToken()
  let isSuccess = null
  let short = false

  const options = {
    method: 'POST',
    url: serviceUrl, // serviceUrl is the source that we'll search for a polygon based on parcel number
    form: {
      f: 'json',
      token
    }
  }

  const featureService = await queryGISService(options, handleError)

  if (!featureService.error) {

    let geometry;
    let existingProperty;

    const featureServiceLayers = featureService.layers
    const layerIds = featureServiceLayers.map(l => l.id)

    for (var i = 0; i < layerIds.length; i++) {
      const url = `${serviceUrl}/${i}/query`

      // Search the ArcGis url associated with the project for the matching parcel so we can get the geometry
      const parcelSearchOptions = {
        method: 'POST',
        url,
        form: {
          f: 'json',
          where: `Parcel_ID='${property.Parcel_ID}'`,
          outFields: '*',
          returnGeometry: true,
          token
        }
      }

      // Search the ArcGis url associated with the client for an existing property record in their layer
      const propertyLayerSearchOptions = {
        method: 'POST',
        url: `${client_url}/0/query`,
        form: {
          f: 'json',
          where: `Inked_ID='${property.Inked_ID}'`,
          outFields: '*',
          token
        }
      }

      const foundParcel = await queryGISService(parcelSearchOptions)
      const foundProperty = await queryGISService(propertyLayerSearchOptions)

      const hasErrors = foundParcel.error || foundProperty.error

      if (!hasErrors) {
        const foundSourceGeometry = foundParcel.features.length > 0
        const foundDestinationProperty = foundProperty.features.length > 0

        short = foundSourceGeometry

        if (foundSourceGeometry && foundDestinationProperty) {
          geometry = foundParcel.features[0].geometry
          existingProperty = foundProperty.features[0]

          const updates = [{
            "geometry": geometry,
            "attributes":
            {
              "OBJECTID": `${existingProperty.attributes["OBJECTID"]}`,
              ...property
            }
          }]

          const updateURL = `${client_url}/0/applyEdits`

          const options = {
            method: 'POST',
            url: updateURL,
            form: {
              token,
              rollbackOnFailure: true,
              f: "json",
              updates: JSON.stringify(updates),
              returnServiceEditsOption: "originalAndCurrentFeature"
            }
          }

          const query = await queryGISService(options)
          isSuccess = query.updateResults ? query.updateResults[0].success : false
          break
        }

        if (foundSourceGeometry && !foundDestinationProperty) {
          geometry = foundParcel.features[0].geometry

          const adds = [{
            "geometry": geometry,
            "attributes":
            {
              ...property
            }
          }]

          const addURL = `${client_url}/0/applyEdits`

          const options = {
            method: 'POST',
            url: addURL,
            form: {
              token,
              rollbackOnFailure: true,
              f: "json",
              adds: JSON.stringify(adds)
            }
          }

          const query = await queryGISService(options)
          isSuccess = query.addResults ? query.addResults[0].success : false

          break
        }

        if (!foundSourceGeometry && foundDestinationProperty) {
          existingProperty = foundProperty.features[0]

          const updates = [{
            "attributes":
            {
              "OBJECTID": `${existingProperty.attributes["OBJECTID"]}`,
              ...property
            }
          }]

          const updateURL = `${client_url}/0/applyEdits`

          const options = {
            method: 'POST',
            url: updateURL,
            form: {
              token,
              rollbackOnFailure: true,
              f: "json",
              updates: JSON.stringify(updates)
            }
          }

          const query = await queryGISService(options)
          isSuccess = query.updateResults ? query.updateResults[0].success : false
          break

        }

        if (!foundSourceGeometry && !foundDestinationProperty) {
          const adds = [{
            "attributes":
            {
              ...property
            }
          }]

          const addURL = `${client_url}/0/applyEdits`

          const options = {
            method: 'POST',
            url: addURL,
            form: {
              token,
              rollbackOnFailure: true,
              f: "json",
              adds: JSON.stringify(adds)
            }
          }

          const query = await queryGISService(options)

          isSuccess = query.addResults ? query.addResults[0].success : false
          break
        }

      } else {
        isSuccess = false
        break
      }
    }
  }
  return { isSuccess, short }
}

export const updatePermitFeature = async (permit, client_url, clientName) => {

  let shouldUpdate = false;

  if (REACT_APP_UPDATE_PRODUCTION_ARCGIS) {
    shouldUpdate = true;
  }
  else if (clientName.toLowerCase().indexOf("sample") != -1) {
    shouldUpdate = true;
  }

  if (shouldUpdate == false) {
    return { isSuccess: true, short: true }
  }




  //const permitMockUrl = `https://services9.arcgis.com/DLCWUJibUNQwkpc2/arcgis/rest/services/Permits_Mock_Layer/FeatureServer`
  const token = await getAuthToken()
  let isSuccess = null

  const permitOptions = {
    method: 'POST',
    url: `${client_url}/0/query`,
    form: {
      f: 'json',
      where: `Inked_ID='${permit.Inked_ID}'`,
      outFields: '*',
      token
    }
  }

  const permitFeature = await queryGISService(permitOptions)

  const hasErrors = permitFeature.error

  if (!hasErrors) {
    let mockFeature
    const foundMock = permitFeature.features.length > 0

    if (foundMock) {
      mockFeature = permitFeature.features[0]

      const updates = [{
        "attributes":
        {
          "OBJECTID": `${mockFeature.attributes["OBJECTID"]}`,
          ...permit
        }
      }]

      const updateURL = `${client_url}/0/applyEdits`

      const options = {
        method: 'POST',
        url: updateURL,
        form: {
          token,
          f: "json",
          updates: JSON.stringify(updates)
        }
      }

      const query = await queryGISService(options)

      isSuccess = query.updateResults ? query.updateResults[0].success : false
    }

    if (!foundMock) {

      const adds = [{
        "attributes": {
          ...permit
        }
      }]

      const addURL = `${client_url}/0/applyEdits`

      const options = {
        method: 'POST',
        url: addURL,
        form: {
          token,
          rollbackOnFailure: true,
          f: "json",
          adds: JSON.stringify(adds)
        }
      }

      const query = await queryGISService(options)
      isSuccess = query.addResults ? query.addResults[0].success : false
    }
  } else {
    isSuccess = false
  }
  return isSuccess
}

export const deletePropertyFeature = async (inkedID) => {
  const propertyMockUrl = 'https://services9.arcgis.com/DLCWUJibUNQwkpc2/arcgis/rest/services/Properties_Mock_Layer/FeatureServer'
  const token = await getAuthToken()
  let isSuccess = false

  const propertyOptions = {
    method: 'POST',
    url: `${propertyMockUrl}/0/query`,
    form: {
      f: 'json',
      where: `Inked_ID='${inkedID}'`,
      outFields: 'ObjectID',
      token
    }
  }

  const propertyFeature = await queryGISService(propertyOptions)

  const hasErrors = propertyFeature.error

  if (!hasErrors && propertyFeature.features.length > 0) {
    const mockFeature = propertyFeature.features[0]
    const deleteID = mockFeature.attributes["OBJECTID"]

    const deleteOptions = {
      method: 'POST',
      url: `${propertyMockUrl}/0/applyEdits`,
      form: {
        f: 'json',
        token,
        deletes: JSON.stringify([deleteID])
      }
    }

    const query = await queryGISService(deleteOptions)
    isSuccess = query.deleteResults ? query.deleteResults[0].success : false

  }
  return isSuccess
}