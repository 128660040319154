import * as Yup from 'yup';
import LookupType from './LookupType';
export var UserSchema = Yup.object().shape({
  email: Yup.string().email().nullable().label('Email').required(),
  first_name: Yup.string().nullable().label('First Name').required('First name is required'),
  last_name: Yup.string().nullable().label('Last Name').required('Last name is required'),
  password: Yup.string().when(['isEdit'], {
    is: function is(isEdit) {
      return isEdit;
    },
    then: Yup.string(),
    otherwise: Yup.string().min(8).label('Password').matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, 'Password must container 1 letter, 1 number, and 1 special character').required('Password is required')
  }),
  password_confirm: Yup.string().when(['isEdit'], {
    is: function is(isEdit) {
      return isEdit;
    },
    then: Yup.string(),
    otherwise: Yup.string().min(8).label('Confirm Password').required('Please confirm your password').matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, 'Password must container 1 letter, 1 number, and 1 special character').test('passwords-match', 'Passwords do not match', function (value) {
      return this.parent.password === value;
    })
  }),
  clients: Yup.array().of(LookupType).when(['role', 'projects'], {
    is: function is(role, projects) {
      return role && (role.id === 6 || role.id === 7) && projects && projects.length > 0;
    },
    then: Yup.array().of(LookupType),
    otherwise: Yup.array().of(LookupType).required("Client is required")
  }),
  projects: Yup.array().of(LookupType).when(['role', 'clients'], {
    is: function is(role, clients) {
      return role && (role.id === 6 || role.id === 7) && clients && clients.length > 0 || role && role.id !== 6 && role.id !== 7 || !role;
    },
    then: Yup.array().of(LookupType),
    otherwise: Yup.array().of(LookupType).required("Project is required")
  }),
  role: Yup.object().shape({
    id: Yup.mixed(),
    name: Yup.string()
  }).nullable().default(null).required('Role is required')
}, [['clients', 'projects']]);