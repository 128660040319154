import React, { useState, useCallback, useEffect } from 'react'
import { Form, FieldArray } from 'formik'
import { gql } from 'apollo-boost'
import * as lo from 'lodash'
import { Overlay, FileInput, RadioGroup, Radio, TextArea, Icon } from '@blueprintjs/core'
import { useAuth0 } from '../../hooks/auth0'
import { useMutation } from '@apollo/react-hooks'
import download from 'downloadjs'
import { getUrlPath, formikAutoCalc, orderByName } from '../../utils/helpers'
import { usePreventWindowUnload } from '../../hooks/preventWindowUnload'


import {
  Card,
  CardWithBreadcrumb,
  FormikInput,
  FormikSimpleSelect,
  FormikSuggest,
  FormikTextArea,
  FormikNumberMaskedInput,
  CancelButton,
  SaveButton,
  Button,
  FormikEditableText,
  Table,
  DocumentTable,
  FormikCheckbox
} from '../../elements'


const containerStyle = {
  flex: 1,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'stretch'
}

const cardStyle = {
  minWidth: 300
}

const modalCardStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}

const modalFooterStyle = {
  display: 'block',
  marginTop: '20px',
  textAlign: 'center'
}

const gisStatusStyle = {
  marginTop: '10px',
  marginLeft: '10px'
}

const documentTypeStyle = {
  marginTop: '10px'
}

const docColStyle = {
  verticalAlign: 'middle'
}

const requiredStyle = {
  color: 'red',
  fontWeight: '500'
}

const cardHeaderStyle = {
  marginTop: 0
}


const recordingCard = {
  minWidth: 650
}

const surveyCard = {
  minWidth: 500
}

const constructionCard = {
  minWidth: 400
}

const propertyCard = {
  minWidth: 900
}

const contactCard = {
  minWidth: 900
}

const documentCard = {
  minWidth: 900
}

const isExtensionPreviewable = (extension) => {
  let e
  switch (extension) {
    case '.pdf':
      e = true
      break
    case '.docx':
      e = true
      break
    case '.doc':
      e = true
      break
    default:
      e = false
  }
  return e
}

const MUTATION = gql`
  mutation createDocument($document: PropertyDocumentInput!)
    {
      createDocument(document: $document) {
        id
      }
    }`

export default ({
  values,
  user,
  clients,
  grantors,
  landmen,
  projects,
  lookups,
  id,
  isNew,
  inkedID,
  documents,
  projectClient,
  gisSuccess,
  dirty
}) => {
  const { jwt } = useAuth0()
  const [createDocument] = useMutation(MUTATION)

  const [modal, modalToggle] = useState(false)
  const [documentName, updateDocumentName] = useState(null)
  const [fileToUpload, updateFileToUpload] = useState(null)
  const [documentType, updateDocumentType] = useState(null)
  const [paymentMade, updatePaymentMade] = useState(values.payment_made_to_exercise_option)
  const [tableDocuments, updateTableDocuments] = useState(documents || [])
  const [frame, frameToggle] = useState(false)
  const [previewUrl, updatePreviewUrl] = useState(null)
  const [extensionType, updateExtensionType] = useState(null)

  const [client, updateClient] = useState(projectClient)

  const resetModal = () => {
    modalToggle(false)
    updateDocumentName(null)
    updateFileToUpload(null)
    updateDocumentType(null)
  }

  const resetFrame = () => {
    frameToggle(false)
  }

  const agreementDocuments = tableDocuments.filter(d => d.document_type_id === 1)
  const titleDocuments = tableDocuments.filter(d => d.document_type_id === 2)
  const surveyDocuments = tableDocuments.filter(d => d.document_type_id === 3)
  const paymentDocuments = tableDocuments.filter(d => d.document_type_id === 4)
  const contactDocuments = tableDocuments.filter(d => d.document_type_id === 5)
  const constructionDocuments = tableDocuments.filter(d => d.document_type_id === 6)
  const miscDocuments = tableDocuments.filter(d => d.document_type_id === 7)

  const uploadDocToS3 = async (postData, file) => {
    const formData = new FormData()
    Object.keys(postData.s3.fields).forEach(key => {
      formData.append(key, postData.s3.fields[key])
    })

    formData.append('file', file, file.name)

    try {
      const response = await fetch(postData.s3.url, {
        method: 'POST',
        body: formData
      })
        .then(res => {
          return res
        })
    } catch (error) {
      console.log('error: ', error)
    }
  }


  const uploadDocToAzureStorage = async (key, ext, documentName, file) => {

    const formData = new FormData();

    formData.append('file', file, file.name);

    formData.append('key', key);
    formData.append('ext', ext);
    formData.append('documentName', documentName);

    var response = await fetch(getUrlPath('UploadPropertyDocument'), {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${jwt}`
      }
    });


  }

  const submitDocument = useCallback(() => {
    fetch(getUrlPath(`prepareDocumentUpload/${id}`), {
      method: 'POST',
      body: JSON.stringify({ document: documentName }),
      headers: {
        'Authorization': `Bearer ${jwt}`
      }
    }).then(res => res.json()).then(async res => {
      return res
    }).then(async data => {
      if (isExtensionPreviewable(data.ext)) {
        //await uploadDocToS3(data, fileToUpload)

        await uploadDocToAzureStorage(data.s3.fields.key, data.ext, data.documentName, fileToUpload);

        const docShortName = documentName.substring(documentName.lastIndexOf('\\') + 1, documentName.length)

        const newDoc = {
          id: data.id,
          document_key: data.s3.fields.key,
          name: docShortName,
          property_id: id,
          document_type_id: documentType,
          extension: data.ext
        }

        await createDocument({
          variables: {
            document: newDoc
          }
        })

        updateTableDocuments([...tableDocuments, newDoc])
      }
    })

    resetModal()
  })

  const downloadDocument = useCallback(
    (property_id, document_id, document_key, extension) => {
      fetch(getUrlPath(`property/${property_id}/${document_id}`), {
        method: 'POST',
        body: JSON.stringify({
          Key: document_key,
          Extension: extension
        }),
        headers: {
          'Authorization': `Bearer ${jwt}`
        }
      }).then(res => {
        return res.json()
      }).then(d => {
        download(d.url, d.fileName)
      })
    })

  const previewDocument = useCallback(
    (property_id, document_id, document_key, extension) => {
      fetch(getUrlPath(`property/${property_id}/${document_id}`), {
        method: 'POST',
        body: JSON.stringify({
          Key: document_key,
          Extension: extension
        }),
        headers: {
          'Authorization': `Bearer ${jwt}`
        }
      }).then(res => {
        return res.json()
      }).then(async d => {
        const url = extension === '.pdf' ? d.url : encodeURIComponent(d.url)
        await updatePreviewUrl(url)
        await updateExtensionType(extension)

        frameToggle(true)
      })
    })

  const updateDependent = async (val, name, callback) => {
    let c = null
    if (val) {
      c = {
        id: val.client_id,
        name: val.client_name,
        property_urls: clients ? clients.find(c => c.id === val.client_id).property_urls || [] : [],
        permit_urls: clients ? clients.find(c => c.id === val.client_id).permit_urls || [] : []
      }
    }
    await updateClient(c)

    callback(c)
  }

  usePreventWindowUnload(dirty)

  values.total_payment = formikAutoCalc("total_payment", values)
  values.footage_difference = formikAutoCalc("footage_difference", values)
  values.initial_payment_made = formikAutoCalc("initial_payment_made", values)
  values.payment_due_to_exercise_option_percent = formikAutoCalc("payment_due_to_exercise_option_percent", values)
  values.payment_due_to_exercise_option_dollar = formikAutoCalc("payment_due_to_exercise_option_dollar", values)
  values.payment_made_to_exercise_option = paymentMade
  values.total_payment = formikAutoCalc("total_payment_made", values)
  values.inked_id = inkedID

  return (
    <Form>
      <CardWithBreadcrumb items={[{ text: 'Properties', to: '/property' }, { text: values.name }]}>
        <CancelButton />
        <SaveButton />
      </CardWithBreadcrumb>
      <div style={containerStyle}>
        <Card style={cardStyle}>
          <h3 style={cardHeaderStyle}>Project Info</h3>

          <FormikSuggest
            name="client"
            label="Client"
            placeholder=""
            items={orderByName(clients)}
            value={client}
            disabled
          />

          <FormikSuggest
            name="project"
            label="Project"
            placeholder="Select a Project"
            items={orderByName(projects)}
            dependent="client"
            updateDependent={updateDependent}
            dependentValue={client}
          />

          <FormikInput
            name="name"
            label="Tract #"
            placeholder="Tract #"
          />

          <FormikSuggest
            name="landman"
            label="Landman"
            placeholder="Select a Landman"
            items={orderByName(landmen)}
          />

          <FormikInput
            name="inked-record"
            label="Inked Record #"
            value={inkedID}
            placeholder={inkedID}
            disabled
          />
        </Card>

        <Card style={cardStyle}>
          <h3 style={cardHeaderStyle}>Property Info</h3>

          <FormikSuggest
            name="grantor"
            label="Grantor"
            placeholder="Select a Grantor"
            items={orderByName(grantors)}
          />

          <FormikSimpleSelect
            nullable
            name="grantor_status"
            label="Grantor Status"
            items={orderByName(lookups.grantor_statuses)}
          />

          <FormikSimpleSelect
            nullable
            name="property_type"
            label="Property Type"
            items={orderByName(lookups.property_types)}
          />

          <FormikInput
            name="parcel_number"
            label="Parcel #"
            placeholder="Parcel #"
            type="text"
          />

          <FormikInput
            name="plss_qq"
            label="QQ"
            placeholder="QQ"
            type="text"
          />

          <FormikSuggest
            name="plss_section"
            label="Section"
            placeholder="Select a Section"
            items={orderByName(lookups.plss_section)}
          />

          <FormikSuggest
            name="plss_township"
            label="Township"
            placeholder="Select a Township"
            items={orderByName(lookups.plss_township)}
          />

          <FormikSuggest
            name="plss_range"
            label="Range"
            placeholder="Select a Range"
            items={orderByName(lookups.plss_range)}
          />

          <FormikSuggest
            name="state"
            label="State"
            placeholder="Select a State"
            items={orderByName(lookups.property_states)}
          />

          <FormikSuggest
            name="county"
            label="County"
            placeholder="Select a County"
            items={values.state ? orderByName(lookups.counties.filter(co => co.state_id === values.state.id)) : orderByName(lookups.counties)}
          />
        </Card>

        <Card style={cardStyle}>
          <h3 style={cardHeaderStyle}>Acquisition Status</h3>

          <FormikSimpleSelect
            nullable
            name="acquisition_status"
            label="Acquisition Status"
            items={orderByName(lookups.acquisition_statuses)}
          />

          <FormikInput
            name="survey_permission_date"
            label="Survey Permission Date"
            placeholder="Survey Permission Date"
            type="date"
            leftIcon="calendar"
          />

          <FormikInput
            name="plat_received_date"
            label="Plat Received Date"
            placeholder="Plat Received Date"
            type="date"
            leftIcon="calendar"
          />

          <FormikInput
            name="title_received_date"
            label="Title Received Date"
            placeholder="Title Received Date"
            type="date"
            leftIcon="calendar"
          />

          <FormikInput
            name="agreement_executed_date"
            label="Agreement Executed Date"
            placeholder="Agreement Executed Date"
            type="date"
            leftIcon="calendar"
          />

          <FormikSimpleSelect
            nullable
            name="agreement_type"
            label="Agreement Type"
            items={orderByName(lookups.agreement_types)}
          />
        </Card>

        <Card style={recordingCard}>
          <h3 style={cardHeaderStyle}>Recording Info</h3>
          <FieldArray
            name="recording_info"
            label="Recording Info"
            render={({ push, remove, onChange }) => (
              <div>
                <Table>
                  <thead>
                    <tr>
                      <th>Agreement Type</th>
                      <th>Recording Date</th>
                      <th>Reception #</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      values.recording_info && values.recording_info.map((r, index) => {
                        return (
                          <tr key={`recording-info-${index}`}>
                            <td>
                              <FormikSimpleSelect
                                nullable
                                key={`recording_info_${index}-agreement_type`}
                                name={`recording_info[${index}].agreement_type`}
                                items={lookups.agreement_types}
                                style={{ width: 150 }}
                              />
                            </td>
                            <td>
                              <FormikInput
                                name={`recording_info[${index}].recording_date`}
                                key={`recording_info_${index}-recording_date`}
                                placeholder="Recording Date"
                                type="date"
                                leftIcon="calendar"
                                style={{ width: 150 }}
                              />
                            </td>
                            <td>
                              <FormikInput
                                name={`recording_info[${index}].reception_number`}
                                key={`recording_info_${index}-reception_number`}
                                style={{ width: 150 }}
                              />
                            </td>
                            <td>
                              <div style={{ width: 20, margin: 0 }}>
                                <Icon icon='delete' onClick={() => remove(index)} />
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
                <Button
                  icon='add'
                  text='Add New'
                  type='button'
                  onClick={() => push({ agreement_type: null, recording_date: null, reception_number: '' })}
                />
              </div>
            )}
          />

        </Card>
        <Card style={surveyCard}>
          <h3 style={cardHeaderStyle}>Survey Info</h3>
          <FormikNumberMaskedInput
            name="preliminary_footage"
            label="Preliminary Footage"
            placeholder="Preliminary Footage"
            leftIcon="widget"
            type="decimal"
          />

          <FormikNumberMaskedInput
            name="final_plat_footage"
            label="Final Plat Footage"
            placeholder="Final Plat Footage"
            leftIcon="widget"
            type="decimal"
          />

          <FormikNumberMaskedInput
            name="footage_difference"
            label="Footage Difference"
            placeholder="Footage Difference"
            leftIcon="widget"
            type="number"
            isAuto
          />


          <FieldArray
            name="existing_encumberances"
            label="Existing Encumberances"
            render={({ push, remove, onChange }) => (
              <div>
                <Table>
                  <thead>
                    Existing Encumberances
                    <tr>
                      <th>Reception Number</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.existing_encumberances && values.existing_encumberances.map((c, index) => {
                      return (
                        <tr>
                          <td>
                            <FormikInput
                              key={`existing_encumberance_${index}-reception_number`}
                              name={`existing_encumberances[${index}].reception_number`}
                            />
                          </td>
                          <td>
                            <FormikSimpleSelect
                              nullable
                              key={`existing_encumberance_${index}-encumberance_type`}
                              name={`existing_encumberances[${index}].encumberance_type`}
                              items={lookups.encumberance_types}
                            />
                          </td>
                          <td>
                            <Icon icon='delete' onClick={() => remove(index)} />
                          </td>
                        </tr>
                      )
                    })

                    }
                  </tbody>
                </Table>
                <Button
                  icon='add'
                  text='Add New'
                  type='button'
                  onClick={() => push({ reception_number: '', encumberance_type: null })}
                />
              </div>
            )}
          />

          <FieldArray
            name="survey_stips"
            label="Survey Stips"
            render={({ push, remove, onChange }) => (
              <div style={{ marginTop: 10 }}>
                <Table>
                  <thead>
                    Survey Stips
                  </thead>
                  <tbody>
                    {values.survey_stips && values.survey_stips.map((c, index) => {
                      return (
                        <tr>
                          <td>
                            <FormikEditableText
                              key={`survey_stip-${index}-entry`}
                              name={`survey_stips[${index}].entry`}
                              placeholder="Add entry"
                            />
                          </td>
                          <td>
                            <Icon icon='delete' onClick={() => remove(index)} />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                <Button
                  style={{ marginLeft: '10px', marginTop: '10px' }}
                  icon='add'
                  text='Add New'
                  type='button'
                  onClick={() => push({ entry: '' })}
                />
              </div>
            )}
          />
        </Card>

        <Card style={cardStyle}>
          <h3 style={cardHeaderStyle}>Payment Info</h3>

          <FormikNumberMaskedInput
            name="price_per_foot"
            label="Price Per Foot"
            placeholder="Price Per Foot"
            leftIcon="dollar"
            type="decimal"
          />

          <FormikNumberMaskedInput
            name="initial_payment_percent"
            label="Initial Payment %"
            placeholder="Initial Payment %"
            leftIcon="percentage"
            type="decimal"
          />

          <FormikNumberMaskedInput
            name="initial_payment_made"
            label="Initial Payment Made $"
            placeholder="Initial Payment Made $"
            leftIcon="dollar"
            type="decimal"
          />

          <FormikInput
            name="initial_payment_check_number"
            label="Initial Payment Check #"
            placeholder="Initial Payment Check #"
            leftIcon="endorsed"
          />

          <FormikNumberMaskedInput
            name="payment_due_to_exercise_option_percent"
            label="Payment Due to Exercise Option %"
            placeholder="Payment due to Exercise Option %"
            leftIcon="percentage"
            type="number"
          />

          <FormikNumberMaskedInput
            name="payment_due_to_exercise_option_dollar"
            label="Payment Due to Exercise Option $"
            placeholder="Payment due to Exercise Option $"
            leftIcon="dollar"
            type="number"
          />

          <div style={{ marginTop: 20, marginBottom: 10 }}>
            <FormikCheckbox
              name="payment_made_to_exercise_option"
              label="Payment Made to Exercise Option"
              onChange={() => updatePaymentMade(!paymentMade)}
              value={paymentMade}
            />
          </div>

          <FormikInput
            name="exercise_option_check_number"
            label="Exercise Option Check #"
            placeholder="Exercise Option Check #"
            leftIcon="endorsed"
          />

          <FormikInput
            name="exercise_option_due_date"
            label="Exercise Option Due Date"
            placeholder="Exercise Option Due Date"
            type="date"
            leftIcon="calendar"
          />

          <FormikNumberMaskedInput
            name="misc_payment"
            label="Misc. Payment"
            placeholder="Misc. Payment"
            leftIcon="dollar"
            type="decimal"
          />

          <FormikInput
            name="misc_payment_check_number"
            label="Misc. Payment Check #"
            placeholder="Misc. Payment Check #"
            leftIcon="endorsed"
          />

          <FormikNumberMaskedInput
            name="total_payment"
            label="Total Payment"
            placeholder="Total Payment"
            leftIcon="dollar"
            type="number"
          />
        </Card>

        <Card style={cardStyle}>
          <h3 style={cardHeaderStyle}>Contract Terms</h3>
          <FormikSimpleSelect
            nullable
            name="term_assignable"
            label="Assignable"
            items={orderByName(lookups.term_assignable_types)}
          />

          <FormikSimpleSelect
            nullable
            name="term_length"
            label="Term (months)"
            items={orderByName(lookups.term_lengths)}
          />

          <FormikInput
            name="term_expiration_date"
            label="Expiration Date"
            placeholder="Expiration Date"
            type="date"
            leftIcon="calendar"
          />

          <FormikSimpleSelect
            nullable
            name="term_abandonment"
            label="Abandonment by Non Use"
            items={orderByName(lookups.term_abandonment_types)}
          />

          <FormikSimpleSelect
            nullable
            name="term_number_of_lines"
            label="Number of Lines"
            items={orderByName(lookups.term_number_of_lines)}
          />

          <FormikSimpleSelect
            nullable
            name="term_change_size"
            label="Change Size"
            items={orderByName(lookups.term_change_sizes)}
          />

          <FormikSimpleSelect
            nullable
            name="term_above_ground_appurtenance"
            label="Above-Ground Appurtenance"
            items={orderByName(lookups.term_above_ground_appurtenance)}
          />
          <FormikSimpleSelect
            nullable
            name="term_product_restriction"
            label="Product Restriction"
            items={orderByName(lookups.term_product_restriction)}
          />

          <FormikSimpleSelect
            nullable
            name="term_permanent_width"
            label="Perm. Width (feet)"
            items={orderByName(lookups.term_permanent_width)}
          />

          <FormikSimpleSelect
            nullable
            name="term_tws_length"
            label="TWS (feet)"
            items={orderByName(lookups.term_tws_length)}
          />

          <FormikSimpleSelect
            nullable
            name="term_tws_expiration"
            label="TWS Term Expiration?"
            items={orderByName(lookups.term_tws_expiration)}
          />

          <FormikTextArea
            name="term_contract_notes"
            label="Contract Notes"
            placeholder="Contract Notes"
          />
        </Card>

        <Card style={constructionCard}>
          <h3 style={cardHeaderStyle}>Construction Info</h3>

          <FormikInput
            name="station_entry"
            label="Station Entry"
            placeholder="Station Entry"
          />

          <FormikInput
            name="station_exit"
            label="Station Exit"
            placeholder="Station Exit"
          />

          <FieldArray
            name="construction_stips"
            label="Construction Stips"
            render={({ push, remove, onChange }) => (
              <div>
                <Table>
                  <thead>
                    Construction Stips
                  </thead>
                  <tbody>
                    {values.construction_stips && values.construction_stips.map((c, index) => {
                      return (
                        <tr>
                          <td>
                            <FormikEditableText
                              key={`construction_stip-${index}-entry`}
                              name={`construction_stips[${index}].entry`}
                              placeholder="Add entry"
                            />
                          </td>
                          <td>
                            <Icon icon='delete' onClick={() => remove(index)} />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                <Button
                  style={{ marginLeft: '10px', marginTop: '10px' }}
                  icon='add'
                  text='Add New'
                  type='button'
                  onClick={() => push({ entry: '' })}
                />
              </div>
            )}
          />
        </Card>

        <Card style={propertyCard}>
          <h3 style={cardHeaderStyle}>Property Use</h3>
          <FieldArray
            name="tenants"
            label="Tenants"
            render={({ push, remove, onChange }) => (<div>
              <Table>
                <thead>
                  <tr>
                    <th>Tenant Name</th>
                    <th>Contact Number</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {values.tenants && values.tenants.map((tenant, index) => {
                    return (
                      <tr>
                        <td>
                          <FormikEditableText
                            key={`tenant-${index}-name`}
                            name={`tenants[${index}].name`}
                            label="Tenant Name"
                            placeholder="Tenant Name"
                          />
                        </td>
                        <td>
                          <FormikEditableText
                            key={`tenant-${index}-phone`}
                            name={`tenants[${index}].phone`}
                            label="Contact Number"
                            placeholder="Contact Number"
                          />
                        </td>
                        <td>
                          <FormikEditableText
                            key={`tenant-${index}-email`}
                            name={`tenants[${index}].email`}
                            label="Email"
                            placeholder="Email"
                          />
                        </td>
                        <td>
                          <Icon icon='delete' onClick={() => remove(index)} />
                        </td>
                      </tr>
                    )
                  }
                  )}
                </tbody>
              </Table>
              <Button
                className='table-button'
                icon='add'
                text='Add New'
                type='button'
                onClick={() => push({ name: '', phone: '', email: '' })}
              />

            </div>)}
          />
        </Card>

        <Card style={contactCard}>
          <h3 style={cardHeaderStyle}>Contact Notes</h3>
          <FieldArray
            name="contact_history"
            render={({ push, remove }) => (<div>
              <Table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Staff</th>
                    <th>Contact Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {values.contact_history && values.contact_history.map((h, index) => {
                    return (
                      <tr>
                        <td>{new Date(h.date).toLocaleDateString()}</td>
                        <td>{h.user}</td>
                        <td>
                          <FormikTextArea style={{ width: 500, height: 200 }} fill={true}
                            name={`contact_history[${index}].notes`}
                            placeholder="Contact Notes"
                          />
                        </td>
                        <td>
                          <Icon icon='delete' onClick={() => remove(index)} />
                        </td>
                      </tr>
                    )
                  }
                  )}
                </tbody>
              </Table>
              <Button
                className='table-button'
                icon='add'
                text='Add New'
                type='button'
                onClick={() => push({ date: new Date().toISOString().slice(0,10), user: user, notes: '' })}
              />
            </div>)}
          />
        </Card>
        <Card style={documentCard}>
          <h3 style={cardHeaderStyle}>Documents</h3>
          <Button
            className='table-button'
            style={{ marginLeft: 0, marginBottom: 20 }}
            icon='add'
            text='Add New'
            type='button'
            disabled={!id}
            onClick={() => modalToggle(true)}
          />

          <Overlay
            canEscapeKey
            onClose={() => resetModal()}
            isOpen={modal}
            usePortal
          >
            <div style={modalCardStyle}>
              <Card>
                <h3>Add Document</h3>
                <FileInput
                  buttonText='Browse'
                  hasSelection
                  text={documentName || 'Choose file...'}
                  onInputChange={(e) => {
                    updateDocumentName(e.target.value)
                    updateFileToUpload(e.target.files ? e.target.files[0] : null)
                  }}
                />
                {fileToUpload &&
                  <div style={documentTypeStyle}>
                    <h4>Document Type <span style={requiredStyle}>required</span></h4>
                    <RadioGroup selectedValue={documentType} onChange={(e) => {
                      updateDocumentType(parseInt(e.target.value))
                    }} >
                      {lookups.property_document_types.map((t, i) => {
                        return <Radio key={`document-type-${i}`} label={t.name} value={i + 1} />
                      })}
                    </RadioGroup>
                  </div>
                }
                <div style={modalFooterStyle}>
                  <CancelButton
                    onClick={() => resetModal()}
                  />
                  <SaveButton
                    disabled={!documentType}
                    onClick={(fileToUpload && documentType) ? () => submitDocument(fileToUpload) : null}
                  />
                </div>
              </Card>
            </div>
          </Overlay>
          {agreementDocuments.length > 0 &&
            <DocumentTable
              id={"agreement-documents-table"}
              downloadDocument={downloadDocument}
              previewDocument={previewDocument}
              tableDocuments={agreementDocuments}
              colStyle={docColStyle}
              lookups={lookups}
              previewUrl={previewUrl}
              extensionType={extensionType}
              frame={frame}
              resetFrame={resetFrame}
            />
          }
          {titleDocuments.length > 0 &&
            <DocumentTable
              id={"title-documents-table"}
              downloadDocument={downloadDocument}
              previewDocument={previewDocument}
              tableDocuments={titleDocuments}
              colStyle={docColStyle}
              lookups={lookups}
              previewUrl={previewUrl}
              extensionType={extensionType}
              frame={frame}
              resetFrame={resetFrame}
            />
          }
          {surveyDocuments.length > 0 &&
            <DocumentTable
              id={"survey-documents-table"}
              downloadDocument={downloadDocument}
              previewDocument={previewDocument}
              tableDocuments={surveyDocuments}
              colStyle={docColStyle}
              lookups={lookups}
              previewUrl={previewUrl}
              extensionType={extensionType}
              frame={frame}
              resetFrame={resetFrame}
            />
          }
          {paymentDocuments.length > 0 &&
            <DocumentTable
              id={"payment-documents-table"}
              downloadDocument={downloadDocument}
              previewDocument={previewDocument}
              tableDocuments={paymentDocuments}
              colStyle={docColStyle}
              lookups={lookups}
              previewUrl={previewUrl}
              extensionType={extensionType}
              frame={frame}
              resetFrame={resetFrame}
            />
          }
          {contactDocuments.length > 0 &&
            <DocumentTable
              id={"contact-documents-table"}
              downloadDocument={downloadDocument}
              previewDocument={previewDocument}
              tableDocuments={contactDocuments}
              colStyle={docColStyle}
              lookups={lookups}
              previewUrl={previewUrl}
              extensionType={extensionType}
              frame={frame}
              resetFrame={resetFrame}
            />
          }
          {constructionDocuments.length > 0 &&
            <DocumentTable
              id={"construction-documents-table"}
              downloadDocument={downloadDocument}
              previewDocument={previewDocument}
              tableDocuments={constructionDocuments}
              colStyle={docColStyle}
              lookups={lookups}
              previewUrl={previewUrl}
              extensionType={extensionType}
              frame={frame}
              resetFrame={resetFrame}
            />
          }
          {miscDocuments.length > 0 &&
            <DocumentTable
              id={"misc-documents-table"}
              downloadDocument={downloadDocument}
              previewDocument={previewDocument}
              tableDocuments={miscDocuments}
              colStyle={docColStyle}
              lookups={lookups}
              previewUrl={previewUrl}
              extensionType={extensionType}
              frame={frame}
              resetFrame={resetFrame}
            />
          }
        </Card>
      </div>
    </Form>
  )
}