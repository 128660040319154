import React from 'react'
import { usePreventWindowUnload } from '../../hooks/preventWindowUnload'
import { FieldArray } from 'formik'
import { orderByName } from '../../utils/helpers'
import { Icon } from '@blueprintjs/core'

import { 
  ButtonLink,
  Card, 
  CardWithBreadcrumb, 
  Form,
  FormikInput, 
  CancelButton,
  SaveButton,
  FormikMultiSuggest,
  FormikSuggest,
  FormikTextArea,
  Button,
  Table,
  FormikNumberMaskedInput
} from '../../elements'

const containerStyle = {
  flex: 1,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'stretch'
}

const cardStyle = {
  width: '23vw',
  minWidth: 300
}

const urlCardStyle = {
  minWidth: 300
}

export default ({ isNew, values, clients, landmen, states, counties, property_states, products, dirty }) => {
  usePreventWindowUnload(dirty)
  
  return (
    <Form>
      <CardWithBreadcrumb items={[{ text: 'Projects', to: '/project' }, { text: values.name }]}>
        <ButtonLink
          disabled={isNew}
          icon="label"
          to={`/property?project=${values.id}`}
        >
          Properties
        </ButtonLink>
        <CancelButton />
        <SaveButton />
      </CardWithBreadcrumb>

      <div style={containerStyle}>
        <Card style={cardStyle}>
          <FormikInput
            name="name"
            label="Project Name"
            placeholder="Project Name"
            leftIcon="projects"
          />

          <FormikInput
            name="manager"
            label="Project Manager"
            placeholder="Project Manager"
            leftIcon="projects"
          />

          <FormikSuggest
            name="client"
            label="Client"
            placeholder="Client"
            leftIcon="office"
            items={orderByName(clients)}
          />

          <FormikMultiSuggest
            name="landmen"
            label="Landmen"
            placeholder="Landmen"
            leftIcon="office"
            items={orderByName(landmen)}
          />

        </Card>

        <Card style={cardStyle}>
          <FormikSuggest
            name="state"
            label="State"
            placeholder="State"
            leftIcon="map-marker"
            items={orderByName(property_states)}
          />

          <FormikSuggest
            name="county"
            label="County"
            placeholder="County"
            leftIcon="map-marker"
            items={values.state ? orderByName(counties.filter(co => co.state_id === values.state.id)) : orderByName(counties)}
            
          />

          <FormikMultiSuggest
            name="products"
            label="Products"
            placeholder="Products"
            leftIcon="path"
            items={orderByName(products)}
          />
        </Card>

        <Card style={cardStyle}>
          <FormikInput
            name="afe_po_number"
            label="AFE / PO Number"
            placeholder="AFE / PO Number"
            leftIcon="document"
          />

          <FormikInput
            name="number_of_lines"
            label="Number of Lines"
            placeholder="Number of Lines"
            leftIcon="numbered-list"
          />

          <FormikTextArea
            name="notes"
            label="Notes"
            placeholder="Notes"
          />
        </Card>

        <Card style={cardStyle}>
          <FormikInput
            name="target_release_to_build"
            label="Target Release to Build"
            placeholder="Target Release to Build"
            leftIcon="calendar"
            type="date"
          />

          <FormikInput
            name="target_in_service_date"
            label="Target in Service Date"
            placeholder="Target in Service Date"
            leftIcon="calendar"
            type="date"

          />

          <FormikNumberMaskedInput
            name="target_permanent_width"
            label="Target Permanent Width"
            placeholder="Target Permanent Width"
            leftIcon="locate"
            type='number'
          />

          <FormikNumberMaskedInput
            name="target_construction_width"
            label="Target Construction Width"
            placeholder="Target Construction Width"
            leftIcon="locate"
            type="number"
          />
        </Card>
        <Card style={urlCardStyle}>
          <FieldArray
            name="property_urls"
            label="GIS Property URLs"
            render={( {push, remove, onChange }) => (
              <div style={{marginTop: 10}}>
                <Table>
                  <thead>
                    GIS Parcel URLs
                  </thead>
                 <tbody>
                  {values.property_urls && values.property_urls.map((c, index) => {
                    return (
                      <tr>
                        <td>
                          <FormikInput 
                            key={`property_url-${index}-name`}
                            name={`property_urls[${index}].name`}
                            placeholder="Name"
                          />
                        </td>
                        <td>
                          <FormikInput
                            key={`property_url-${index}-url`}
                            name={`property_urls[${index}].url`}
                            placeholder="Property URL"
                            style={{ width: 300 }}
                        />
                        </td>
                        <td>
                            <Icon icon='delete' onClick={() => remove(index)} />
                          </td>
                      </tr>
                    )
                  })}
                 </tbody>
                </Table>
                <Button
                style={{marginLeft: '10px', marginTop: '10px' }}
                icon='add'
                text='Add New'
                type='button'
                onClick={() => push({ url: '', name: '' })}
              />
              </div>
            )}
          />
        </Card>
      </div>
    </Form>
  )
}