import React, { useState } from 'react'
import { Form } from 'formik'
import { 
    FormikInput, 
    Card, 
    CancelButton, 
    SaveButton, 
    FormikMultiSuggest,
    FormikSimpleSelect,
    FormikSuggest
} from '../../elements'

const buttonContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 25
}

const errorStyle = {
    color: 'red',
    fontSize: '12pt',
    fontWeight: '500'
}

const successStyle = {
    color: '#4BB543',
    fontSize: '12pt',
    fontWeight: '500'
}

export default ({ isEdit, clients, roles, values, projects, ...props }) => {
    const isLandman = values && values.role && (values.role.name === "Landman" || values.role.name === "Landman (Readonly)")
    const title = isEdit ? 'Update User' : 'Create User'
    values.isEdit = isEdit
    return(
        <Form>
            <Card style={{width: 500}}>            
                <h3>{title}</h3>
                <FormikInput
                    name='first_name'
                    label='First Name'
                    placeholder="First name"
                />
                <FormikInput
                    name='last_name'
                    label='Last Name'
                    placeholder="Last name"
                />

                <FormikInput
                    name='email'
                    label='Email'
                    placeholder='Email'
                    type='email'
                />
                {!isEdit &&
                <>
                    <FormikInput
                        type='password'
                        name='password'
                        label='Password'
                        placeholder="Password"
                    />
                    <FormikInput
                        type='password'
                        name='password_confirm'
                        label='Confirm password'
                        placeholder="Confirm password"
                    />
                </>
                }

                <FormikSimpleSelect
                    nullable
                    name='role'
                    label='Role'
                    items={roles}
                />

                <FormikMultiSuggest
                    items={clients}
                    name='clients'
                    label='Clients'
                />

                {isLandman &&
                    <FormikMultiSuggest
                        items={projects}
                        name='projects'
                        label='Project'
                    />    
                }

                {props.error &&
                    <p style={errorStyle}>An error has occurred</p>
                }
                {props.success &&
                    <p style={successStyle}>{`User ${isEdit ? 'updated' : 'created'} successfully`}</p>
                }
                <div style={buttonContainerStyle}>
                <CancelButton style={{ marginRight: 20 }} />
                <SaveButton style={{ marginLeft: 20 }} />
                </div>
            </Card>
        </Form>
    )
}