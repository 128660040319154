import React, { useState } from 'react'
import { Formik } from 'formik'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { PropertySchema } from '@inked/validation'
import { useAuth0 } from '../../hooks/auth0'
import PropertyForm from '../PropertyForm'
import { Spinner } from '../../elements'
import { navigate } from '@reach/router'
import { inputToArcGIS } from '../../utils/helpers'
import { updatePropertyFeature, deletePropertyFeature } from '../../utils/gis'
import { Alert, Icon } from '@blueprintjs/core';

const FRAGMENT = gql`
  fragment PropertyFragment on Property {
    id
    name
    inked_id
    client {
      id
      name
      property_urls {
        url
      }
      permit_urls {
        url
      }
    }
    project {
      id
      name
      client {
        id
        name
      }
      property_urls {
        url
      }
    }
    landman {
      id
      name
    }
    grantor {
      id
      name
    }
    grantor_status {
      id
    }
    property_type {
      id
    }
    parcel_number
    plss_qq
    plss_section {
      id
      name
    }
    plss_township {
      id
      name
    }
    plss_range { 
      id
      name
    }
    state {
      id
      name
      short_name
    }
    county {
      id
      name
    }
    acquisition_status {
      id,
      name
    }
    agreement_type {
      id
      name
    }
    term_assignable {
      id
      name
    }
    term_length {
      id
      name
    }
    term_abandonment {
      id
      name
    }
    term_number_of_lines {
      id
      name
    }
    term_change_size {
      id
      name
    }
    term_above_ground_appurtenance {
      id
      name
    }
    term_product_restriction {
      id
      name
    }
    term_permanent_width {
      id
      name
    }
    term_tws_length {
      id
      name
    }
    term_tws_expiration {
      id
      name
    }
    survey_permission_date
    plat_received_date
    title_received_date
    agreement_executed_date
    exercise_option_due_date
    term_expiration_date
    preliminary_footage
    price_per_foot
    final_plat_footage
    footage_difference
    initial_payment_percent
    initial_payment_made
    initial_payment_check_number
    payment_due_to_exercise_option_percent
    payment_due_to_exercise_option_dollar
    payment_made_to_exercise_option
    final_payment
    exercise_option_check_number
    misc_payment
    misc_payment_check_number
    total_payment
    term_contract_notes
    station_entry
    station_exit
    existing_encumberances {
      id
      encumberance_type {
        id
        name
      }
      reception_number
      property_id
      permit_id
    }
    recording_info {
      id
      agreement_type {
        id
        name
      }
      recording_date
      reception_number
      property_id
      permit_id
    }
    survey_stips {
      id
      entry
    }
    construction_stips {
      id
      entry
    }
    user
    tenants {
      id
      name
      phone
      email
    }
    contact_history {
      id
      date
      user
      notes
    }
    documents {
      id
      document_key
      name
      property_id
      document_type
      document_type_id
      extension
    }
  }
`

const PROPERTYQUERY = gql`
  ${FRAGMENT}
  query ($id: ID!) {
    property(id: $id) {
      ...PropertyFragment
    }

    projects {
      id
      name
      client_name
      client_id
      property_service_url
    }

    grantors {
      id
      name
    }

    landmen {
      id
      name
    }

    states {
      id
      name
    }

    property_states {
      id
      name
    }
    
    counties {
      id
      name
      state_id
    }

    grantor_statuses {
      id
      name
    }

    property_types {
      id
      name
    }

    acquisition_statuses {
      id
      name
    }

    agreement_types {
      id
      name
    }
    
    term_lengths {
      id
      name
    }
    
    term_change_sizes {
      id
      name
    }
    
    term_assignable_types {
      id
      name
    }
    
    term_abandonment_types {
      id
      name
    }
    
    term_product_restriction {
      id
      name
    }
    
    term_above_ground_appurtenance {
      id
      name
    }
    
    term_tws_expiration {
      id
      name
    }
    
    term_tws_length {
      id
      name
    }
    
    term_number_of_lines {
      id
      name
    }
    
    term_permanent_width {
      id
      name
    }

    plss_section {
      id
      name
    }

    plss_range {
      id
      name
    }

    plss_township {
      id
      name
    }

    property_document_types {
      id
      name
    }

    encumberance_types {
      id
      name
    }

  }
`

const MUTATION = gql`
  ${FRAGMENT}
  mutation UpdateProperty($id: ID!, $property: PropertyInput!) {
    updateProperty(id: $id, property: $property) {
      ...PropertyFragment
    }
  }
`


export default ({ propertyID }) => {
  const [updateProperty] = useMutation(MUTATION);
  const [gisSuccess, updateGisSuccess] = useState(true)
  const { profile } = useAuth0()
  let { loading, error, data } = useQuery(PROPERTYQUERY, {
    fetchPolicy: 'network-only',
    variables: {
      id: propertyID
    }
  })

  if (loading) {
    return <Spinner />
  }

  if (error) {
    console.error(error)
    return <span>error</span>
  }

  const onSubmit = async (props) => {

    let model = PropertySchema.cast(props, { stripUnknown: true })
    let serviceUrls = props && props.project ? props.project.property_urls : []
    let propertyUrls = props && props.client ? props.client.property_urls : []
    const property = inputToArcGIS(props)

    model.user = profile.nickname

    await updateProperty({
      variables: {
        id: propertyID,
        property: model
      }
    })

    if (serviceUrls.length === 0 || (!model.parcel_number || model.parcel_number === '') || propertyUrls.length === 0) {
      updateGisSuccess(false)
    }

    if (property.Parcel_ID && serviceUrls.length > 0 && propertyUrls.length > 0) {
      let result
      const url = propertyUrls[0].url

      for (var i = 0; i < serviceUrls.length; i++) {
        result = await updatePropertyFeature(property, serviceUrls[i].url, updateGisSuccess, url, props.client.name)
        if (result.short) {
          break
        }
      }

      updateGisSuccess(result.isSuccess)
    }
  }

  const onReset = () => window.history.go(-1)

  const navigateToEdit = () => {
    navigate(`/property/${propertyID}/edit`, { replace: true })
    updateGisSuccess(true)
  }

  const { property, grantors, landmen, projects, ...lookups } = data

  let client = null

  if (property.project) {
    const projectClient = property.project.client
    client = {
      id: projectClient.id,
      name: projectClient.name
    }
  }

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={data.property}
        onSubmit={onSubmit}
        onReset={onReset}
        render={props => (
          <PropertyForm
            {...props}
            grantors={grantors}
            landmen={landmen}
            projects={projects}
            lookups={lookups}
            user={profile.nickname}
            id={property.id}
            inkedID={property.inked_id}
            documents={property.documents}
            projectClient={client}

          />
        )}
        validationSchema={PropertySchema}
      />
      <Alert
        isOpen={!gisSuccess}
        onClose={() => navigateToEdit()}
        canOutsideClickCancel
      >
        {
          !gisSuccess &&
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Icon style={{ color: 'red' }} icon="cross" />
            <div>ArcGIS update unsuccessful</div>
          </div>
        }
      </Alert>
    </div>
  )

}