import React, { useState } from 'react'
import { gql } from 'apollo-boost'
import { get } from 'lodash/fp'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useAuth0 } from '../../hooks/auth0'
import PropertyForm from '../PropertyForm'
import { navigate } from '@reach/router'
import { Spinner } from '../../elements'
import { Formik } from 'formik';
import { Alert, Icon } from '@blueprintjs/core'
import { PropertySchema } from '@inked/validation'
import { inputToArcGIS } from '../../utils/helpers'
import { updatePropertyFeature } from '../../utils/gis'


const MUTATION = gql`
    mutation createProperty($property: PropertyInput!) {
        createProperty(property: $property) {
            id
        }
    }`

const QUERY = gql`
    query {
        clients {
            id
            name
            property_urls {
                url
            }
            permit_urls {
                url
            }
        }
        projects {
            id
            name
            client_id
            client_name
            property_urls {
                url
            }
        }

        landmen {
            id
            name
        }

        grantors {
            id
            name
        }

        grantor_statuses {
            id
            name
        }

        property_types {
            id
            name
        }

        states {
            id
            name
        }

        property_states {
            id
            name
        }

        counties {
            id
            name
            state_id
        }

        acquisition_statuses {
            id
            name
        }

        agreement_types {
            id
            name
        }

        encumberance_types {
            id
            name
        }

        term_assignable_types {
            id
            name
        }

        term_lengths {
            id
            name
        }

        term_abandonment_types {
            id
            name
        }

        term_number_of_lines {
            id
            name
        }

        term_change_sizes {
            id
            name
        }

        term_above_ground_appurtenance {
            id
            name
        }

        term_product_restriction {
            id
            name
        }

        term_permanent_width {
            id
            name
        }

        term_tws_length {
            id
            name
        }

        term_tws_expiration {
            id
            name
        }

        plss_section {
            id
            name
        }

        plss_range {
            id
            name
        }

        plss_township {
            id
            name
        }

        property_document_types {
            id
            name
        }

        newPropertyInkedID {
            id
        }
    }`

export default () => {
    const [state, updateState] = useState(null)
    const [gisSuccess, updateGisSuccess] = useState(true)
    const [propertyId, updatePropertyId] = useState(null)
    const [modalToggle, updateModalToggle] = useState(null)

    const [createProperty] = useMutation(MUTATION);
    let { loading, error, data } = useQuery(QUERY)

    const { profile } = useAuth0()

    if (loading) {
        return <Spinner />
    }

    if (error) {
        return <span>error</span>
    }

    const { clients, landmen, grantors, projects, newPropertyInkedID, ...lookups } = data

    const onSubmit = async props => {
        let model = PropertySchema.cast(props, { stripUnknown: true })
        model.user = profile.nickname
        let success = false

        const property = inputToArcGIS(props)

        let serviceUrls = props.project ? props.project.property_urls : []
        let propertyUrls = props.client ? props.client.property_urls : []

        const res = await createProperty({ variables: { property: model } })

        const id = get('data.createProperty.id')(res);

        updatePropertyId(id)

        if (serviceUrls.length === 0 || (!model.parcel_number || model.parcel_number === '') || propertyUrls.length === 0) {
            await updateGisSuccess(false)
        }

        if (property.Parcel_ID && serviceUrls.length > 0 && propertyUrls.length > 0) {
            let result;
            const url = propertyUrls[0].url

            for (var i = 0; i < serviceUrls.length; i++) {
                result = await updatePropertyFeature(property, serviceUrls[i].url, updateGisSuccess, url, props.client.name)
                if (result.short) {
                    break
                }
            }

            await updateGisSuccess(result.isSuccess)
            success = result.isSuccess
        }

        if (success) {
            navigate(`/property/${id}/edit`, { replace: true })
        }
    }

    const onReset = () => navigate('/property');

    const navigateToEdit = () => {
        navigate(`/property/${propertyId}/edit`, { replace: true })
        updateGisSuccess(true)
    }

    return (
        <Formik
            onSubmit={onSubmit}
            onReset={onReset}
            initialValues={{}}
            render={(props) => (
                <div>
                    <PropertyForm
                        isNew
                        landmen={landmen}
                        grantors={grantors}
                        projects={projects}
                        lookups={lookups}
                        clients={clients}
                        user={profile.nickname}
                        updateState={updateState}
                        validationSchema={PropertySchema}
                        inkedID={newPropertyInkedID.id}
                        {...props}
                    />
                    <Alert
                        isOpen={!gisSuccess}
                        onClose={() => navigateToEdit()}
                        canOutsideClickCancel
                    >
                        {
                            !gisSuccess &&
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Icon style={{ color: 'red' }} icon="cross" />
                                <div>ArcGIS update unsuccessful</div>
                            </div>
                        }
                    </Alert>
                </div>
            )}
        >
        </Formik>
    )
}

