import React from 'react'
import { get } from 'lodash/fp'
import { Formik } from 'formik'
import { navigate } from '@reach/router'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { LandmanSchema } from '@inked/validation'

import LandmanForm from '../LandmanForm'
import { Spinner } from '../../elements'

const QUERY = gql`
  query {
    states {
      id
      name
    }
  }
`

const MUTATION = gql`
  mutation createLandman($landman: LandmanInput!) {
    createLandman(landman: $landman) {
      id
    }
  }
`

export default () => {
  const [createLandman] = useMutation(MUTATION)
  const { loading, error, data } = useQuery(QUERY)

  if (loading) {
    return <Spinner />
  }

  if (error) {
    console.error(error)

    return <span>error</span>
  }

  const onSubmit = async (props) => {
    const landman = LandmanSchema.cast(props, { stripUnknown: true })

    const res = await createLandman({ variables: { landman }})

    const id = get('data.createLandman.id')(res)

    navigate(`/landman/${id}/edit`, { replace: true })
  }

  const onReset = () => navigate('/landman')

  return (
    <Formik
      initialValues={{}}
      onSubmit={onSubmit}
      onReset={onReset}
      render={props => <LandmanForm isNew {...props} states={data.states} />}
      validationSchema={LandmanSchema}
    />
  )
}