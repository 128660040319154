import { withAuth } from '../WithAuth'
import {
    CAN_VIEW_CLIENT,
    CAN_EDIT_CLIENT,
    CAN_VIEW_LANDMAN,
    CAN_EDIT_LANDMAN,
    CAN_VIEW_AND_EDIT_ALL,
    CAN_VIEW_GRANTOR,
    CAN_EDIT_GRANTOR,
    CAN_CREATE_USER,
    CAN_VIEW_PROJECT,
    CAN_EDIT_PROJECT,
    CAN_VIEW_PROPERTY,
    CAN_EDIT_PROPERTY,
    CAN_VIEW_PERMIT,
    CAN_EDIT_PERMIT,
    CAN_VIEW_REPORT

} from '../../utils/enums'

export const CanViewClient = withAuth(CAN_VIEW_CLIENT)
export const AccessViewClient = withAuth(CAN_VIEW_CLIENT, true)

export const CanEditClient = withAuth(CAN_EDIT_CLIENT)
export const AccessEditClient = withAuth(CAN_EDIT_CLIENT, true)

export const CanViewLandman = withAuth(CAN_VIEW_LANDMAN)
export const AccessViewLandman = withAuth(CAN_VIEW_LANDMAN, true)

export const CanEditLandman = withAuth(CAN_EDIT_LANDMAN)
export const AccessEditLandman = withAuth(CAN_EDIT_LANDMAN, true)

export const CanViewAndEditAll = withAuth(CAN_VIEW_AND_EDIT_ALL)

export const CanViewGrantor = withAuth(CAN_VIEW_GRANTOR)
export const AccessViewGrantor = withAuth(CAN_VIEW_GRANTOR, true)

export const CanEditGrantor = withAuth(CAN_EDIT_GRANTOR)
export const AccessEditGrantor = withAuth(CAN_EDIT_GRANTOR, true)

export const CanCreateUser = withAuth(CAN_CREATE_USER)
export const AccessCreateUser = withAuth(CAN_CREATE_USER, true)

export const CanViewProject = withAuth(CAN_VIEW_PROJECT)
export const AccessViewProject = withAuth(CAN_VIEW_PROJECT, true)

export const CanEditProject = withAuth(CAN_EDIT_PROJECT)
export const AccessEditProject = withAuth(CAN_EDIT_PROJECT, true)

export const CanViewProperty = withAuth(CAN_VIEW_PROPERTY)
export const AccessViewProperty = withAuth(CAN_VIEW_PROPERTY, true)

export const CanEditProperty = withAuth(CAN_EDIT_PROPERTY)
export const AccessEditProperty = withAuth(CAN_EDIT_PROPERTY, true)

export const CanViewPermit = withAuth(CAN_VIEW_PERMIT)
export const AccessViewPermit = withAuth(CAN_VIEW_PERMIT, true)

export const CanEditPermit = withAuth(CAN_EDIT_PERMIT)
export const AccessEditPermit = withAuth(CAN_EDIT_PERMIT, true)

export const CanViewReport = withAuth(CAN_VIEW_REPORT)
export const AccessViewReport = withAuth(CAN_VIEW_REPORT, true)