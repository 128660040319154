import _defineProperty from "C:\\Projects\\InkedLandManagement\\src\\client\\inked-react\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

var _Yup$object$shape;

import * as Yup from 'yup';
import LookupType from './LookupType';
import Tenant from './Tenant';
import ContactHistory from './ContactHistory';
import RecordingInfo from './RecordingInfo';
export var PropertySchema = Yup.object().shape((_Yup$object$shape = {
  name: Yup.string().label('Tract #').min(1).required(),
  client: LookupType.label('Client').default(null).nullable(),
  project: LookupType.label('Project').default(null).nullable(),
  landman: LookupType.label('Landman').default(null).nullable(),
  grantor: LookupType.label('Grantor').default(null).nullable(),
  grantor_status: LookupType.label('Grantor Status').default(null).nullable(),
  property_type: LookupType.label('Property Type').default(null).nullable(),
  parcel_number: Yup.string().label('Parcel #').default(null).nullable(),
  plss_qq: Yup.string().label('QQ').default(null).nullable(),
  plss_section: LookupType.label('Section').default(null).nullable(),
  plss_township: LookupType.label('Township').default(null).nullable(),
  plss_range: LookupType.label('Range').default(null).nullable(),
  state: LookupType.label('State').default(null).nullable(),
  county: LookupType.label('County').default(null).nullable(),
  survey_permission_date: Yup.string().label('Survey Permission Date').default(null).nullable(),
  plat_received_date: Yup.string().label('Plat Received Date').default(null).nullable(),
  title_received_date: Yup.string().label('Title Received Date').default(null).nullable(),
  agreement_executed_date: Yup.string().label('Agreement Executed Date').default(null).nullable(),
  acquisition_type: LookupType.label('Acquisition Type').default(null).nullable(),
  acquisition_status: LookupType.label('Acquisition Status').default(null).nullable(),
  agreement_type: LookupType.label('Agreement Type').default(null).nullable(),
  recording_info: RecordingInfo.label("Recording Info"),
  preliminary_footage: Yup.number().label('Preliminary Footage').default(null).nullable(),
  price_per_foot: Yup.number().label('Price per Foot').default(null).nullable(),
  final_plat_footage: Yup.number().label('Final Plat Footage').default(null).nullable(),
  footage_difference: Yup.number().label('Footage Difference').default(null).nullable(),
  initial_payment_percent: Yup.number().label('Initial Payment %').default(null).nullable(),
  initial_payment_made: Yup.number().label('Initial Payment Made $').default(null).nullable(),
  initial_payment_check_number: Yup.string().label('Initial Payment Check #').default(null).nullable(),
  payment_due_to_exercise_option_percent: Yup.number().label('Final Payment due to Exercise Option %').default(null).nullable(),
  payment_due_to_exercise_option_dollar: Yup.number().label('Final Payment due to Exercise Option $').default(null).nullable(),
  payment_made_to_exercise_option: Yup.boolean().label('Payment Made to Exercise Option').default(null).nullable(),
  exercise_option_due_date: Yup.string().label('Exercise Option Due Date').default(null).nullable(),
  final_payment: Yup.number().label('Final Payment').default(null).nullable(),
  exercise_option_check_number: Yup.string().label('Final Payment Check #').default(null).nullable(),
  misc_payment: Yup.number().label('Misc. Payment').default(null).nullable(),
  misc_payment_check_number: Yup.string().label("Misc. Payment Check Number").default(null).nullable(),
  total_payment: Yup.number().label('Total Payment').default(null).nullable(),
  term_assignable: LookupType.label('Assignable').default(null).nullable(),
  term_length: LookupType.label('Term Length').default(null).nullable(),
  term_permanent_width: LookupType.label('Perm. Width (feet)').default(null).nullable(),
  term_expiration_date: Yup.string().label('Expiration Date').default(null).nullable(),
  term_abandonment: LookupType.label('Abandonment due to Non-Use').default(null).nullable(),
  term_number_of_lines: LookupType.label('Number of Lines').default(null).nullable(),
  term_change_size: LookupType.label('Change Size').default(null).nullable(),
  term_above_ground_appurtenance: LookupType.label('Above-Ground Appurtenance').default(null).nullable(),
  term_product_restriction: LookupType.label('Product Restriction').default(null).nullable(),
  term_tws_length: LookupType.label('TWS Length (ft)').default(null).nullable(),
  term_tws_expiration: LookupType.label('TWS Expiration').default(null).nullable(),
  term_contract_notes: Yup.string().label('Contract Notes').default(null).nullable(),
  station_entry: Yup.string().label('Station Entry').default(null).nullable(),
  station_exit: Yup.string().label('Station Exit').default(null).nullable(),
  existing_encumberances: Yup.string().label('Existing Encumberances').default(null).nullable(),
  survey_stips: Yup.string().label('Survey Stips').default(null).nullable(),
  construction_stips: Yup.array().of(Yup.object().shape({
    id: Yup.mixed(),
    entry: Yup.string().label("Construction Stip"),
    property_id: Yup.mixed(),
    permit_id: Yup.mixed()
  }).label("Construction Stips"))
}, _defineProperty(_Yup$object$shape, "survey_stips", Yup.array().of(Yup.object().shape({
  id: Yup.mixed(),
  entry: Yup.string().label("Survey Stip"),
  property_id: Yup.mixed(),
  permit_id: Yup.mixed()
}).label("Survey Stips"))), _defineProperty(_Yup$object$shape, "existing_encumberances", Yup.array().of(Yup.object().shape({
  id: Yup.mixed(),
  reception_number: Yup.string().nullable(),
  encumberance_type: LookupType.nullable()
}).label('Existing Encumberances'))), _defineProperty(_Yup$object$shape, "tenants", Tenant.label('Tenants')), _defineProperty(_Yup$object$shape, "contact_history", ContactHistory.label('History')), _defineProperty(_Yup$object$shape, "user", Yup.string().nullable()), _Yup$object$shape));