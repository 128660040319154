import React, { useState } from 'react'
import { HTMLTable } from '@blueprintjs/core'
import * as lo from 'lodash'
import { Button } from '../../elements'
import { Overlay } from '@blueprintjs/core'

export const Table = ({ children, id, style }) => {

  return (
    <HTMLTable 
      id={id}
      interactive
      striped
      style={Object.assign({ width: '100%' }, style)}
    >
      {children}
    </HTMLTable>
  )
}

export const DocumentTable = ({ downloadDocument, previewDocument, tableDocuments, colStyle, lookups, previewUrl, extensionType, frame, resetFrame }) => {


  const [documentSortVal, updateDocumentSortVal] = useState("name")
  const [documentOrder, updateDocumentOrder] = useState("asc")

  const updateDocumentSortValWithOrder = (s) => {
    const defaultSortOrder = 'asc'
    const newSortOrder = 'desc'

    if(s === documentSortVal) {
      updateDocumentOrder(documentOrder === defaultSortOrder ? newSortOrder : defaultSortOrder)
    }
    updateDocumentSortVal(s)
  }

  const sortDocuments = (documents, attr, order) => {
    return lo.orderBy(documents, [d => d[attr]], [order])
  }

  const isExtensionPreviewable = (extension) => {
    let e
    switch(extension) {
      case '.pdf':
        e = true
        break
      case '.docx':
        e = true
        break
      case '.doc':
        e = true
        break
      default:
        e = false
    }
    return e
  }

  const documentPreviewStyle = {
    width: '60%',
    height: '80%',
    position: 'fixed',
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)'
  }

  const tableStyle = {
    marginBottom: '20px'
  }

  const actionHeader = {
    textAlign: 'right',
    paddingRight: 30
  }

return(
  <Table style={tableStyle}>
  <thead>
    <tr>
      <th onClick={() => updateDocumentSortValWithOrder("name")} >Name</th>
      <th onClick={() => updateDocumentSortValWithOrder("document_type")}>Type</th>
      <th style={actionHeader}>Action</th>
    </tr>
  </thead>
    <tbody>
          {
            sortDocuments(tableDocuments, documentSortVal, documentOrder).map((item, i) => {
              return(
                <tr key={`property-document-${i}`}>
                  <td style={colStyle}>{item.name}</td>
                  <td style={colStyle}>{lookups.property_document_types.find(p => p.id === item.document_type_id.toString()).name}</td>
                  <td style={{ textAlign: 'right' }}>
                    <Button disabled={!isExtensionPreviewable(item.extension)} onClick={() => previewDocument(item.property_id, item.id, item.document_key, item.extension)} minimal icon='search' />
                    <Button minimal icon='download' onClick={() => downloadDocument(item.property_id, item.id, item.document_key, item.extension)} />
                  </td>
                </tr>
              )
            })
          }    
    </tbody>
    <Overlay
        onEscapeKey
        isOpen={frame}
        hasBackdrop={false}
        canOutsideClickClose
        onClose={() => resetFrame()}
      > 
        <div style={documentPreviewStyle}>
          {extensionType === '.pdf' ?
            <iframe src={previewUrl} width='100%' height='100%' /> :
            <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${previewUrl}`} width='100%' height='100%' frameBorder={100} />
          }
        </div>
      </Overlay>
</Table>
)

}
