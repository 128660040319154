import React from 'react'
import { navigate } from '@reach/router'
import { Card as CoreCard, H5, Intent, Breadcrumb, Breadcrumbs } from '@blueprintjs/core'
import { CanEdit } from '../../components/WithAuth'

import { Button, ButtonLink } from '../Button'

export const Card = ({ children, style, ...props }) => {
  return (
    <CoreCard style={{ margin: 10, ...style }} {...props}>
      {children}
    </CoreCard>
  )
}

export const CardEntitySearch = ({ title, roleid, buttonText, buttonTo, children, exportClick, ...props }) => (
  <Card {...props}>
    <div>
      <H5 style={{ display: 'inline' }}>
        {title}
      </H5>

    {exportClick && 
        <Button
          minimal 
          icon="export"
          onClick={exportClick} 
        />
    }
    { CanEdit(title, roleid) &&
      <ButtonLink
        intent={Intent.PRIMARY} 
        text={buttonText} 
        to={buttonTo}
        icon="plus"
        style={{ float: 'right' }}
      />
    }
    
    </div>

    {children}
  </Card>
)

export const CardWithNewButton = ({ title, buttonText, buttonTo, children, ...props }) => (
  <Card {...props}>
    <div>
      <H5 style={{ display: 'inline' }}>
        {title}
      </H5>

      <ButtonLink
        intent={Intent.PRIMARY} 
        text={buttonText} 
        to={buttonTo}
        icon="plus"
        style={{ float: 'right' }}
      />
    </div>

    {children}
  </Card>
)

const renderBreadcrumb = ({ text, to }) => (
  <Breadcrumb 
    disabled={!to}
    onClick={() => navigate(to)}
  >
    {text}
  </Breadcrumb>
)

export const CardWithBreadcrumb = ({ children, items, ...props }) => (
  <Card {...props}>
    <div style={{ display: 'inline-block' }}>
      <Breadcrumbs 
        items={items}
        breadcrumbRenderer={renderBreadcrumb}
      />
    </div>

    <div style={{ float: 'right' }}>
      {children}
    </div>
  </Card>
)
