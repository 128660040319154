import React, { useCallback, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import * as lo from 'lodash'
import { navigate } from '@reach/router'
import { useAuth0 } from '../../hooks/auth0'
import download from 'downloadjs'
import { useDebounce } from '../../hooks/debounce'
import { getUrlPath } from '../../utils/helpers'
import { CanEdit } from '../WithAuth'

import { Card, CardEntitySearch, InputGroup, Spinner, Table, Button} from '../../elements'

const QUERY = gql`
  query($keyword: String) {
    landmen(keyword: $keyword) {
      id
      name
      state
      city
      zip_code
      phone_1
      phone_1_ext
      email_1
    }
  }
`

const headerStyle = { 
  position: 'sticky', 
  top: 0, 
  zIndex: 10, 
  backgroundColor: 'white',
  borderBottom: '1px gray solid',
  paddingTop: 40
}

const filterButtonStyle = {
  marginTop: '10px'
}

const sortByAttribute = (items, attr, order) => {
  return lo.orderBy(items, [(o) => o[attr]], [order])
}

const LandmenSearchResults = ({ items = [], ...props }) => {
  const onClick = (id) => () => navigate(`/landman/${id}/edit`)
  const updateSortVal = props.updateSortVal

  const canEdit = CanEdit()

  return (
    <Table>
      <thead>
        <tr style={{ position: 'relative'}}>
          <th style={headerStyle} onClick={() => updateSortVal('name')}>Name</th>      
          <th style={headerStyle} onClick={() => updateSortVal('state')}>State</th>      
          <th style={headerStyle} onClick={() => updateSortVal('city')}>City</th>      
          <th style={headerStyle} onClick={() => updateSortVal('zip_code')}>Zip</th>      
          <th style={headerStyle} onClick={() => updateSortVal('phone_1')}>Phone</th>      
          <th style={headerStyle} onClick={() => updateSortVal('email_1')}>Email</th>      
        </tr>
      </thead>

      <tbody>
        {items.map(({ id, ...item }) => (
          <tr key={id} onDoubleClick={canEdit ? onClick(id) : null}>
            <td>{item.name}</td>
            <td>{item.state}</td>
            <td>{item.city}</td>
            <td>{item.zip_code}</td>
            <td>{item.phone_1} {item.phone_1_ext ? `ext. ${item.phone_1_ext}` : null}</td>
             <td>{item.email_1}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default () => {

  const [keyword, updateKeyword] = useState('')
  const debouncedSearch = useDebounce(keyword, 500)

  const { loading, error, data } = useQuery(QUERY, { 
    fetchPolicy: 'network-only',
    variables: {
      keyword: debouncedSearch
    }
  })

  const [sortVal, updateSortVal] = useState('name')
  const [order, updateOrder] = useState('asc')

  const { jwt } = useAuth0()

  const exportLandmen = useCallback(() => {
    fetch(getUrlPath('landman/xlsx'), {
      headers: {
        'Authorization': `Bearer ${jwt}`
      }
    }).then(res => res.json()).then(d => download(d.url))
  })

  const updateSortValWithOrder = (s) => {
    const defaultSortOrder = 'asc'
    const newSortOrder = 'desc'

    if(s === sortVal) {
      updateOrder(order === defaultSortOrder ? newSortOrder : defaultSortOrder)
    }
    
    updateSortVal(s)
  }

  if (loading) {
    return <Spinner />
  }

  if (error) {
    console.error(error)

    return <span>error</span>
  }

  return (
    <>
      <CardEntitySearch 
        title="Landmen" 
        buttonText="New Landman" 
        buttonTo="/landman/new"
        exportClick={exportLandmen}
      >
        <InputGroup 
              placeholder='Keyword...'
              style={{ marginTop: 10 }}
              id={'landmen-search-keyword'}
              value={keyword}
              onChange={(evt) => updateKeyword(evt.target.value)}
              rightElement = {
                <div style={filterButtonStyle}>
                  <Button
                  minimal
                  icon='cross'
                  onClick={() => updateKeyword('')} 
                  />
                </div>
              }
          />
      </CardEntitySearch>

      <Card style={{ maxHeight: 800, paddingTop: 0, marginTop: 0, overflowY: 'scroll'}}>
      {(loading || !data) && <Spinner />}
      {!(loading || !data) && 
        <LandmenSearchResults updateSortVal={updateSortValWithOrder} items={sortByAttribute(data.landmen, sortVal, order)} />}
      </Card>
    </>
  )
}
