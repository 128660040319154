import React, { useState } from 'react'
import { Formik } from 'formik'
import UserForm from '../UserForm'
import { gql } from 'apollo-boost'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { UserSchema } from '@inked/validation'
import { navigate } from '@reach/router'
import { Spinner } from '../../elements'
import { get } from 'lodash/fp';


const QUERY = gql`
    query {
        clients {
            id
            name
        }
        roles {
            id
            name
        }
        projects {
            id
            name
        }
    }
`

const MUTATION = gql`
    mutation CreateUser($user: UserInput!) {
        createUser(user: $user) {
            id
        }
    }
`

export default () => {
    const { loading, error, data } = useQuery(QUERY)
    const [createUser] = useMutation(MUTATION)
    const [createError, updateCreateError] = useState(false)
    const [createSuccess, updateCreateSuccess] = useState(false)

    if(loading) {
        return <Spinner />
    }

    const { clients, roles, projects } = data

    const onSubmit = async (props) => {
        await updateCreateError(false)
        await updateCreateSuccess(false)

        const model = UserSchema.cast(props, { stripUnknown: true})

        const res = await createUser({ variables: { user: model }}).catch(e => updateCreateError(true))

        const id = get('data.createUser.id')(res);

        if(res) {
            updateCreateSuccess(true)
            navigate(`/user/${id}/edit`)
        }
    }

    const onReset = () =>  { 
        updateCreateError(false)
        updateCreateSuccess(false)
        navigate('/user/new') 
    }

    return(
        <Formik 
            enableReinitialize
            initialValues={{}}
            onSubmit={onSubmit}
            onReset={onReset}
            render={(props) => (
                <UserForm
                error={createError}
                success={createSuccess}
                clients={clients}
                roles={roles}
                projects={projects}
                {...props}
                />
            )}
            validationSchema={UserSchema}
        />
    )
}