import React from 'react'
import { Formik } from 'formik'
import { navigate } from '@reach/router'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { ProjectSchema } from '@inked/validation'

import ProjectForm from '../ProjectForm'
import { Spinner } from '../../elements'

const QUERY = gql`
  query($id: ID!) {
    project(id: $id) {
      id
      name
      manager
      afe_po_number
      number_of_lines
      target_release_to_build
      target_in_service_date
      target_permanent_width
      target_construction_width
      notes
      property_service_url
      permit_service_url
      property_urls {
        url
        name
      }
      permit_urls {
        url
        name
      }

      client {
        id
        name
      }

      landmen {
        id
        name
      }

      products {
        id
        name
      }

      state  {
        id
        name
      }
      
      county {
        id
        name
        state_id
      }
    }

    clients {
      id
      name
    }

    landmen {
      id
      name
    }

    property_states {
      id
      name
    }

    counties {
      id
      name
      state_id
    }

    products {
      id
      name
    }
  }
`

const FRAGMENT = gql`
  fragment ProjectFragment on Project {
    id
    name
    client {
      id
    }
    landmen {
      id
      name
    }
    state {
      id
      name
    }
    county {
      id
      name
    }
    products {
      id
      name
    }
    afe_po_number
    number_of_lines
    target_release_to_build
    target_in_service_date
    target_permanent_width
    target_construction_width
    notes
  }
`

const MUTATION = gql`
  ${FRAGMENT}
  mutation UpdateProject($id: ID!, $project: ProjectInput!) {
    updateProject(id: $id, project: $project) {
      ...ProjectFragment
    }
  }
`

export default ({ projectID }) => {
  const { loading, error, data } = useQuery(QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      id: projectID
    }
  })

  const [updateProject] = useMutation(MUTATION)

  if (loading) {
    return <Spinner />
  }

  if (error) {
    console.error(error)

    return <span>error</span>
  }

  const onSubmit = async (props) => {
    let model = ProjectSchema.cast(props, { stripUnknown: true })

    await updateProject({
      variables: {
        id: projectID,
        project: model
      }
    })
  }

  const onReset = () => navigate('/project')

  return (
    <Formik
      initialValues={data.project}
      onSubmit={onSubmit}
      onReset={onReset}
      render={props => (
        <ProjectForm
          {...props}
          {...data}
        />
      )}
      validationSchema={ProjectSchema}
    />
  )
}