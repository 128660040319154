import React, { useState } from 'react'
import { Form, FieldArray } from 'formik'
import { formikAutoCalc, orderByName } from '../../utils/helpers'
import { usePreventWindowUnload } from '../../hooks/preventWindowUnload'
import { Icon } from '@blueprintjs/core'


import { 
    Card, 
    CardWithBreadcrumb, 
    CancelButton, 
    SaveButton, 
    FormikSuggest, 
    FormikInput,
    FormikSimpleSelect,
    FormikTextArea,
    FormikEditableText,
    Table,
    FormikNumberMaskedInput,
    Button
} from '../../elements'

const containerStyle = {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'stretch'
}

const cardStyle = {
    minWidth: 300,
  }

const cardHeaderStyle = {
  marginTop: 0
}

const recordingCard = {
  minWidth: 650
}

const surveyCard = {
  minWidth: 500
}

const constructionCard = { 
  minWidth: 400
}

const contactCard = {
  minWidth: 900
}


const tractContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
}

const tractStyle = {
  width: '80px',
  marginRight: '10px'
}


export default ({ 
  values, 
  clients, 
  landmen, 
  projects,
  grantors, 
  id, 
  inkedID, 
  projectClient, 
  lookups, 
  user,
  dirty
}) => {

    const [client, updateClient] = useState(projectClient)
    const updateDependent = async (val, name, callback) => {

      let c = null
      if(val) {
       c = {
            id: val.client_id,
            name: val.client_name,
            property_urls: clients.find(c => c.id === val.client_id).property_urls || [],
            permit_urls: clients.find(c => c.id === val.client_id).permit_urls || []
        }
      }
      await updateClient(c)
      callback(c)
    }

    const name = values.tract_no && values.permit_type ? values.tract_no + '-' + values.permit_type.name : ''
    usePreventWindowUnload(dirty)
    
    values.total_payment = formikAutoCalc("permit_total_payment", values)
    values.inked_id = inkedID
    
    return(
      <Form>
          <CardWithBreadcrumb items={[{ text: 'Permits', to: '/permit' }, { text: name }]}>
              <CancelButton />
              <SaveButton />
          </CardWithBreadcrumb>
          <div style={containerStyle}>
              <Card style={cardStyle}>
                  <h3 style={cardHeaderStyle}>Project Info</h3>
                  <FormikSuggest
                      name="client"
                      label="Client"
                      placeholder=""
                      items={orderByName(clients)}
                      value={client}
                      disabled
                  />

                  <FormikSuggest
                      name="project"
                      label="Project"
                      placeholder="Select a Project"
                      items={orderByName(projects)}
                      dependent="client"
                      updateDependent={updateDependent}
                  />
                <div style={tractContainerStyle}>
                  <div style={tractStyle}>
                      <FormikInput
                          name="tract_no"
                          label="Tract #"
                          placeholder="Tract #"
                      />
                    </div>
                      <FormikSimpleSelect
                        nullable
                        name="permit_type"
                        label="Permit Type"
                        items={orderByName(lookups.permit_type)}
                        placeholder="Select..."
                      />
                </div>

                  <FormikSuggest
                      name="landman"
                      label="Landman"
                      placeholder="Select a Landman"
                      items={orderByName(landmen)}
                  />

                  <FormikInput
                      name="inked_id"
                      label="Inked Record #"
                      value={inkedID}
                      placeholder={inkedID}
                      disabled
                  />
              </Card>
              <Card style={cardStyle}>
                  <h3 style={cardHeaderStyle}>Permit Info</h3>
                  <FormikSuggest
                    name="grantor"
                    label="Grantor"
                    placeholder="Select a Grantor"
                    items={orderByName(grantors)}
                  />

                  <FormikInput
                    name="permit_number"
                    label="Permit #"
                    placeholder="Permit #"
                  />

                  <FormikInput
                    name="plss_qq"
                    label="QQ"
                    placeholder="QQ"
                    type="text"
                  />

                  <FormikSuggest
                    name="plss_section"
                    label="Section"
                    placeholder="Select a Section"
                    items={orderByName(lookups.plss_section)}
                  />

                  <FormikSuggest
                    name="plss_township"
                    label="Township"
                    placeholder="Select a Township"
                    items={orderByName(lookups.plss_township)}
                  />

                  <FormikSuggest
                    name="plss_range"
                    label="Range"
                    placeholder="Select a Range"
                    items={orderByName(lookups.plss_range)}
                  />

                  <FormikSuggest
                    name="state"
                    label="State"
                    placeholder="Select a State"
                    items={lookups.property_states}
                  />

                  <FormikSuggest
                    name="county"
                    label="County"
                    placeholder="Select a County"
                    items={values.state ? orderByName(lookups.counties.filter(co => co.state_id === values.state.id)) : orderByName(lookups.counties)}
                  />
              </Card>
              <Card style={cardStyle}>
                  <h3 style={cardHeaderStyle}>Acquisition Status</h3>
                  <FormikSimpleSelect
                    nullable
                    name="acquisition_status"
                    label="Acquisition Status"
                    placeholder="Acquisition Status"
                    items={orderByName(lookups.acquisition_statuses)}

                />
                <FormikInput
                  name="survey_complete_date"
                  label="Survey Complete"
                  placeholder="Survey Complete"
                  type="date"
                  leftIcon="calendar"
                />
                <FormikInput
                  name="plan_profile_received_date"
                  label="Plan and Profile Received"
                  placeholder="Plan and Profile Received"
                  type="date"
                  leftIcon="calendar"
                />
                <FormikInput
                  name="submitted_date"
                  label="Permit Submitted"
                  placeholder="Permit Submitted"
                  type="date"
                  leftIcon="calendar"
                />
                <FormikInput
                  name="executed_date"
                  label="Permit Executed"
                  placeholder="Permit Executed"
                  type="date"
                  leftIcon="calendar"
                />
                <FormikSimpleSelect
                  nullable
                  name="agreement_type"
                  label="Agreement Type"
                  placeholder="Agreement Type"
                  items={orderByName(lookups.agreement_types)}
                />
            </Card>
            <Card style={recordingCard}>
                <h3 style={cardHeaderStyle}>Recording Info</h3>
                <FieldArray
                name="recording_info"
                label="Recording Info"
                render={({ push, remove, onChange }) => (
                  <div>
                    <Table>
                      <thead>
                        <tr>
                          <th>Agreement Type</th>
                          <th>Recording Date</th>
                          <th>Reception #</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        values.recording_info && values.recording_info.map((r, index) => {
                          return (
                            <tr>
                              <td>
                                <FormikSimpleSelect
                                  nullable
                                  key={`recording_info_${index}-agreement_type`}
                                  name={`recording_info[${index}].agreement_type`}
                                  items={lookups.agreement_types}
                                  style={{ width: 150 }}

                                />
                              </td>
                              <td>
                                <FormikInput
                                  name={`recording_info[${index}].recording_date`}
                                  key={`recording_info_${index}-recording_date`}
                                  placeholder="Recording Date"
                                  type="date"
                                  leftIcon="calendar"
                                  style={{ width: 150 }}

                                />
                              </td>
                              <td>
                              <FormikInput
                                name={`recording_info[${index}].reception_number`}
                                key={`recording_info_${index}-reception_number`}
                                style={{ width: 150 }}

                                />
                              </td>
                            <td>
                            <div style={{ width: 20, margin: 0 }}>
                              <Icon icon='delete' onClick={() => remove(index)} />
                            </div>
                            </td>
                            </tr>
                          )
                        })
                      }
                      </tbody>
                    </Table>
                    <Button
                      icon='add'
                      text='Add New'
                      type='button'
                      onClick={() => push({ agreement_type: null, recording_date: null, reception_number: '' })}
                    />
                  </div>
            )}
          />
            </Card>
            <Card style={surveyCard}>
                <h3 style={cardHeaderStyle}>Survey Info</h3>
                <FormikNumberMaskedInput
                  name="footage"
                  label="Footage"
                  placeholder="Footage"
                  leftIcon="widget"
                  type='decimal'
                />
                <FormikNumberMaskedInput
                  name="depth"
                  label="Depth"
                  placeholder="Depth"
                  leftIcon="widget"
                  type='decimal'
                />

          <FieldArray
              name="existing_encumberances"
              label="Existing Encumberances"
              render={({ push, remove, onChange }) => (
                <div>
                  <Table>
                    <thead>
                      Existing Encumberances
                      <tr>
                        <th>Reception Number</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                      <tbody>
                        {values.existing_encumberances && values.existing_encumberances.map(( c, index ) => {
                          return (
                            <tr>
                              <td>
                                <FormikInput
                                  key={`existing_encumberance_${index}-reception_number`}
                                  name={`existing_encumberances[${index}].reception_number`}
                                />
                              </td>
                              <td>
                                <FormikSimpleSelect
                                  nullable
                                  key={`existing_encumberance_${index}-encumberance_type`}
                                  name={`existing_encumberances[${index}].encumberance_type`}
                                  items={lookups.encumberance_types}
                                  />
                              </td>
                              <td>
                                <Icon icon='delete' onClick={() => remove(index)} />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                    <Button
                      icon='add'
                      text='Add New'
                      type='button'
                      onClick={() => push({ reception_number: '', encumberance_type: null })}
                  />
                  </div>
                  )}   
                  />

                  <FieldArray
                    name="survey_stips"
                    label="Survey Stips"
                    render={( {push, remove, onChange }) => (
                      <div style={{marginTop: 10}}>
                        <Table>
                          <thead>
                            Survey Stips
                          </thead>
                        <tbody>
                          {values.survey_stips && values.survey_stips.map((c, index) => {
                            return (
                              <tr>
                                <td>
                                  <FormikEditableText
                                    key={`survey_stip-${index}-entry`}
                                    name={`survey_stips[${index}].entry`}
                                    placeholder="Add entry"
                                />
                                </td>
                                <td>
                                <Icon icon='delete' onClick={() => remove(index)} />
                              </td>
                              </tr>
                            )
                          })}
                        </tbody>
                        </Table>
                        <Button
                        style={{marginLeft: '10px', marginTop: '10px' }}
                        icon='add'
                        text='Add New'
                        type='button'
                        onClick={() => push({ entry: '' })}
                      />
                      </div>
                    )}
                  />
              </Card>

            <Card style={cardStyle}>
                <h3 style={cardHeaderStyle}>Payment Info</h3>
                <FormikNumberMaskedInput
                  name="application_fee"
                  label="Application Fee"
                  placeholder="Application Fee $"
                  leftIcon="dollar"
                  type="decimal"
                 />
                <FormikInput
                  name="application_check_number"
                  label="Application Check #"
                  placeholder="Application Check #"
                  leftIcon="endorsed"
                 />
                <FormikNumberMaskedInput
                  name="crossing_fee"
                  label="Crossing Fee"
                  placeholder="Crossing Fee $"
                  leftIcon="dollar"
                  type="decimal"
                />
                <FormikInput
                  name="crossing_fee_check_number"
                  label="Crossing Fee Check #"
                  placeholder="Crossing Fee Check #"
                  leftIcon="endorsed"
                />
                <FormikNumberMaskedInput
                  name="bond_requirement"
                  label="Bond Requirement"
                  placeholder="Bond Requirement $"
                  leftIcon="dollar"
                  type="decimal"
                />
                <FormikInput
                  name="bond_check_number"
                  label="Bond Check #"
                  placeholder="Bond Check #"
                  leftIcon="endorsed"
                 />
                <FormikNumberMaskedInput
                  name="total_payment"
                  label="Total Payment $"
                  placeholder="Total Payment $"
                  leftIcon="dollar"
                  type="decimal"
                />

              </Card>

              <Card style={cardStyle}>
                  <h3 style={cardHeaderStyle}>Contract Terms</h3>
                  <FormikSimpleSelect
                      nullable
                      name="term_assignable"
                      label="Assignable"
                      items={lookups.term_assignable_types}
                  />

                  <FormikSimpleSelect
                    nullable
                    name="term_length"
                    label="Term (months)"
                    items={lookups.term_lengths}
                  />

                  <FormikInput
                    name="term_expiration_date"
                    label="Expiration Date"
                    placeholder="Expiration Date"
                    type="date"
                    leftIcon="calendar"
                  />

                  <FormikSimpleSelect
                    nullable
                    name="term_abandonment"
                    label="Abandonment by Non Use"
                    items={lookups.term_abandonment_types}
                  />

                  <FormikSimpleSelect
                    nullable
                    name="term_number_of_lines"
                    label="Number of Lines"
                    items={lookups.term_number_of_lines}
                  />

                  <FormikSimpleSelect
                    nullable
                    name="term_change_size"
                    label="Change Size"
                    items={lookups.term_change_sizes}
                  />

                  <FormikSimpleSelect
                    nullable
                    name="term_above_ground_appurtenance"
                    label="Above-Ground Appurtenance"
                    items={lookups.term_above_ground_appurtenance}
                  />
                  <FormikSimpleSelect
                    nullable
                    name="term_product_restriction"
                    label="Product Restriction"
                    items={lookups.term_product_restriction}
                  />

                  <FormikSimpleSelect
                    nullable
                    name="term_permanent_width"
                    label="Perm. Width (feet)"
                    items={lookups.term_permanent_width}
                  />

                  <FormikSimpleSelect
                    nullable
                    name="term_tws_length"
                    label="TWS (feet)"
                    items={lookups.term_tws_length}
                  />

                  <FormikSimpleSelect
                    nullable
                    name="term_tws_expiration"
                    label="TWS Term Expiration?"
                    items={lookups.term_tws_expiration}
                  />

                  <FormikTextArea
                    name="term_contract_notes"
                    label="Contract Notes"
                    placeholder="Contract Notes"
                  />
              </Card>

              <Card style={constructionCard}>
                  <h3 style={cardHeaderStyle}>Construction Info</h3>
                  <FormikInput
                      name="station_entry"
                      label="Station Entry"
                      placeholder="Station Entry"
                  />

                  <FormikInput
                      name="station_exit"
                      label="Station Exit"
                      placeholder="Station Exit"
                  />

              <FieldArray
                  name="construction_stips"
                  label="Construction Stips"
                  render={( {push, remove, onChange }) => (
                    <div>
                      <Table>
                        <thead>
                        Construction Stips
                        </thead>
                      <tbody>
                        {values.construction_stips && values.construction_stips.map((c, index) => {
                          return (
                            <tr>
                              <td>
                                <FormikEditableText
                                  key={`construction_stip-${index}-entry`}
                                  name={`construction_stips[${index}].entry`}
                                  placeholder="Add entry"
                              />
                              </td>
                              <td>
                                <Icon icon='delete' onClick={() => remove(index)} />
                            </td>
                            </tr>
                          )
                        })}
                      </tbody>
                      </Table>
                      <Button
                      style={{marginLeft: '10px', marginTop: '10px' }}
                      icon='add'
                      text='Add New'
                      type='button'
                      onClick={() => push({ entry: '' })}
                    />
                    </div>
                  )}
                />
            </Card>

              <Card style={contactCard}>
                  <h3 style={cardHeaderStyle}>Contact Notes</h3>
                  <FieldArray
                  name="contact_history"
                  render={({ push, remove }) => (<div>
                    <Table>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Staff</th>
                          <th>Contact Notes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.contact_history && values.contact_history.map((h, index) =>
                          {
                          return(
                            <tr>
                              <td>{new Date(h.date).toLocaleDateString()}</td>
                              <td>{h.user}</td>
                              <td>
                                <FormikTextArea style={{width: 500, height: 200}} fill={true}
                                  name={`contact_history[${index}].notes`}
                                  placeholder="Contact History"
                                />
                              </td>
                              <td>
                                  <Icon icon='delete' onClick={() => remove(index)} />
                                </td>
                            </tr>
                          )}
                        )}
                      </tbody>
                    </Table>
                    <Button
                      className='table-button'
                      icon='add'
                      text='Add New'
                      type='button'
                      onClick={() => push({ date: new Date(), user: user, notes: ''})}
                    />
                  </div>)}
                />
              </Card>

          </div>
      </Form>
    )
}