
import { orderBy } from 'natural-orderby'

export const isLocal = window.location.hostname === 'localhost'

const REACT_APP_API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;

export const getUrlPath = (route) => isLocal ? `/v1/${route}` : `${REACT_APP_API_BASE_PATH}/v1/${route}`

export const formikAutoCalc = (name, values) => {
    let value = null

    const pf = values.preliminary_footage
    const fpf = values.final_plat_footage
    const ppf = values.price_per_foot
    const ipp = values.initial_payment_percent
    const fp = values.final_payment
    const mp = values.misc_payment
    const ipm = values.initial_payment_made
    var af = parseFloat(values.application_fee);
    var cf = parseFloat(values.crossing_fee);
    var br = parseFloat(values.bond_requirement);
    const pmd = values.payment_due_to_exercise_option_dollar
    const pm = values.payment_made_to_exercise_option

    const fpfHasVal = fpf && !isNaN(fpf)
    const pfHasVal = pf && !isNaN(pf)
    const ppfHasVal = ppf && !isNaN(ppf)
    const ippHasVal = ipp && !isNaN(ipp)
    const ipmHasVal = ipm && !isNaN(ipm)
    const afHasVal = af && !isNaN(af)
    const cfHasVal = cf && !isNaN(cf)
    const brHasVal = br && !isNaN(br)

    switch (name) {
        case "footage_difference":
            if (fpfHasVal || pfHasVal) {
                value = (fpf || 0) - (pf || 0)
            }
            break
        case "initial_payment_made":
            if (pfHasVal && ppfHasVal && ippHasVal) {
                value = (pf * ppf * (ipp / 100)).toFixed(2)
            }
            break
        case "total_payment_made":
            if (pm) {
                value = ((ipm || 0) + (pmd || 0) + (mp || 0)).toFixed(2)
            } else {
                value = parseFloat((ipm || 0) + (mp || 0)).toFixed(2)
            }
            break
        case "permit_total_payment":
            if (afHasVal || cfHasVal || brHasVal) {
                if (!af) {
                    af = 0;
                }

                if (!cf) {
                    cf = 0;
                }

                if (!br) {
                    br = 0;
                }

                value = parseFloat((af + cf + br)).toFixed(2)
            }
            break
        case "payment_due_to_exercise_option_percent":
            if (ippHasVal) {
                value = (100 - ipp).toFixed(2)
            }
            break
        case "payment_due_to_exercise_option_dollar":
            if (fpfHasVal && ppfHasVal && ipmHasVal) {
                value = ((fpf * ppf) - ipm).toFixed(2)
            }
            break
        default:
            return value
    }

    return value
}

const calculateFootageDifference = (item) => {
    let footageDifference = item.footage_difference

    if (item.preliminary_footage || item.final_plat_footage && !item.footage_difference) {
        footageDifference = item.final_plat_footage - item.preliminary_footage
    }

    return footageDifference
}

const calculateTotalPayment = (item) => {
    let totalPayment = null
    const shouldCalculateProperty = item.initial_payment_made || item.payment_due_to_exercise_option_dollar || item.misc_payment
    const shouldCalculatePermit = item.application_fee || item.crossing_fee || item.bond_requirement

    if (shouldCalculateProperty) {
        totalPayment = item.payment_made_to_exercise_option ? (item.initial_payment_made || 0) + (item.payment_due_to_exercise_option_dollar || 0) + (item.misc_payment || 0) : (item.initial_payment_made || 0) + (item.misc_payment || 0)
    }

    if (shouldCalculatePermit) {
        totalPayment = (item.application_fee || 0) + (item.crossing_fee || 0) + (item.bond_requirement || 0)
    }


    return totalPayment
}


export const inputToArcGIS = (input) => {
    const inkedID = input.inked_id
    const tractNo = input.name
    const client = input.project && input.project.client ? input.project.client.name : null
    const project = input.project ? input.project.name : null
    const landman = input.landman ? input.landman.name : null

    const permitNumber = input.permit_number ? input.permit_number : null

    const grantor = input.grantor ? input.grantor.name : null
    const grantorStatus = input.grantor_status ? input.grantor_status.name : null

    const propertyType = input.property_type ? input.property_type.name : null

    const section = input.plss_section ? input.plss_section.name : null
    const township = input.plss_township ? input.plss_township.name : null
    const range = input.plss_range ? input.plss_range.name : null
    const state = input.state ? input.state.name : null
    const county = input.county ? input.county.name : null

    const acquisitionStatus = input.acquisition_status ? input.acquisition_status.name : null
    const agreementType = input.agreement_type ? input.agreement_type.name : null

    const surveyPermissionDate = (input.survey_permission_date || input.survey_complete_date) || null
    const platReceivedDate = (input.plat_received_date || input.plan_profile_received_date) || null
    const titleReceivedDate = (input.title_received_date || input.submitted_date) || null
    const agreementExecutedDate = (input.agreement_executed_date || input.executed_date) || null

    const finalPlatFootage = (input.final_plat_footage || input.footage) || null
    const initialPaymentMade = (input.initial_payment_made || input.application_fee) || null
    const initialPaymentCheckNumber = (input.initial_payment_check_number || input.application_check_number) || null
    const finalPayment = (input.final_payment || input.crossing_fee) || null
    const exerciseOptionCheckNUmber = (input.exercise_option_check_number || input.crossing_fee_check_number) || null
    const miscPayment = (input.misc_payment || input.bond_requirement) || null
    const miscPaymentCheckNumber = (input.misc_payment_check_number || input.bond_check_number) || null


    const termAssignable = input.term_assignable ? input.term_assignable.name : null
    const termLength = input.term_length ? input.term_length.name : null
    const termAbandonment = input.term_abandonment ? input.term_abandonment.name : null
    const termNumberOfLines = input.term_number_of_lines ? input.term_number_of_lines.name : null
    const termChangeSize = input.term_change_size ? input.term_change_size.name : null
    const termAboveGroundAppurtenance = input.term_above_ground_appurtenance ? input.term_above_ground_appurtenance.name : null
    const termProductRestriction = input.term_product_restriction ? input.term_product_restriction.name : null
    const termPermanentWidth = input.term_permanent_width ? input.term_permanent_width.name : null
    const termTwsLength = input.term_tws_length ? input.term_tws_length.name : null
    const termTwsExpiration = input.term_tws_expiration ? input.term_tws_expiration.name : null

    let constructionStips = null
    let surveyStips = null
    let existingEncumberances = null
    let recordingInfo = null

    if (input.construction_stips && input.construction_stips.length > 0) {
        constructionStips = input.construction_stips.map(c => `${c.entry || 'N-A'}`).join(', ')
    }

    if (input.survey_stips && input.survey_stips.length > 0) {
        surveyStips = input.survey_stips.map(s => `${s.entry || 'N-A'}`).join(', ')
    }

    if (input.existing_encumberances && input.existing_encumberances.length > 0) {
        existingEncumberances = input.existing_encumberances.map(e => `${e.reception_number || 'N-A'}-${e.name || 'N-A'}`).join(', ')
    }

    if (input.recording_info && input.recording_info.length > 0) {
        recordingInfo = input.recording_info.map(r => `${r.name || 'N-A'}/${r.recording_date || 'N-A'}/${r.reception_number || 'N-A'}`).join(', ')
    }

    return {
        "Inked_ID": inkedID,
        "Tract_ID": tractNo,
        "Parcel_ID": input.parcel_number,
        "Client": client,
        "Project": project,
        "Landman": landman,
        "Permit_Number": permitNumber,
        "Grantor": grantor,
        "Grantor_Status": grantorStatus,
        "Property_Type": propertyType,
        "QQ": input.plss_qq,
        "S": section,
        "T": township,
        "R": range,
        "State": state,
        "County": county,
        "Survey_Permission_Date": surveyPermissionDate,
        "Plat_Received_Date": platReceivedDate,
        "Title_Received_Date": titleReceivedDate,
        "Agreement_Executed_Date": agreementExecutedDate,
        "Acquisition_Status": acquisitionStatus,
        "Agreement_Type": agreementType,
        "Preliminary_Footage": input.preliminary_footage,
        "Price_Per_Foot": input.price_per_foot,
        "Final_Plat_Footage": finalPlatFootage,
        "Footage_Difference": calculateFootageDifference(input),
        "Recording_Info": recordingInfo,
        "Initial_Payment_Percent": input.initial_payment_percent,
        "Initial_Payment_Made": initialPaymentMade,
        "Initial_Payment_Check_Number": initialPaymentCheckNumber,
        "Payment_Due_To_Exercise_O": input.payment_due_to_exercise_option_percent,
        "Payment_Due_To_Exercise_1": input.payment_due_to_exercise_option_dollar,
        "Exercise Option Due Date": input.exercise_option_due_date,
        "Exercise_Option_Check_Number": exerciseOptionCheckNUmber,
        "Miscellaneous_Payment": miscPayment,
        "Miscellaneous_Payment_Check_Num": miscPaymentCheckNumber,
        "Final_Payment": finalPayment,
        "Total_Payment": calculateTotalPayment(input),
        "Term_Assignable": termAssignable,
        "Term_Length": termLength,
        "Term_Expiration_Date": input.term_expiration_date,
        "Term_Abandonment": termAbandonment,
        "Term_Number_Of_Lines": termNumberOfLines,
        "Term_Change_Size": termChangeSize,
        "Term_Above_Ground_Appurtenance": termAboveGroundAppurtenance,
        "Term_Product_Restriction": termProductRestriction,
        "Term_Permanent_Width": termPermanentWidth,
        "Term_TWS_Length": termTwsLength,
        "Term_TWS_Expiration": termTwsExpiration,
        "Term_Contract_Notes": input.term_contract_notes,
        "Station_Entry": input.station_entry,
        "Station_Exit": input.station_exit,
        "Survey_Stips": surveyStips,
        "Construction_Stips": constructionStips,
        "Existing Encumberances": existingEncumberances
    }
}

export const orderByName = (arr) => {
    return orderBy(arr, [v => v.name], ['asc'])
}

export const formatStrToThousands = (val) => {
    return val ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : val
}
