import React from 'react'
import { defaultProps, withProps } from 'recompose'
import { Link } from '@reach/router'

import { Button as CoreButton, Intent } from '@blueprintjs/core'

export const NavButton = (props) => {
  return (
    <CoreButton minimal {...props} />
  )
}

export const NavButtonLink = ({ to, ...props }) => {
  return (
    <Link to={to}>
      <NavButton {...props} />
    </Link>
  )
}

export const ButtonLink = ({ disabled, to, ...props }) => {
  if (disabled) {
    return <Button disabled {...props} />
  }

  return (
    <Link to={to}>
      <Button {...props} />
    </Link>
  )
}

export const Button = defaultProps({
  style: {
    marginLeft: 10
  }
})(CoreButton)

export const SaveButton = withProps({
  icon: 'saved',
  intent: Intent.PRIMARY,
  text: 'Save',
  type: 'submit'
})(Button)

export const CancelButton = withProps({
  icon: 'trash',
  intent: Intent.DANGER,
  text: 'Cancel',
  type: 'reset'
})(Button)