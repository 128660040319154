import React from 'react'
import { FieldArray } from 'formik'
import { TextArea } from '@blueprintjs/core'
import { orderBy } from 'natural-orderby'
import { usePreventWindowUnload } from '../../hooks/preventWindowUnload'


import { 
  ButtonLink,
  Card, 
  CardWithBreadcrumb, 
  Form,
  FormikInput, 
  CancelButton,
  SaveButton,
  FormikSuggest,
  FormikTextArea,
  FormikPhoneMaskedInput,
  Table,
  Button,
  FormikEditableText
} from '../../elements'

const containerStyle = {
  flex: 1,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'stretch'
}

const cardStyle = {
  width: '23vw',
  minWidth: 300
}

const cardHeaderStyle = {
  marginTop: 0
}

const extensionStyle = {
  width: 100,
  marginLeft: 10
}

const phoneContainerStyle = {
  display: 'flex'
}

export default ({ isNew, values, states, isEdit, dirty }) => {
  usePreventWindowUnload(dirty)
  
  return (
    <Form>
      <CardWithBreadcrumb items={[{ text: 'Grantors', to: '/grantor' }, { text: values.name }]}>
        <ButtonLink
          disabled={isNew}
          icon="label"
          to={`/property?grantor=${values.id}`}
        >
          Properties
        </ButtonLink>
        <CancelButton />
        <SaveButton />
      </CardWithBreadcrumb>

      <div style={containerStyle}>
        <Card style={cardStyle}>
          <FormikInput
            name="name"
            label="Grantor Name"
            placeholder="Grantor Name"
            leftIcon="projects"
          />
        </Card>

        <Card style={cardStyle}>
          <FormikInput
            name="details.address_1"
            label="Address"
            placeholder="Address"
            leftIcon="map-marker"
          />

          <FormikInput
            name="details.city"
            label="City"
            placeholder="City"
            leftIcon="map-marker"
          />

          <FormikInput
            name="details.zip_code"
            label="Zip Code"
            placeholder="Zip Code"
            leftIcon="map-marker"
          />

          <FormikSuggest
            name="details.state"
            label="State"
            placeholder="State"
            leftIcon="map-marker"
            items={orderBy(states)}
          />
        </Card>

        <Card style={cardStyle}>
          <div style={phoneContainerStyle}>
            <FormikPhoneMaskedInput
              name="details.phone_1"
              label="Phone (main)"
              placeholder="Phone (main)"
              leftIcon="phone"
              type="tel"
            />
            <div style={extensionStyle}>
              <FormikInput
                name={`details.phone_1_ext`}
                label="Ext."
                placeholder="Ext."
              />
            </div>
          </div>
        <div style={phoneContainerStyle}>
          <FormikPhoneMaskedInput
            name="details.phone_2"
            label="Phone (2)"
            placeholder="Phone (2)"
            leftIcon="phone"
            type="tel"
          />
          <div style={extensionStyle}>
            <FormikInput
              name={`details.phone_2_ext`}
              label="Ext."
              placeholder="Ext."
            />
          </div>
          </div>

          <FormikInput
            name="details.email_1"
            label="Email (main)"
            placeholder="Email (main)"
            leftIcon="envelope"
            type="email"
          />

          <FormikInput
            name="details.email_2"
            label="Email (2)"
            placeholder="Email (2)"
            leftIcon="envelope"
            type="email"
          />
        </Card>

        <Card style={cardStyle}>
          <FormikInput
            name="details.website"
            label="Web / Social Media"
            placeholder="Web / Social Media"
            leftIcon="link"
          />

          <FormikTextArea
            name="details.notes"
            label="Notes"
            placeholder="Notes"
          />
        </Card>
        { isEdit &&
        <Card>
        <h3 style={cardHeaderStyle}>Contact History</h3>
        <FieldArray
          name="contact_history"
          render={({ push }) => (<div>
            <Table>
              <thead>
                <tr>
                  <th>Inked Record No.</th>
                  <th>Client</th>
                  <th>Project</th>
                  <th>Date</th>
                  <th>Staff</th>
                  <th>Contact Notes</th>
                </tr>
              </thead>
              <tbody>
                {values.contact_history && values.contact_history.map((h, index) =>
                  {
                  return(
                    <tr>
                      <td>{h.inked_id}</td>
                      <td>{h.client}</td>
                      <td>{h.project}</td>
                      <td>{new Date(h.date).toLocaleDateString()}</td>
                      <td>{h.user}</td>
                      <td>
                        <TextArea style={{width: 500, height: 200}} fill={true} value={h.notes}>
                          {h.notes}
                        </TextArea>
                      </td>
                    </tr>
                  )}
                )}
              </tbody>
            </Table>
          </div>)}
        />
        </Card>
        }
      </div>
    </Form>
  )
}