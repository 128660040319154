import * as Yup from 'yup';
import EntityDetails from './EntityDetails';
export var PersonContactSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  name: Yup.string().label('Name'),
  title: Yup.string().label('Title'),
  phone_1: Yup.string().nullable().default(null).label('Phone (work)'),
  phone_1_ext: Yup.string().nullable().default(null).label('Ext.'),
  phone_2: Yup.string().nullable().default(null).label('Phone (cell)'),
  phone_2_ext: Yup.string().nullable().default(null).label('Ext.'),
  email: Yup.string().label('Email'),
  contact_index: Yup.number().nullable()
});
export var ClientSchema = Yup.object().shape({
  name: Yup.string().label('Client Name').min(1).required(),
  details: EntityDetails,
  contacts: Yup.array().of(PersonContactSchema),
  property_urls: Yup.array().of(Yup.object().shape({
    name: Yup.string().nullable().default(null).required('Name is required.'),
    url: Yup.string().nullable().default(null).required('Url is required.')
  })),
  permit_urls: Yup.array().of(Yup.object().shape({
    name: Yup.string().nullable().default(null).required('Name is required.'),
    url: Yup.string().nullable().default(null).required('Url is required')
  }))
});