import React from 'react'
import { Spinner as CoreSpinner, Intent } from '@blueprintjs/core'

const spinnerStyle = {
  marginTop: 120
}

export const Spinner = () => (
  <div style={spinnerStyle}>
    <CoreSpinner
      intent={Intent.PRIMARY}
      size={150}
    />
  </div>
)