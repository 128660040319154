import React from 'react'
import { Formik } from 'formik'
import { navigate } from '@reach/router'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { GrantorSchema } from '@inked/validation'

import GrantorForm from '../GrantorForm'
import { Spinner } from '../../elements'

const FRAGMENT = gql`
  fragment GrantorFragment on Grantor {
    id
    name
    contact_history {
      id
      user
      date
      notes
      property_id
      inked_id
      client
      project
    }
    details {
      address_1
      city
      state {
        id
        name
      }
      zip_code

      phone_1
      phone_1_ext
      phone_2
      phone_2_ext
      email_1
      email_2

      website
      notes
    }
  }
`

const QUERY = gql`
  ${FRAGMENT}
  query ($id: ID!) {
    grantor(id: $id) {
      ...GrantorFragment
    }

    states {
      id
      name
    }
  }
`

const MUTATION = gql`
  mutation UpdateGrantor($id: ID!, $grantor: GrantorInput!) {
    updateGrantor(id: $id, grantor: $grantor) {
      ...GrantorFragment
    }
  }
  ${FRAGMENT}
`

export default ({ grantorID }) => {
  const [updateGrantor] = useMutation(MUTATION)
  const { loading, error, data } = useQuery(QUERY, { 
    fetchPolicy: 'network-only',
    variables: {
      id: grantorID
    }
  })

  if (loading) {
    return <Spinner />
  }

  if (error) {
    console.error(error)

    return <span>error</span>
  }

  const onSubmit = async (props) => {
    const model = GrantorSchema.cast(props, { stripUnknown: true })

    await updateGrantor({ variables: {
      id: grantorID,
      grantor: model
    }})
  }

  const onReset = () => navigate('/grantor')

  return (
    <Formik
      initialValues={data.grantor}
      onSubmit={onSubmit}
      onReset={onReset}
      render={props => <GrantorForm isEdit {...props} states={data.states} />}
      validationSchema={GrantorSchema}
    />
  )
}