import React, { useState } from 'react'
import * as lo from 'lodash'
import { navigate } from '@reach/router'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

import { Card, CardEntitySearch, InputGroup, Spinner, Table, Button } from '../../elements'

const QUERY = gql`
    query {
        users {
            id
            firstName
            lastName
            email
            role
        }
    }
`


const sortByAttribute = (items, attr, order) => {
    return lo.orderBy(items, [(o) => o[attr]], [order])
  }


const UserSearchResults = ({ items = [], ...props}) => {
    const onClick = (id) =>  navigate(`/user/${id}/edit`)

    return(
        <Table>
            <thead>
                <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Role</th>
                </tr>
            </thead>
            <tbody>
                {
                    items.map(({ id, ...item}) => (
                        <tr onDoubleClick={() => onClick(id)} key={`user-${id}`}>
                            <td>{item.firstName}</td>
                            <td>{item.lastName}</td>
                            <td>{item.email}</td>
                            <td>{item.role}</td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>
    )
}

export default () => {

    const [sortVal, updateSortVal] = useState('name')
    const [order, updateOrder] = useState('asc')

    const { loading, error, data } = useQuery(QUERY, {
        fetchPolicy: 'network-only'
    })

    const updateSortValWithOrder = (s) => {
        const defaultSortOrder = 'asc'
        const newSortOrder = 'desc'
        
        if(s === sortVal) {
            updateOrder(order === defaultSortOrder ? newSortOrder : defaultSortOrder)
        } else {
            updateOrder(defaultSortOrder)
        }
        
        updateSortVal(s)
    }


    return(
        <>
            <CardEntitySearch
                title="Users"
                buttonText="New User"
                buttonTo="/user/new"
            />
            <Card style={{ padding: 0}}>
            {(loading || !data) && <Spinner />}
            {!(loading || !data) && <UserSearchResults updateSortVal={updateSortValWithOrder} items={sortByAttribute(data.users, sortVal, order)} />}
            </Card>
        </>
    )
}