import React from 'react'
import { NonIdealState } from '@blueprintjs/core'

export default ({ error, componentStack }) => (
  <NonIdealState
    icon="warning-sign"
    title="Error Occurred"
    description={error.toString()}
  >
    {componentStack}
  </NonIdealState>
)