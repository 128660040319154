import React from 'react'
import { Router } from '@reach/router'

import { Auth0Provider } from './hooks/auth0'
import AuthApp from './AuthApp'
import Auth0Callback from './components/Auth0Callback'

export default () => {
  return (
    <Auth0Provider>
      <Router>
        <Auth0Callback path="/auth" />
        <AuthApp default />
      </Router>
    </Auth0Provider>
  )
}
