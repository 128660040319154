import React, { useState } from 'react'
import { Formik } from 'formik'
import { gql } from 'apollo-boost'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Spinner } from '../../elements'
import PermitForm from '../PermitForm'
import { PermitSchema } from '@inked/validation'
import { get } from 'lodash/fp';
import { navigate } from '@reach/router'
import { useAuth0 } from '../../hooks/auth0'
import { Alert, Icon } from '@blueprintjs/core'
import { updatePermitFeature } from '../../utils/gis'
import { inputToArcGIS } from '../../utils/helpers'


const MUTATION = gql`
    mutation createPermit($permit: PermitInput!) {
        createPermit(permit: $permit) {
            id
        }
    }`

const QUERY = gql`
    query {
        clients {
            id
            name
            permit_urls {
                url
            }
        }
        projects {
            id
            name
            client_id
            client_name
            permit_urls {
                url
            }
        }
        landmen {
            id
            name
        }
        grantors {
            id
            name
        }
        permit_type {
            id
            name
        }
        plss_section {
            id
            name
        }
        plss_township {
            id
            name
        }
        plss_range {
            id
            name
        }

        encumberance_types {
            id
            name
        }


        states {
            id
            name
        }

        property_states {
            id
            name
        }

        counties {
            id
            name
            state_id
        }
        acquisition_statuses {
            id
            name
        }
        agreement_types {
            id
            name
        }

        term_assignable_types {
            id
            name
        }

        term_lengths {
            id
            name
        }

        term_abandonment_types {
            id
            name
        }

        term_number_of_lines {
            id
            name
        }

        term_change_sizes {
            id
            name
        }

        term_above_ground_appurtenance {
            id
            name
        }

        term_product_restriction {
            id 
            name
        }

        term_permanent_width {
            id
            name
        }

        term_tws_length {
            id
            name
        }

        term_tws_expiration {
            id
            name
        }

        newPermitInkedID {
            id
        }
    }
`

export default () => {
    const { loading, error, data } = useQuery(QUERY)
    const [createPermit] = useMutation(MUTATION)
    const { profile } = useAuth0()
    const [gisSuccess, updateGisSuccess] = useState(true)
    const [permitId, updatePermitId] = useState(null)
    const [modalToggle, updateModalToggle] = useState(null)

    if (loading) {
        return <Spinner />
    }

    if (error) {
        return <span>error</span>
    }

    const onSubmit = async (props) => {
        const model = PermitSchema.cast(props, { stripUnknown: true })
        const permitUrls = props.client ? props.client.permit_urls : []
        let success = true

        const res = await createPermit({ variables: { permit: model } })

        const permit = inputToArcGIS(props)

        const id = get('data.createPermit.id')(res);

        updatePermitId(id)

        if (permitUrls.length === 0) {
            updateGisSuccess(false)
            success = false
        }

        if (permit.Inked_ID && permitUrls.length > 0) {
            const url = permitUrls[0].url
            const result = await updatePermitFeature(permit, url, props.client.name)
            updateGisSuccess(result)
            success = result
        }

        if (success) {
            navigate(`/permit/${id}/edit`, { replace: true })
            updateGisSuccess(true)
        }

    }

    const onReset = () => navigate('/permit');

    const navigateToEdit = () => {
        navigate(`/permit/${permitId}/edit`, { replace: true })
    }

    const { clients, landmen, grantors, projects, newPermitInkedID, ...lookups } = data

    return (
        <Formik
            validationSchema={PermitSchema}
            enableReinitialize
            onSubmit={onSubmit}
            onReset={onReset}
            initialValues={{}}
            render={(props) => (
                <div>
                    <PermitForm
                        clients={clients}
                        landmen={landmen}
                        projects={projects}
                        lookups={lookups}
                        grantors={grantors}
                        inkedID={newPermitInkedID.id}
                        user={profile.nickname}
                        validationSchema={PermitSchema}
                        {...props}
                    />
                    <Alert
                        isOpen={!gisSuccess}
                        onClose={() => navigateToEdit()}
                        canOutsideClickCancel
                    >
                        {
                            !gisSuccess &&
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Icon style={{ color: 'red' }} icon="cross" />
                                <div>ArcGIS update unsuccessful</div>
                            </div>
                        }
                    </Alert>
                </div>
            )}
        />
    )
}