import React, { useEffect } from 'react'
import { memoize } from 'lodash/fp'
import { ApolloClient } from 'apollo-client'
import { ApolloProvider } from '@apollo/react-hooks'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { Router } from '@reach/router'
import { Navbar } from '@blueprintjs/core'
import { ErrorBoundary } from 'react-error-boundary'
import { navigate } from '@reach/router'

import { getAuthState, useAuth0 } from './hooks/auth0'

import { NavButton, NavButtonLink } from './elements'

import ClientSearch from './components/ClientSearch'
import ClientEdit from './components/ClientEdit'
import ClientNew from './components/ClientNew'
import GrantorSearch from './components/GrantorSearch'
import GrantorNew from './components/GrantorNew'
import GrantorEdit from './components/GrantorEdit'
import LandmanSearch from './components/LandmanSearch'
import LandmanEdit from './components/LandmanEdit'
import LandmanNew from './components/LandmanNew'
import ProjectSearch from './components/ProjectSearch'
import ProjectEdit from './components/ProjectEdit'
import ProjectNew from './components/ProjectNew'
import PropertySearch from './components/PropertySearch'
import PropertyEdit from './components/PropertyEdit'
import PropertyNew from './components/PropertyNew'
import PermitNew from './components/PermitNew'
import PermitSearch from './components/PermitSearch'
import PermitEdit from './components/PermitEdit'
import Reports from './components/Reports'
import NotFound from './components/NotFound'
import Error from './components/Error'
import UserNew from './components/UserNew'
import UserSearch from './components/UserSearch'
import UserEdit from './components/UserEdit'

import {
  CanViewClient,
  AccessViewClient,
  AccessEditClient,
  CanViewLandman,
  AccessViewLandman,
  AccessEditLandman,
  CanCreateUser,
  AccessCreateUser,
  CanViewGrantor,
  AccessViewGrantor,
  AccessEditGrantor,
  CanViewProject,
  AccessViewProject,
  AccessEditProject,
  CanViewProperty,
  AccessViewProperty,
  AccessEditProperty,
  CanViewPermit,
  AccessViewPermit,
  AccessEditPermit,
  CanViewReport,
  AccessViewReport

} from './components/Auth'

const isLocal = window.location.hostname === 'localhost'

const REACT_APP_API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;


// If we're on local host set the API endpoint as /graphql and the react-scripts server will proxy that request to the server in project.json.  Note, this reverse proxy will work for non GET methods and GET methods that don't accept 'text/html' based on the default proxy config
// See https://github.com/facebook/create-react-app/blob/a0b3753476053ed60159fb209f974b83e2aad7f9/packages/react-dev-utils/WebpackDevServerUtils.js#L419 for notes on what gets proxied.
// If we're running from a hosted server, then the api will be behind the v1 path via a route on the CDN
const httpLink = createHttpLink({
  uri: isLocal ? '/v1/graphql' : `${REACT_APP_API_BASE_PATH}/v1/graphql`
})

const getToken = memoize(() => {
  const state = getAuthState()

  return state && state.idToken
})

const authLink = setContext((_, { headers }) => {
  const token = getToken()

  return {
    headers: {
      ...headers,
      'Authorization': token ? `Bearer ${token}` : ''
    }
  }
})

const cache = new InMemoryCache()

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only'
    }
  }
})

const logoStyle = {
  padding: 5,
  cursor: 'pointer',
  marginLeft: 5,
  marginTop: 5
}

export default () => {
  const { login, logout, isLoggedIn, isAuthenticated, profile } = useAuth0()

  const accessViewClient = AccessViewClient()
  const accessEditClient = AccessEditClient()
  const accessViewProject = AccessViewProject()
  const accessEditProject = AccessEditProject()
  const accessViewProperty = AccessViewProperty()
  const accessEditProperty = AccessEditProperty()
  const accessViewPermit = AccessViewPermit()
  const accessEditPermit = AccessEditPermit()
  const accessViewLandman = AccessViewLandman()
  const accessEditLandman = AccessEditLandman()
  const accessViewGrantor = AccessViewGrantor()
  const accessEditGrantor = AccessEditGrantor()
  const accessViewReport = AccessViewReport()
  const accessCreateUser = AccessCreateUser()

  useEffect(() => {
    if (isLoggedIn() && isAuthenticated) {

    } else {
      login()
    }
  }, [])

  if (!isAuthenticated || !profile) {
    return <div>Loading...</div>
  }

  return (
    <ApolloProvider client={client}>
      <div >
        <Navbar>
          <Navbar.Group>
            <Navbar.Heading>
              <div style={{ height: 100, width: 100, marginTop: 50, marginLeft: 0, backgroundColor: 'white' }}>
                <img onClick={() => navigate('/')} style={logoStyle} width="90px" src={require("./inkedland-logo.png")} alt="inked-logo" />
              </div>
            </Navbar.Heading>
          </Navbar.Group>
          <Navbar.Group>

            <CanViewClient>
              <NavButtonLink className='navbar-button' to="/client">Clients</NavButtonLink>
            </CanViewClient>

            <CanViewProject>
              <NavButtonLink className='navbar-button' to="/project">Projects</NavButtonLink>
            </CanViewProject>

            <CanViewGrantor>
              <NavButtonLink className='navbar-button' to="/grantor">Grantors</NavButtonLink>
            </CanViewGrantor>

            <CanViewProperty>
              <NavButtonLink className='navbar-button' to="/property">Properties</NavButtonLink>
            </CanViewProperty>

            <CanViewPermit>
              <NavButtonLink className='navbar-button' to="/permit">Permits</NavButtonLink>
            </CanViewPermit>

            <CanViewLandman>
              <NavButtonLink className='navbar-button' to="/landman">Landmen</NavButtonLink>
            </CanViewLandman>

            <CanViewReport>
              <NavButtonLink className='navbar-button' to="/report">Reports</NavButtonLink>
            </CanViewReport>
          </Navbar.Group>

          <Navbar.Group align="right">
            <NavButtonLink className='navbar-button' to="/me">{profile.nickname}</NavButtonLink>

            <CanCreateUser>
              <NavButton className='navbar-button' onClick={() => navigate(`/user`)} >Users</NavButton>
            </CanCreateUser>

            <NavButton className='navbar-button' onClick={logout}>Logout</NavButton>
          </Navbar.Group>
        </Navbar>

        <ErrorBoundary FallbackComponent={Error}>

          <Router>

            {accessViewClient &&
              <ClientSearch path="/client" />
            }

            {accessEditClient &&
              <ClientNew path="/client/new" />
            }

            {accessEditClient &&
              <ClientEdit path="/client/:clientID/edit" />
            }

            {accessViewProject &&
              <ProjectSearch path="/project" />
            }

            {accessEditProject &&
              <ProjectNew path="/project/new" />
            }

            {accessEditProject &&
              <ProjectEdit path="/project/:projectID/edit" />
            }

            {accessViewProperty &&
              <PropertySearch path="/property" />
            }

            {accessEditProperty &&
              <PropertyNew path="/property/new" />
            }

            {accessEditProperty &&
              <PropertyEdit path="/property/:propertyID/edit" />
            }

            {accessViewPermit &&
              <PermitSearch path="/permit" />
            }
            {accessEditPermit &&
              <PermitNew authentication={accessEditPermit} path="/permit/new" />
            }

            {accessEditPermit &&
              <PermitEdit path="/permit/:permitID/edit" />
            }

            {accessViewLandman &&
              <LandmanSearch path="/landman" />
            }

            {accessEditLandman &&
              <LandmanNew path="/landman/new" />
            }

            {accessEditLandman &&
              <LandmanEdit path="/landman/:landmanID/edit" />
            }

            {accessViewGrantor &&
              <GrantorSearch path="/grantor" />
            }

            {accessEditGrantor &&
              <GrantorNew path="/grantor/new" />
            }

            {accessEditGrantor &&
              <GrantorEdit path="/grantor/:grantorID/edit" />
            }

            {accessViewReport &&
              <Reports path="/report" />
            }

            {accessCreateUser &&
              <UserSearch path="/user" />
            }

            {accessCreateUser &&
              <UserNew path="/user/new" />
            }

            {
              accessCreateUser &&
              <UserEdit path="user/:userID/edit" />
            }



            <NotFound default />
          </Router>
        </ErrorBoundary>
      </div>
    </ApolloProvider>
  )
}
