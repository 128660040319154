import * as Yup from 'yup';
import LookupType from './LookupType';
export var ProjectSchema = Yup.object().shape({
  name: Yup.string().label('Project Name').min(1).required(),
  manager: Yup.string().label('Project Manager').default(null).nullable(),
  client: LookupType.label('Client').default(null).nullable().required(),
  landmen: Yup.array().of(LookupType).label('Landmen').nullable(),
  products: Yup.array().of(LookupType).label('Products').nullable(),
  state: LookupType.label('State').nullable(),
  county: LookupType.label('County').nullable(),
  afe_po_number: Yup.string().label('AFE / PO #').nullable(),
  number_of_lines: Yup.number().label('Number of Lines').nullable(),
  target_release_to_build: Yup.string().label('Target Release to Build').nullable(),
  target_in_service_date: Yup.string().label('Target in Service Date').nullable(),
  target_permanent_width: Yup.string().label('Target Permanent Width').nullable(),
  target_construction_width: Yup.string().label('Target Construction Width').nullable(),
  notes: Yup.string().label('Notes').nullable(),
  property_service_url: Yup.string().label('Property Service URL').nullable(),
  permit_service_url: Yup.string().label('Permit Service URL').nullable(),
  property_urls: Yup.array().of(Yup.object().shape({
    id: Yup.number(),
    url: Yup.string().nullable().required("URL is required"),
    name: Yup.string().nullable().required("Name is required"),
    project_id: Yup.mixed()
  }).label('GIS Property URLs')),
  permit_urls: Yup.array().of(Yup.object().shape({
    id: Yup.number(),
    url: Yup.string().nullable().required("URL is required"),
    name: Yup.string().nullable().required("Name is required"),
    project_id: Yup.mixed()
  }).label('GIS Permit URLs'))
});