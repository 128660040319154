import React, { useState } from 'react'
import { get } from 'lodash/fp'
import { Formik } from 'formik'
import { navigate } from '@reach/router'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { ProjectSchema } from '@inked/validation'
import ProjectForm from '../ProjectForm'
import { Spinner } from '../../elements'

const QUERY = gql`
  query {
    clients {
      id
      name
    }

    landmen {
      id
      name
    }

    states {
      id
      name
    }

    property_states {
      id
      name
    }

    counties {
      id
      name
      state_id
    }

    products {
      id
      name
    }
  }
`

const MUTATION = gql`
  mutation createProject($project: ProjectInput!) {
    createProject(project: $project) {
      id
    }
  }
`

export default () => {
  const [createProject] = useMutation(MUTATION)
  const { loading, error, data } = useQuery(QUERY)

  if (loading) {
    return <Spinner />
  }

  if (error) {
    console.error(error)

    return <span>error</span>
  }

  const onSubmit = async (props) => {
    const project = ProjectSchema.cast(props, { stripUnknown: true })
    
    const res = await createProject({ variables: { project }})

    const id = get('data.createProject.id')(res)

    navigate(`/project/${id}/edit`, { replace: true })
  }

  const onReset = () => navigate('/project')

  return (
    <Formik
      initialValues={{}}
      onSubmit={onSubmit}
      onReset={onReset}
      render={props => (
        <ProjectForm 
          isNew
          {...props} 
          {...data}
        />
      )}
      validationSchema={ProjectSchema}
    />
  )
}