import React from 'react'
import { Formik } from 'formik'
import { navigate, Redirect } from '@reach/router'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { ClientSchema } from '@inked/validation'

import ClientForm from '../ClientForm'
import { Spinner } from '../../elements'

const FRAGMENT = gql`
  fragment ClientFragment on Client {
    id
    name

    details {
      address_1
      city
      state {
        id
        name
      }
      zip_code

      phone_1
      phone_1_ext
      phone_2
      phone_2_ext
      email_1
      email_2

      website
      notes
    }

    contacts {
      id
      name
      title
      phone_1
      phone_1_ext
      phone_2
      phone_2_ext
      email
      contact_index
    }

    property_urls {
      name
      url
    }

    permit_urls {
      name
      url
    }
  }
`

const QUERY = gql`
  ${FRAGMENT}
  query ($id: ID!) {
    client(id: $id) {
      ...ClientFragment
    }

    states {
      id
      name
    }
  }
`

const MUTATION = gql`
  mutation UpdateClient($id: ID!, $client: ClientInput!) {
    updateClient(id: $id, client: $client) {
      ...ClientFragment
    }
  }
  ${FRAGMENT}
`

export default ({ clientID }) => {
  const [updateClient] = useMutation(MUTATION)
  const { loading, error, data } = useQuery(QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      id: clientID
    }
  })

  if (loading) {
    return <Spinner />
  }

  if (error) {
    console.error(error)

    return <span>error</span>
  }

  const onSubmit = async (props, {resetForm}) => {
    const model = ClientSchema.cast(props, { stripUnknown: true })
    
    await updateClient({
      variables: {
        id: clientID,
        client: model
      }
    })
  }

  const onReset = () => navigate('/client')
    return (
      <Formik
        enableReinitialize={true}
        initialValues={data.client}
        onSubmit={onSubmit}
        onReset={onReset}
        render={({errors, ...props}) => { return <ClientForm {...props} states={data.states} {...props} />}}
        validationSchema={ClientSchema}
      />
    )
}