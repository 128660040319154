import React from 'react'
import { differenceBy } from 'lodash/fp'
import { withProps } from 'recompose'
import { MultiSelect, Suggest } from '@blueprintjs/select'
import { default as CoredMaskedInput } from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import { 
  Button,
  EditableText as CoreEditableText, 
  Icon, 
  FormGroup, 
  InputGroup, 
  Intent, 
  HTMLSelect, 
  MenuItem, 
  TextArea 
} from '@blueprintjs/core'

const editableStyle = {
  borderBottom: '2px dashed grey',
  marginLeft: 5,
  paddingLeft: 5
}

const editableIconStyle = {
  marginLeft: 5
}

export const FormEditableText = ({ errorText, ...props }) =>  { return(
  <span style={editableStyle}>
    <CoreEditableText
      multiline={false}
      minWidth={200}
      intent={errorText ? Intent.DANGER : Intent.NONE}
      {...props}
    />

    <Icon 
      icon="edit" 
      style={editableIconStyle} 
      intent={errorText ? Intent.DANGER : Intent.NONE}
    />
  </span>
)}

export { InputGroup }

export const FormInput = ({ disabled, errorText, helpText, label, type='', placeholder, isAuto, autoValue, value = '', ...props }) => {

return(
  <FormGroup
    disabled={disabled}
    label={label}
    helperText={errorText || helpText}
    intent={errorText ? Intent.DANGER : Intent.NONE}
  >
    <InputGroup 
      type={type}
      noValidate
      disabled={disabled}
      placeholder={placeholder}
      autoComplete='off'
      intent={errorText ? Intent.DANGER : Intent.NONE}
      value={(value || '')}
      {...props}
    />
  </FormGroup>
)}

export const MaskedInput = ({ disabled, errorText, helpText, label, placeholder, mask, value = '', ...props }) => {
  return (
    <FormGroup
      disabled={disabled}
      label={label}
      helperText={errorText || helpText}
      intent={errorText ? Intent.DANGER : Intent.NONE}
    >
      <CoredMaskedInput
        guide={false}
        mask={mask}
        value={(value ? value.toLocaleString() : '')}
        {...props}
        render={(ref, props) => 
          <InputGroup
            noValidate
            disabled={disabled}
            placeholder={placeholder}
            autoComplete='off'
            intent={errorText ? Intent.DANGER : Intent.NONE}
            inputRef={ref}
            {...props}
          />
        }
      />
    </FormGroup>
  )
}

export const PhoneMaskedInput = withProps({
  mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
})(MaskedInput)

const thousandsMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    allowNegative: true,
    includeThousandsSeparator : false
})

export const NumberMaskedInput = withProps({
  mask: thousandsMask
})(MaskedInput)


export const FormTextArea = ({ disabled, errorText, helpText, label, placeholder, value = '', ...props }) => (
  <FormGroup
    disabled={disabled}
    label={label}
    helperText={errorText || helpText}
    intent={errorText ? Intent.DANGER : Intent.NONE}
  >
    <TextArea
      fill
      growVertically={false}
      disabled={disabled}
      value={value || ''}
      {...props}
    />
  </FormGroup>
)

const itemValueRenderer = (item) => item.name

const tagRenderer = (item) => item.name

const itemRenderer = (item, { handleClick, modifiers }) => (
  <MenuItem
    active={modifiers.active}
    key={item.id}
    text={item.name}
    onClick={(e) => handleClick(item, e)}
  />
)

const ClearButton = ({ disabled, ...props }) => (
  <Button
    minimal
    icon="small-cross"
    disabled={disabled}
    {...props}
  />
)

export const FormSuggest = ({ disabled, style={}, errorText, helpText, label, items = [], onBlur, onChange, value, ...props }) => {
  const onClear = () => onChange(null)
  return (
    <div style={style}>
      <FormGroup
        disabled={disabled}
        label={label}
        helperText={errorText || helpText}
        intent={errorText ? Intent.DANGER : Intent.NONE}
      >
        <Suggest
          fill
          items={items}
          itemRenderer={itemRenderer}
          inputValueRenderer={itemValueRenderer}
          selectedItem={value}
          inputProps={{ 
            ...props, 
            onBlur, 
            autoComplete: 'off',
            disabled,
            intent: errorText ? Intent.DANGER : Intent.NONE,
            rightElement: <ClearButton disabled={disabled} onClick={onClear} />
          }}
          onItemSelect={onChange}
        />
      </FormGroup>
    </div>
  )
}

export const FormMultiSuggest = ({ disabled, errorText, helpText, label, name, items = [], onBlur, onChange, value = [], ...props }) => {
  const onItemSelect = (item) => onChange(value.concat(item))

  const onRemove = (_, index) => onChange(value.filter((_, i) => i !== index))

  const onClear = () => onChange([])

  const filteredItems = differenceBy('id', items, value)

  return (
    <FormGroup
      disabled={disabled}
      label={label}
      helperText={errorText || helpText}
      intent={errorText ? Intent.DANGER : Intent.NONE}
    >
      <MultiSelect
        fill
        items={filteredItems}
        itemRenderer={itemRenderer}
        inputValueRenderer={itemValueRenderer}
        onItemSelect={onItemSelect}
        selectedItems={value}
        tagRenderer={tagRenderer}
        tagInputProps={{
          ...props, 
          disabled,
          onRemove,
          rightElement: <ClearButton disabled={disabled} onClick={onClear} />,
          tagProps: {
            intent: Intent.PRIMARY
          },
          inputProps: {
            autoComplete: 'none',
            name,
            onBlur, 
          }
        }}
      />
    </FormGroup>
  )
}

const toOptions = (items = []) => items.map(({ id, name }) => ({ label: name, value: id }))

export const FormSimpleSelect = ({ disabled, errorText, helpText, items, label, nullable, onChange, value, ...props }) => {
  const mapped = toOptions(items)
  const options = nullable ? [{ label: '', value: '' }].concat(mapped) : mapped

  const handleChange = (evt) => {
    const item = items.find(o => o.id == evt.target.value)

    onChange(item)
  }

  const id = value && value.id

  return (
    <FormGroup
      disabled={disabled}
      label={label}
      helperText={errorText || helpText}
      intent={errorText ? Intent.DANGER : Intent.NONE}
    >
      <HTMLSelect
        fill
        autoComplete="off"
        disabled={disabled}
        options={options}
        intent={errorText ? Intent.DANGER : Intent.NONE}
        onChange={handleChange}
        value={id}
        {...props}
      />
    </FormGroup>
  )
}